import React, { useEffect, useState } from "react";
import PriceContainer from "../../components/Price";
import styles from "./Prix.module.scss";
export default function Prix({ data, refresh }) {
  const [allPrice, setAllPrice] = useState([]);

  useEffect(() => {
    setAllPrice(data);
  }, [data]);

  return (
    <div className={styles.container}>
      {allPrice?.map(
        (el, i) =>
          el.label !== "montage_demontage" &&
          el.label !== "distance logement rue" && (
            <PriceContainer refresh={refresh} data={el} key={el.label} />
          )
      )}
    </div>
  );
}
