import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { getDevisByPrestaireId } from "../../../apiCalls/prestataires";
import styles from "./allChantier.module.scss";
import LineChantier from "../../../commons/LineChantier/LineChantier";
export default function AllChantier({ id, prestataire }) {
  const [chantier, setChantier] = useState([]);

  const handleCatchChantier = useCallback(async () => {
    await getDevisByPrestaireId({ prestataire_id: parseInt(id) }).then(
      (res) => {
        setChantier(res.data.getCommandeByPrestataireIdAdmin);
      }
    );
  }, [id]);

  useEffect(() => {
    handleCatchChantier();
  }, [handleCatchChantier]);

  return (
    <div className={styles.container}>
      {chantier.length === 0 ? (
        <p>Aucun chantier</p>
      ) : (
        chantier?.map((el) => (
          <LineChantier prestataireInformation={prestataire} el={el} />
        ))
      )}
    </div>
  );
}
