import React, { useCallback, useState } from "react";
import Button from "../../../../commons/Button";
import styles from "../Accessoires.module.scss";
import Input from "../../../../commons/Input";
export default function LineCarton({
  element,
  data,
  i,
  handleModify,
  type,
  handleDelete,
}) {
  const [value, setValue] = useState(data?.[i]?.quantity);

  const handleChange = useCallback(
    (e) => {
      if (e.target.value === "") return;
      setValue(parseInt(e.target.value ?? 0));
      handleModify(element, parseInt(e.target.value) ?? 0, type, data?.[i]);
    },
    [data, element, handleModify, i, type]
  );

  const deleteLine = useCallback(() => {
    handleDelete(element, type, i);
  }, [element, handleDelete, i, type]);

  return (
    <div
      key={`${element.id}-${element.nom}-${i}`}
      className={styles.lineMeuble}
    >
      <div className={styles.box}>
        <Input min={0} value={value} type={"number"} onChange={handleChange} />
      </div>

      <div className={styles.box}>
        <p className="value">{element.nom}</p>
      </div>
      <div className={styles.box}>
        <p className="value">{parseFloat(value * element.prix).toFixed(2)} €</p>
      </div>
      {value === "0" && (
        <div className={styles.box}>
          <Button
            onClick={deleteLine}
            text={"🗑️"}
            type="delete"
            size={"small"}
          />
        </div>
      )}
    </div>
  );
}
