export const majoration = [
  {
    value: 1,
    label: "Non",
  },
  {
    value: 1.2,
    label: "Oui",
  },
];
