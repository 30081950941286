import axios from "axios";
import React, { useCallback } from "react";
import {
  disableChantierSelectPresta,
  removeDevisPrestataire,
} from "../../../apiCalls/prestataires";
import { printCorrectPricePrestataire } from "../../../utils/price";
import Button from "../../Button";

export default function NewAvailable({ devis, prestataire }) {
  const handleDisociate = useCallback(async () => {
    const priceDevis = parseFloat(
      printCorrectPricePrestataire(
        devis?.option_prix[0],
        devis?.option_prix?.[0]?.assurance,
        devis?.option_prix?.[0]?.report,
        devis?.emb_deb_data
      )
    );
    const indexChantier = prestataire?.see_chantier.indexOf(devis?.id);
    let newArrayChantier = [...prestataire?.see_chantier];
    newArrayChantier.splice(indexChantier, 1);

    await disableChantierSelectPresta({
      id: prestataire?.id,
      caTotal: prestataire?.ca_total - priceDevis,
      seeChantier: newArrayChantier,
    });
    await removeDevisPrestataire({ id: devis?.id }).then(() => {
      axios
        .post(
          "https://www.dev-server.titan-demenagement.com/api/email/annulation-demenagement",
          {
            email: prestataire?.email,
            name: prestataire?.rasion_social,
            id: parseInt(devis?.id),
          }
        )
        .then(() => window.location.reload());
    });
  }, [
    devis?.emb_deb_data,
    devis?.id,
    devis?.option_prix,
    prestataire?.ca_total,
    prestataire?.email,
    prestataire?.id,
    prestataire?.rasion_social,
    prestataire?.see_chantier,
  ]);

  return (
    <Button
      text={"Dissocier"}
      onClick={handleDisociate}
      size="small"
      type={"primary"}
    />
  );
}
