import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import Button from "../Button";
import styles from "./LineClient.module.scss";
import eyes from "../../icons/eyes.png";
import Modal from "../Modal";
import RecapForm from "../../components/RecapFormulaire";
import Note from "../../components/Note";
import RecapInfo from "../../components/RecapInfo";
import RecapPaiement from "../../components/RecapPaiement";
export default function LineClient({ el, children }) {
  const [modal, setModal] = useState(false);

  const [type, setType] = useState();
  const handleSeeMore = useCallback(() => {
    setType("devis");

    setModal(true);
  }, []);
  const handleOpenModalNote = useCallback(() => {
    setType("note");
    setModal(true);
  }, []);

  const printCOrrectLabel = useMemo(() => {
    if (el.status === "PAYED") {
      return "Payé";
    }
    if (el.status === "EN COUR") {
      return "En Cours";
    }
    if (el.status === "VALIDATED") {
      return "Validé";
    }
    if (el.status === "LEADS") {
      return "Lead";
    }
  }, [el.status]);

  const handleReloadNote = useCallback(() => {
    setModal(false);
  }, []);

  const PrintCorrectModalChildren = useMemo(() => {
    switch (type) {
      case "note":
        return (
          <Note
            data={el?.client[0]?.note}
            clientId={el?.client[0]?.id}
            reload={handleReloadNote}
          />
        );

      case "devis":
        return (
          <RecapForm data={el} step={el?.step} newScreen={(e) => setType(e)} />
        );
      case "info":
        return <RecapInfo data={el} handleReturn={() => setType("devis")} />;
      case "paiement":
        return (
          <RecapPaiement data={el} handleReturn={() => setType("devis")} />
        );
      default:
        break;
    }
  }, [el, handleReloadNote, type]);

  return (
    <div
      className={classNames(styles.lineClient, {
        [styles.payer]: el.status === "PAYED",
        [styles.enCour]: el.status === "EN COUR",
        [styles.valider]: el.status === "VALIDATED",
        [styles.lead]: el.status === "LEADS",
      })}
    >
      <div className={styles.client}>
        <div className={styles.table}>
          <div className={styles.thead}>
            <div className={styles.id}>
              <p>ID</p>
            </div>
            <div>
              <p>Nom</p>
            </div>
            <div>
              <p>Email</p>
            </div>
            <div>
              <p>Téléphone</p>
            </div>
            <div>
              <p>Statut</p>
            </div>
            <div>
              <Button
                onClick={handleOpenModalNote}
                type={"note"}
                text={"Notes"}
                className={styles.notes}
              />
            </div>
          </div>
          <br></br>
          <div className={styles.theadSd}>
            <div className={styles.id}>
              <p>{el?.id}</p>
            </div>
            <div>
              <p>{el?.nom} </p>
            </div>
            <div>
              <p>{el?.client?.[0]?.email} </p>
            </div>
            <div>
              <p>{el?.telephone}</p>
            </div>
            <div>
              <p>{printCOrrectLabel} </p>
            </div>
            <div>
              <button className={styles.seeMoreButton} onClick={handleSeeMore}>
                <img className="iconsImage" src={eyes} alt="see more" />
              </button>
            </div>
          </div>
        </div>
        {children && <div className={styles.childrenElement}>{children}</div>}
      </div>
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          {PrintCorrectModalChildren}
        </Modal>
      )}
    </div>
  );
}
