const calculateDifference = (priceOriginal, newPrice) => {
  let difference = priceOriginal - newPrice;

  if (difference < 0) {
    difference = -difference; // Convertir la différence en valeur positive
  }

  return difference;
};
export default calculateDifference;
