import { daysToWeeks, format } from "date-fns";

import { fr } from "date-fns/locale";
import { Pie } from "react-chartjs-2";
import React, { useEffect, useCallback, useState, useMemo } from "react";

import { Chart as ChartJS, ArcElement, Legend } from "chart.js";

import { getTotalCa, getTotalDevis } from "../../apiCalls/devis";
import { getTotalPresta } from "../../apiCalls/prestataires";

import styles from "./dashboard.module.scss";
import { formatPerfectDate } from "../../utils/week";
import { printCorrectPriceFacture } from "../../utils/price";
ChartJS.register(ArcElement, Legend);
export default function DashBoard() {
  const [totalPresa, setTotalPresta] = useState(0);
  const [totalChantier, setTotalChantier] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [totalDay, setTotalDay] = useState(0);
  const [totalWeek, seTotalWeek] = useState(0);
  const [infoToday, setInfoToday] = useState({
    lead: "",
  });
  const [infoMonth, setInfoMonth] = useState({
    lead: "",
  });
  const [infoWeek, setInfoWeek] = useState({
    lead: "",
  });
  const [commandes, setCommandes] = useState({
    encour: "",
    valide: "",
  });
  const Card = ({ title, children }) => {
    return (
      <div className={styles.card}>
        <div className={styles.bodyCard}>
          <p className={styles.titleCard}>{title}</p>
          <div className={styles.childrenCardContainer}>{children}</div>
        </div>
      </div>
    );
  };

  const DayWeekMonth = ({ day, week, month }) => {
    return (
      <div className={styles.DayWeekMonth}>
        <p>
          <span>Aujourd'hui :</span> {day}
        </p>
        <p>
          <span>Semaine :</span> {week}
        </p>
        <p>
          <span>Mensuel :</span> {month}
        </p>
      </div>
    );
  };

  const handleCatchInfo = useCallback(async () => {
    await getTotalDevis().then((res) => {
      setTotalChantier(
        res.data.getCommandeAdmin.filter(
          (el) => el.status === "VALIDATED" && el.prestataire_id === null
        ).length
      );

      const data = res.data.getCommandeAdmin.filter((el) => el.date_commande);
      const today = data.filter(
        (el) => el.date_commande === format(new Date(), "dd/MM/yyyy")
      );
      const lead = today.filter(
        (el) => el.status === "EN COUR" || el.status === "EN_COUR"
      ).length;
      const validated = today.filter(
        (el) => el.status === "PAYED" || el.status === "VALIDATED"
      ).length;
      setCommandes({ encour: lead, valide: validated });

      const month = data.filter(
        (el) =>
          el.date_commande.slice(3, 5) ===
          format(new Date(), "dd/MM/yyyy").slice(3, 5)
      ).length;
      const weekly = data.filter(
        (el) =>
          daysToWeeks(el.date_commande.slice(0, 2)) ===
          daysToWeeks(new Date().getDate())
      ).length;
      setInfoWeek({ ...infoWeek, lead: weekly });
      setInfoMonth({ ...infoMonth, lead: month });
      setInfoToday({ ...infoToday, lead: today.length });
    });
    await getTotalPresta().then((res) =>
      setTotalPresta(res.data.getAllPrestataireInformation.length)
    );
  }, [infoMonth, infoToday, infoWeek]);

  const getAllCa = useCallback(async () => {
    await getTotalCa().then((res) => {
      const day = new Date().getDate();
      let curr = new Date();
      let weeky = [];

      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first)).toDateString();
        weeky.push(day);
      }

      const month = new Date().getMonth();
      const data = res.data.getTotalCa.map((el) => ({
        id: el.id,
        option_prix: printCorrectPriceFacture(el.ca[0], el.emb),
        date: formatPerfectDate(el.date_commande),
      }));

      const ca_today = data.filter(
        (el) =>
          el.date.getDate() === day && {
            ca: el.option_prix,
          }
      );
      const ca_week = data.filter(
        (el) =>
          weeky.includes(el.date.toDateString()) && {
            ca: el.option_prix,
          }
      );

      const ca_month = data.filter(
        (el) =>
          el.date.getMonth() === month && {
            ca: el.option_prix,
          }
      );

      const sumWithInitialWeek =
        ca_week.length !== 0
          ? ca_week
              .map((el) => parseFloat(el.option_prix))
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue
              )
          : 0;
      const sumWithInitialDay =
        ca_today.length !== 0
          ? ca_today
              .map((el) => parseFloat(el.option_prix))
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue
              )
          : 0;
      const sumWithInitialMonth = ca_month
        .map((el) => parseFloat(el.option_prix))
        .reduce((previousValue, currentValue) => previousValue + currentValue);
      setTotalMonth(parseFloat(sumWithInitialMonth).toFixed(2));
      seTotalWeek(parseFloat(sumWithInitialWeek).toFixed(2));
      setTotalDay(parseFloat(sumWithInitialDay).toFixed(2));
    });
  }, []);

  useEffect(() => {
    handleCatchInfo();
    getAllCa();
  }, []);

  const data = useMemo(() => {
    return {
      datasets: [
        {
          label: "Commandes Journaliere",
          data: [commandes.encour, commandes.valide],
          backgroundColor: ["#FE5900", "#20a846"],
          borderColor: ["#F6F6F6", "#F6F6F6"],
          borderWidth: 1,
        },
      ],
    };
  }, [commandes.encour, commandes.valide]);

  return (
    <div className={styles.main}>
      <h2 className={styles.days}>
        {format(new Date(), "dd LLLL yyyy", { locale: fr })}
      </h2>
      <div className={styles.container}>
        <h3>Petit coup d’oeil sur les données de la journée.</h3>
        <div className={styles.gridContainer}>
          <Card title="Chiffre d'affaires">
            <DayWeekMonth
              month={`${totalMonth} €`}
              day={`${totalDay} €`}
              week={`${totalWeek} €`}
            />
          </Card>
          <Card title="Commandes">
            <div className={styles.containerPie}>
              <Pie
                style={{
                  width: "100px",
                  height: "100px",
                }}
                data={data}
              />
              <div className={styles.containerLabel}>
                <p className="orangeText">En cours : {commandes.encour}</p>
                <p className="greenText">Validé : {commandes.valide}</p>
              </div>
            </div>
          </Card>
          <Card title="Visites du site">
            <DayWeekMonth />
          </Card>
          <Card title="Nouveaux Leads">
            <DayWeekMonth
              day={infoToday.lead}
              week={`${infoWeek.lead}`}
              month={infoMonth.lead}
            />
          </Card>
          <Card title="Nombre de partenaires">
            <div className={styles.score}>
              <p>{totalPresa}</p>
            </div>
          </Card>
          <Card title="Chantiers disponibles">
            <div className={styles.score}>
              <p>{totalChantier}</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
