import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getMajorationZipCode = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllZipCode {
        getAllZipCode {
          majoration
          list
          id
        }
      }
    `,
  });
  return { data, errors };
};

export const createMajorationZipCode = async ({ value }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateMajoration($list: [String], $majoration: Float) {
        createMajoration(list: $list, majoration: $majoration) {
          id
          list
          majoration
        }
      }
    `,
    variables: {
      list: value.list,
      majoration: value.majoration,
    },
  });
  return { data, errors };
};

export const updateMajorationZipCode = async ({ value }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateZipMajorationZipCode(
        $updateZipMajorationZipCodeId: ID
        $list: [String]
        $majoration: Float
      ) {
        updateZipMajorationZipCode(
          id: $updateZipMajorationZipCodeId
          list: $list
          majoration: $majoration
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      updateZipMajorationZipCodeId: value.id,
      list: value.list,
      majoration: value.majoration,
    },
  });
  return { data, errors };
};

export const deleteMajorationZipCode = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteMajorationZipCode($deleteMajorationZipCodeId: ID) {
        deleteMajorationZipCode(id: $deleteMajorationZipCodeId) {
          message
          successful
        }
      }
    `,
    variables: {
      deleteMajorationZipCodeId: id,
    },
  });
  return { data, errors };
};
