import React, { useCallback } from "react";
import Button from "../../../../commons/Button";
import styles from "./addAccessoireLine.module.scss";
export default function AddAccessoireLine({ element, handleAddAcc }) {
  const handleAdd = useCallback(
    (e) => {
      if (element.nom.includes("Carton")) {
        return handleAddAcc("carton", element);
      }
      handleAddAcc("accessoires", element);
    },
    [element, handleAddAcc]
  );

  return (
    <div className={styles.lineMeuble}>
      <div className={styles.box}>
        <p>{element.nom}</p>
      </div>
      <Button className={styles.ajoutacc} text={"+"} onClick={handleAdd} />
    </div>
  );
}
