const reverseDemontageMontage = (data, dataObject, byName, byIndex) => {
  if (byName) {
    let newArray = [];
    newArray = data?.map((el) => dataObject?.filter((i) => i.nom == el));
    let flatArray = newArray?.flat();
    flatArray = flatArray?.map((el) => el.nom);
    return flatArray;
  }
  if (byIndex) {
    let newArray = [];

    newArray = data?.map((el) =>
      dataObject?.filter((i) => parseInt(i.id) === el)
    );
    console.log(newArray);
    let flatArray = newArray?.flat();
    flatArray = flatArray?.map((el) => el.nom);
    return flatArray;
  }
  let newArray = [];
  newArray = data?.map((el) => dataObject?.filter((i) => i.id == el));
  let flatArray = newArray?.flat();
  flatArray = flatArray?.map((el) => el.nom);
  return flatArray;
};

export default reverseDemontageMontage;
