import React from "react";
import Button from "../../../commons/Button";
import styles from "./AllArticles.module.scss";

export default function AllArticles({ data = [], modify }) {
  return (
    <div className={styles.container}>
      {data?.map((el) => (
        <div key={el.id} className={styles.card}>
          <p className={styles.title}>{el.title}</p>
          <p className={styles.region}>{el.region}</p>
          <div className={styles.buttonContainer}>
            <Button
              text={<span style={{ fontSize: "2rem", padding: "0.7rem" }}>⚙️</span>}
              className="buttonModify"
              onClick={() => modify(el)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
