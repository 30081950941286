import React, { useCallback, useContext, useEffect, useMemo } from "react";
import Adresses from "./Adresses";
import Options from "./Options";
import styles from "./Modification.module.scss";
import AssuranceOrReport from "./AssuranceReport";
import { useParams } from "react-router-dom";
import { useState } from "react";
import InformationLogement from "./InformationLogement";
import Accessoires from "./Accessoire";
import { modifiedContext } from "../../store/devisContext";
import Button from "../../commons/Button";
import Listing from "./Listing/index";
import InfoGeneral from "./InfoGeneral";
import { Switch } from "@mui/material";
import classNames from "classnames";
import { getClientByIdForModified } from "../../apiCalls/clients";
import { getPrestaireById } from "../../apiCalls/prestataires";
import { createFactureRm, getFactureId } from "../../apiCalls/facture";
import axios from "axios";
import { calculDLRandEmbDeb } from "../../utils/price";
import { updateDevisGlobal } from "../../apiCalls/devis";
import Modal from "../../commons/Modal";
import Loader from "../../commons/Loader";
import VerificationModal from "./VerificationModal";
import DateAndFlexible from "./DateAndFlexible";

export default function ModificationPage({
  originPrice,
  newPrice,
  allPrice,
  allMeuble,
  allZipCode,
  allSaison,
}) {
  const { devis, unique } = useParams();
  const { data, setData, step, setStep, activeMajoration, orignalDevis } =
    useContext(modifiedContext);
  const [devisId, setDevisId] = useState();
  const [majorationDevisIsActive, setMajorationDevisIsActive] = useState(false);
  const [clientInfo, setClientInfo] = useState();
  const [prestaInfo, setPrestatInfo] = useState();
  const [numeroFacture, setNumeroFacture] = useState();
  const [loading, setLoading] = useState(false);
  const [openModalDif, setOpenModalDif] = useState(false);

  const priceWithoutMajouration = useMemo(() => {
    if (data?.option_prix?.[0]?.majoration) {
      return originPrice / data?.option_prix?.[0]?.majoration;
    }
    return 0;
  }, [data?.option_prix, originPrice]);

  const handleCatchUserInfo = useCallback(async () => {
    await getClientByIdForModified({ id: data?.client_id }).then((res) =>
      setClientInfo(res.data.getClientbyId[0])
    );
    await getPrestaireById({ prestataire_id: data?.prestataire_id }).then(
      (res) => setPrestatInfo(res.data.getPrestataireById[0])
    );
  }, [data?.client_id, data?.prestataire_id]);

  const getFacture = useCallback(async () => {
    await getFactureId(unique)
      .then((res) => setNumeroFacture(res.data.getFactureId[0].index))
      .catch((err) => console.log(err));
  }, [unique]);

  useEffect(() => {
    setDevisId(parseInt(devis));
    handleCatchUserInfo();
    getFacture();

    setMajorationDevisIsActive(
      data?.option_prix?.[0]?.frais_dossier > 0 ? true : false
    );
  }, [data?.option_prix, devis, getFacture, handleCatchUserInfo, unique]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const newData = data;
    data.print_price_modified = parseFloat(newPrice);
    console.log(newData);
    console.log("data", data);
    setLoading(false);
    if (!data.transaction_stripe) {
      // DEVIS PAS ENCORE PAYER
      updateDevisGlobal(newData).then(() => {
        axios
          .post(
            "https://dev-server.titan-demenagement.com/api/email/devis-modifier",
            {
              email: clientInfo?.email,
              name: data?.nom,
              id: parseInt(data?.id),
            }
          )
          .then(() => {
            if (prestaInfo?.email) {
              axios
                .post(
                  "https://dev-server.titan-demenagement.com/api/email/devis-modifier-prestataire",
                  {
                    email: prestaInfo?.email,
                    name: prestaInfo?.raison_social,
                    id: parseInt(data?.id),
                  }
                )
                .then(() => window.location.reload())
                .catch((err) => console.log(err));
            }
            return window.location.reload();
          });
      });
      return;
    }

    await createFactureRm({
      id: data?.client_id.toString(),
      key: unique,
      file: "rm-" + unique + ".pdf",
      index: numeroFacture,
    });

    axios
      .post("https://dev-server.titan-demenagement.com/api/facture/revise", {
        data: newData,
        info: {
          email: clientInfo?.email,
          indexFacture: numeroFacture,
          amount: parseFloat(newPrice),
          materiel: parseFloat(
            data?.emb_deb_data?.livraison + data?.emb_deb_data?.prixCarton
          ).toFixed(2),
          frais: data?.option_prix?.[0]?.frais_modification
            ? data?.option_prix?.[0]?.frais_modification
            : 0,
          dossier: data?.option_prix?.[0]?.frais_dossier
            ? data?.option_prix?.[0]?.frais_dossier
            : 0,
        },
      })
      .then(() => {
        axios
          .post(
            "https://dev-server.titan-demenagement.com/api/facture/annule",
            {
              url: unique,
              id: numeroFacture,
              uniqueId: unique,
            }
          )
          .then((resi) => {
            updateDevisGlobal(newData)
              .then(() => {
                axios
                  .post(
                    "https://dev-server.titan-demenagement.com/api/email/devis-modifier",
                    {
                      email: clientInfo?.email,
                      name: data?.nom,
                      id: parseInt(data?.id),
                    }
                  )
                  .then(() => {
                    if (prestaInfo?.email) {
                      axios
                        .post(
                          "https://dev-server.titan-demenagement.com/api/email/devis-modifier-prestataire",
                          {
                            email: prestaInfo?.email,
                            name: prestaInfo?.raison_social,
                            id: parseInt(data?.id),
                          }
                        )
                        .then(() => window.location.reload())
                        .catch((err) => console.log(err));
                    }
                    return window.location.reload();
                  });
              })
              .catch((err) => console.log(err));
          })
          .catch((erri) => console.log("erri", erri));
      });
  }, [
    clientInfo?.email,
    data,
    newPrice,
    numeroFacture,
    orignalDevis,
    prestaInfo?.email,
    prestaInfo?.raison_social,
    unique,
  ]);

  const handleMajoration = useCallback(
    (e) => {
      const status = e.target.checked;
      let priceModification = allPrice.filter(
        (el) => el.label === "majoration_devis"
      )[0].prix;
      setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            frais_dossier: status
              ? parseFloat(priceModification.toFixed(2))
              : 0,
          },
        ],
      }));
      setMajorationDevisIsActive(status);
    },
    [allPrice, setData]
  );

  const handleValidateDiff = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div className={styles.container}>
      {loading && (
        <Modal noCLose={true}>
          <Loader full />
        </Modal>
      )}
      {openModalDif && (
        <VerificationModal
          allMeuble={allMeuble}
          handleValidateDiff={handleValidateDiff}
          handleClose={() => setOpenModalDif(false)}
        />
      )}
      <div className={styles.activeData}>
        <div className={styles.containerRow}>
          <p
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "18px",
              marginLeft: "1rem",
            }}
          >
            Ancien prix :{" "}
          </p>
          <p className={styles.orignalPrice}> {originPrice} €</p>
        </div>
        <div className={styles.containerRow}>
          <p style={{ color: "black", fontWeight: "bold", fontSize: "18px" }}>
            Nouveau prix :{" "}
          </p>
          <p className={styles.newPrice}>{newPrice} €</p>
          <div className={styles.majorationContainer}>
            <p
              className={classNames({
                [styles.modificationActive]: !majorationDevisIsActive,
              })}
            >
              {" "}
              Aucun frais de dossier
            </p>
            <Switch
              checked={majorationDevisIsActive}
              onChange={handleMajoration}
            />
            <p
              className={classNames({
                [styles.modificationActive]: majorationDevisIsActive,
              })}
            >
              {" "}
              Frais de dossier
            </p>
          </div>
        </div>
        {step !== 1 && (
          <Button
            type={"primary"}
            onClick={() => setStep(step - 1)}
            size={"small"}
            text={"⬅️"}
          />
        )}
        {step !== 3 ? (
          <Button
            type={"primary"}
            onClick={() => setStep(step + 1)}
            size={"small"}
            text={"➡️"}
          />
        ) : (
          <Button
            className={styles.buttonvaliderhead}
            onClick={() => step === 3 && setOpenModalDif(true)}
            text="✔️ Valider"
            size={"small"}
            type="primary"
            disable={step !== 3 && true}
          />
        )}
      </div>

      <div className={styles.header}>
        <h1>Modification du devis n°{data?.id}</h1>
      </div>
      {step !== 2 && (
        <h3>
          <strong>Taux de majoration : {activeMajoration}</strong>
        </h3>
      )}
      {step === 2 && (
        <>
          <h3>
            <strong>Taux de majoration {activeMajoration}</strong>
          </h3>
          <br />
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Date & Flexibilité </h3>
            </div>

            <DateAndFlexible
              originalPriceWithoutMajoration={priceWithoutMajouration}
              allSaison={allSaison}
              data={data}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Adresses </h3>
            </div>
            <Adresses
              priceKm={allPrice?.filter((el) => el.label === "km")[0]?.prix}
              id={devisId}
              unique={unique}
              data={{
                depart: data?.depart,
                arrive: data?.arrive,
              }}
              allZipCode={allZipCode}
              optionPrix={data?.option_prix[0]}
            />
          </div>
        </>
      )}
      {(step === 1 || step === 3) && (
        <>
          {" "}
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Informations du client </h3>
            </div>
            <InfoGeneral data={data} />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Inventaire</h3>
            </div>
            <Listing
              data={data?.listing}
              dataDemontage={data?.demontage}
              allMeuble={allMeuble}
              allPrice={allPrice}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Emballage et Déballage</h3>
            </div>
            <Options
              data={data}
              prices={allPrice?.filter(
                (el) => el.label === "emballage" || el.label === "deballage"
              )}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Besoin de matériel ?</h3>
            </div>
            <Accessoires data={data?.emb_deb_data} />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Assurance et Report </h3>
            </div>
            <AssuranceOrReport
              data={{ assurance: data?.assurance, report: data?.report }}
              id={devisId}
              unique={unique}
              optionPrix={data?.option_prix[0]}
              allPrice={allPrice?.filter(
                (el) =>
                  el.label === "assurrance_base" ||
                  el.label === "assurance_premium" ||
                  el.label === "report"
              )}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.headerSection}>
              <h3>Informations logement</h3>
            </div>
            <InformationLogement
              prices={allPrice?.filter(
                (el) =>
                  el.label === "monte meuble" ||
                  el.label === "cave" ||
                  el.label === "etage" ||
                  el.label === "distance logement rue" ||
                  el.label === "objet_lourd"
              )}
              data={data}
              unique={unique}
              optionPrix={data?.option_prix[0]}
            />
          </div>
        </>
      )}
    </div>
  );
}
