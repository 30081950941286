const calculVolumeUserSelection = (val) => {
  if (val.length === 0) {
    return parseInt(0);
  }
  const sum = val.map(
    (el) => ((((el.hauteur / 100) * el.largeur) / 100) * el.profondeur) / 100
  );
  const sumWithInitial = sum.reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );

  return parseFloat((sumWithInitial * 1.1).toFixed(2));
};

export default calculVolumeUserSelection;
