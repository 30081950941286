import calculateDifference from "../calculateDifference";

export const printCorrectPriceSd = (totalPrice, embDeba) => {
  const priceGeneral = Object.values(totalPrice).filter(
    (el) => typeof el === "number"
  );
  const priceArrive = Object.values(totalPrice.arrive).filter(
    (el) => typeof el === "number"
  );
  const priceDepart = Object.values(totalPrice.depart).filter(
    (el) => typeof el === "number"
  );
  const sum = priceArrive.concat(priceDepart, priceGeneral);

  const sumWithInitial = sum.reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );
  let majoGeneral = totalPrice.majoration;

  const newPriceInitial = sumWithInitial - totalPrice.majoration;

  if (majoGeneral === 1) {
    return parseFloat(newPriceInitial + embDeba.cartonPredict).toFixed(2);
  }

  const priceCorrect = {
    assurance: totalPrice.assurance * majoGeneral - totalPrice.assurance,
    report: totalPrice.report * majoGeneral - totalPrice.report,
    livraison: totalPrice?.livraison * majoGeneral - totalPrice?.livraison,
    totalPrice: totalPrice.carton * majoGeneral - totalPrice.carton,
    frais_dossier:
      totalPrice.frais_dossier * majoGeneral - totalPrice.frais_dossier,
    majorationDevisManuel:
      totalPrice.frais_modification !== 0
        ? parseFloat(
            (
              totalPrice.frais_modification * totalPrice.majoration -
              totalPrice.frais_modification
            ).toFixed(2)
          )
        : 0,
  };

  return (
    (newPriceInitial + embDeba.cartonPredict) * majoGeneral -
    priceCorrect.totalPrice -
    priceCorrect.livraison -
    priceCorrect.report -
    priceCorrect.assurance -
    priceCorrect.majorationDevisManuel -
    priceCorrect.frais_dossier
  ).toFixed(2);
};

export const printCorrectPriceFacture = (totalPrice, embDeba) => {
  const priceGeneral = Object.values(totalPrice).filter(
    (el) => typeof el === "number"
  );
  const priceArrive = Object.values(totalPrice.arrive).filter(
    (el) => typeof el === "number"
  );
  const priceDepart = Object.values(totalPrice.depart).filter(
    (el) => typeof el === "number"
  );
  const sum = priceArrive.concat(priceDepart, priceGeneral);

  const sumWithInitial = sum.reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );

  const correctSumWIthInitial = sumWithInitial - totalPrice.majoration;

  if (totalPrice.majoration === 1) {
    return parseFloat(
      (correctSumWIthInitial + embDeba.cartonPredict).toFixed(2)
    );
  }

  const priceCorrect = {
    assurance:
      totalPrice?.assurance * totalPrice.majoration - totalPrice?.assurance,
    report: totalPrice?.report * totalPrice.majoration - totalPrice?.report,
    livraison:
      totalPrice?.livraison * totalPrice.majoration - totalPrice?.livraison,
    totalPrice: totalPrice.carton * totalPrice.majoration - totalPrice.carton,
    majorationDevisManuel:
      totalPrice?.frais_modification !== 0
        ? parseFloat(
            (
              totalPrice?.frais_modification * totalPrice.majoration -
              totalPrice?.frais_modification
            ).toFixed(2)
          )
        : 0,
    frais_dossier:
      totalPrice.frais_dossier * totalPrice.majoration -
      totalPrice.frais_dossier,
  };

  return (
    (correctSumWIthInitial + embDeba.cartonPredict) * totalPrice.majoration -
    priceCorrect.totalPrice -
    priceCorrect.livraison -
    priceCorrect.report -
    priceCorrect.assurance -
    priceCorrect.majorationDevisManuel -
    priceCorrect.frais_dossier
  ).toFixed(2);
};

export const calculPriceOfUserSelection = (val, price) => {
  const sum = val?.map(
    (el) =>
      (((((el.hauteur / 100) * el.largeur) / 100) * el.profondeur) / 100) *
      price
  );
  const vol = val?.map(
    (el) => ((((el.hauteur / 100) * el.largeur) / 100) * el.profondeur) / 100
  );
  let totalVol = 0;
  if (vol.length > 0) {
    totalVol = vol?.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    );
  }
  if (totalVol === 0) {
    return 0;
  }
  if (totalVol < 3) {
    return 140;
  }

  if (sum.length === 0) {
    return price;
  }
  const sumWithInitial = sum.reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );

  return parseFloat(sumWithInitial.toFixed(2));
};

export const priceM3 = (el, price) => {
  return (
    (((((el.hauteur / 100) * el.largeur) / 100) * el.profondeur) / 100) * price
  );
};

export const printCorrectPricePrestataire = (
  totalPrice,
  assurance,
  report,
  embDeba
) => {
  if (embDeba?.livraison === 18.5) {
    return (
      (printCorrectPriceFacture(totalPrice, embDeba) -
        report -
        assurance -
        embDeba.prixCarton -
        embDeba.livraison) *
      0.65
    ).toFixed(2);
  }

  return (
    ((printCorrectPriceFacture(totalPrice, embDeba) - report - assurance) *
      65) /
    100
  ).toFixed(2);
};

export const priceFromFront = (totalPrice, cartonPredict, original) => {
  let priceGeneral = Object.values(totalPrice).filter(
    (el) => typeof el === "number"
  );
  if (totalPrice.majoration !== 1) {
    const newTotalPrice = {
      arrive: totalPrice?.arrive,
      depart: totalPrice?.depart,
      bigWeight: totalPrice?.bigWeight,
      deballage: totalPrice?.deballage,
      emballage: totalPrice?.emballage,
      km: totalPrice?.km,
      meuble: totalPrice?.meuble,
      montage_demontage: totalPrice?.montage_demontage,
      cartonPredict: cartonPredict,
    };

    priceGeneral = Object.values(newTotalPrice).filter(
      (el) => typeof el === "number"
    );
    const priceArrive = Object.values(newTotalPrice.arrive);
    const priceDepart = Object.values(newTotalPrice.depart);
    const sum = priceArrive.concat(priceDepart, priceGeneral);
    const transformArrayToNumber = sum.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    );
    const priceEmbDeb = [
      original?.option_prix[0].emballage,
      original?.option_prix[0].deballage,
      original?.emb_deb_data.cartonPredict,
    ];
    let embDebaDifference = 0;

    let distanceLogementDepartDifference = 0;
    let distanceLogementArriveDifference = 0;

    if (
      original?.option_prix[0].depart.distance_logement !==
      newTotalPrice.depart.distance_logement
    ) {
      distanceLogementDepartDifference = calculateDifference(
        original?.option_prix[0].depart.distance_logement *
          totalPrice?.majoration,
        original?.option_prix[0].depart.distance_logement
      );
    }

    if (
      original?.option_prix[0].arrive.distance_logement !==
      newTotalPrice.arrive.distance_logement
    ) {
      distanceLogementArriveDifference = calculateDifference(
        original?.option_prix[0].arrive.distance_logement *
          totalPrice?.majoration,
        original?.option_prix[0].arrive.distance_logement
      );
    }
    if (
      original?.option_prix[0].emballage !== newTotalPrice.emballage ||
      original?.option_prix[0].deballage !== newTotalPrice.deballage ||
      original?.emb_deb_data.cartonPredict !== newTotalPrice.cartonPredict
    ) {
      const pricingDifEmbDeba = priceEmbDeb?.map((el) =>
        calculateDifference(el * totalPrice?.majoration, el)
      );
      const transformArrayToNumber = pricingDifEmbDeba.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      );
      embDebaDifference = transformArrayToNumber;
    }

    const totalDistanceLogement =
      distanceLogementArriveDifference + distanceLogementDepartDifference;
    const valeurMajorer = transformArrayToNumber * totalPrice?.majoration;
    const valeurNonMajorer =
      totalPrice.assurance +
      totalPrice.carton +
      totalPrice.livraison +
      totalPrice.report +
      totalPrice.frais_dossier +
      totalPrice.frais_modification +
      embDebaDifference +
      totalDistanceLogement;

    return parseFloat(valeurMajorer + valeurNonMajorer).toFixed(2);
  }
  // NON MAJORER
  const priceArrive = Object.values(totalPrice.arrive);
  const priceDepart = Object.values(totalPrice.depart);
  const sum = priceArrive.concat(priceDepart, priceGeneral);
  const transformArrayToNumber = sum.reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );
  const tauxMajoration = totalPrice.majoration;
  const correctSumWithInual = parseFloat(
    (transformArrayToNumber - tauxMajoration).toFixed(2)
  );

  return parseFloat(correctSumWithInual + cartonPredict).toFixed(2);
};
