import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getFerie } from "../../apiCalls/ferie";
import { getSaison } from "../../apiCalls/saison";
import Navbar from "../../components/navbar";
import Ferie from "../../section/Ferie";
import Saison from "../../section/Saison";
import { useGlobalContext } from "../../store/globalContext";

export default function FerieEtSaison() {
  const context = useGlobalContext();
  const [allSaison, setAllSaison] = useState();
  const [allFerie, setAllFerie] = useState();
  const handleCatchSaison = useCallback(async () => {
    await getSaison().then((res) => setAllSaison(res.data.getAllSaison));
  }, []);
  const handleCatchFerie = useCallback(async () => {
    await getFerie().then((res) => setAllFerie(res.data.getFerie));
  }, []);

  useEffect(() => {
    handleCatchSaison();
    handleCatchFerie();
  }, [handleCatchFerie, handleCatchSaison]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={6} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Jours fériés et Majoration des périodes</h2>
        <Ferie data={allFerie} />
        <Saison data={allSaison} />
      </div>
    </div>
  );
}
