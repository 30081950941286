import React, { useCallback } from "react";
import Button from "../Button";
import styles from "./LineObject.module.scss";
import SwitchButton from "../SwitchButton";
import { noop } from "lodash";
import { poidsModelEnum } from "../../ENUMS/poidsModelEnum";

export default function LineObject({
  data,
  setModalDelete,
  select,
  setModalModify,
  selectToModiFy,
}) {
  const correctWeight = useCallback((poids) => {
    if (poids === poidsModelEnum.LESS80.enum) {
      return poidsModelEnum.LESS80.value;
    }
    if (poids === poidsModelEnum.MORE80.enum) {
      return "- 200kg";
    }
    return "-";
  }, []);

  return (
    <div className={styles.line}>
      <div className={styles.nom}>{data.nom}</div>

      <div>
        {data.hauteur} X {data.profondeur} X {data.largeur}
      </div>

      <div>{correctWeight(data?.poids)}</div>

      <div>{data.prix_montage ?? 0} €</div>

      <div>
        <SwitchButton disable onChange={noop} data={data?.visible} />
      </div>

      <div className={styles.containerMultiButton}>
        <Button
          onClick={() => {
            setModalModify(true);
            selectToModiFy(data);
          }}
          text={<span style={{ fontSize: "2rem" }}>⚙️</span>}
          size={"small"}
          className="buttonModify"
        />
        <Button
          onClick={() => {
            select(data);
            setModalDelete(true);
          }}
          text={
            <span style={{ fontSize: "2rem" }} role="img" aria-label="poubelle">
              🗑️
            </span>
          }
          size={"small"}
          type="delete"
        />
      </div>
    </div>
  );
}
