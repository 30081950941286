export const fonctions = [
  {
    value: "COMMERCIAL",
    label: "Commercial",
  },
  {
    value: "SECRETAIRE",
    label: "Secretaire",
  },
];
