import React, { useCallback, useMemo, useState } from "react";
import { updatePrice } from "../../apiCalls/prix";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import styles from "./PriceContainer.module.scss";

export default function PriceContainer({ data }) {
  const [price, setPrice] = useState(data.prix);
  const [modification, setModification] = useState(false);

  const printCorrectComponents = useMemo(() => {
    if (modification) {
      return (
        <div className={styles.modifyPriceContainer}>
          <p className={styles.price}>Prix : </p>
          <Input
            className={styles.input}
            type={"number"}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      );
    }
    return (
      <div className={styles.modifyPriceContainer}>
        <p className={styles.price}> Prix : {price}€</p>
      </div>
    );
  }, [modification, price]);

  const handleSavePrice = useCallback(async () => {
    await updatePrice({ id: parseInt(data.id), prix: parseFloat(price) })
      .then(() => {
        setModification(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [data.id, price]);

  const printCorrectButton = useMemo(() => {
    if (modification) {
      return (
        <div className={styles.buttonContainerExtend}>
          <Button
            className={styles.secondarynoborder}
            size={"small"}
            text={<span style={{ fontSize: "1rem", padding: "0.4rem" }}>Annuler</span>}
            onClick={() => setModification(false)}
          />
          <Button
            type="primary"
            text={<span style={{ fontSize: "2rem", padding: "0.6rem" }}>💾</span>}
            onClick={handleSavePrice}
          />
        </div>
      );
    }
    return (
      <div className={styles.buttonContainer}>
        <Button
          className="buttonModify"
          size={"small"}
          text={<span style={{ fontSize: "2rem" }} role="img" aria-label="poubelle">⚙️</span>}
          onClick={() => setModification(true)}
        />
      </div>
    );
  }, [handleSavePrice, modification]);

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <th className={styles.thLabel}>
            <p className={styles.label}>{data.label.replaceAll("_", " ")}</p>
          </th>
          <th className={styles.thPrice}>{printCorrectComponents}</th>
          <th className={styles.thBtn}>{printCorrectButton}</th>
        </thead>
      </table>
    </div>
  );
}
