export const calculPriceDistance = (e, bigWeight, pricing) => {
  if (!bigWeight) {
    return e === 0 ? 0 : e === 1 ? pricing * 1 : e === 2 ? pricing * 2 : 0;
  }
  return e === 0 ? 0 : e === 1 ? 150 : e === 2 ? 300 : 0;
};

export const calculPriceDistanceDifference = (
  e,
  bigWeight,
  price,
  majoration,
  originPrice,
  originEtage
) => {
  if (originEtage === parseInt(e)) {
    return calculPriceDistance(parseInt(e), bigWeight, price) * majoration;
  }
  let differenceBetweenFloor = parseInt(e) - originEtage;
  if (originEtage < parseInt(e)) {
    differenceBetweenFloor = parseInt(e) - originEtage;
    return price * differenceBetweenFloor * majoration + originPrice;
  }
  if (originEtage > parseInt(e)) {
    differenceBetweenFloor = -differenceBetweenFloor;
    return calculPriceDistance(parseInt(e), bigWeight, price) * majoration;
  }
  return 0;
};
