import React, { useCallback, useEffect } from "react";
import Navbar from "../../components/navbar";
import DashBoard from "../../section/DashBoard";
import styles from "./Account.module.scss";
import { useGlobalContext } from "../../store/globalContext";
import { getDevisForMail } from "../../apiCalls/devis";
import { addMonths, format } from "date-fns";
import axios from "axios";

export default function Account() {
  const context = useGlobalContext();

  const addToList = useCallback(async (email) => {
    const data = {
      status: true,
      date: format(new Date(), "dd/MM/yyyy"),
    };
    localStorage.setItem("sendEmail", JSON.stringify(data));
    await axios.post(
      "https://www.dev-server.titan-demenagement.com/api/email/add-checklist",
      {
        emailList: email,
      }
    );
  }, []);

  const handleVerifiedDevis = useCallback(async () => {
    await getDevisForMail()
      .then((res) => {
        const validatedChantier = res.data.getCommandeAdmin.filter(
          (el) => el.status === "VALIDATED"
        );
        const today = format(new Date(), "dd/MM/yyyy");
        const selectChantier = validatedChantier.filter((el) => {
          const date = el.date_demenagement.split("-");
          const dateLess = addMonths(
            new Date(date[2], date[0] - 1, parseInt(date[1])),
            -1
          );
          const finalDateFormat = format(new Date(dateLess), "dd/MM/yyyy");
          if (finalDateFormat === today) {
            return el;
          }
        });
        if (selectChantier.length === 0) return;
        const emailList = selectChantier.map((el) => el.client[0].email);

        addToList(emailList);
      })
      .catch((err) => console.log(err));
  }, [addToList]);

  useEffect(() => {
    const localStorageValue = localStorage.getItem("sendEmail");
    const formatLocalStorage = JSON.parse(localStorageValue);
    if (formatLocalStorage?.date === format(new Date(), "dd/MM/yyyy")) {
      return;
    }
    handleVerifiedDevis();
  }, [handleVerifiedDevis]);

  return (
    <div className={styles.main}>
      <Navbar user={context?.userInformation} sectionActive={0} />
      <div className={styles.leftSide}>
        <DashBoard />,
      </div>
    </div>
  );
}
