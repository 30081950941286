import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./Button.module.scss";

export default function Button({
  onClick,
  type,
  text,
  size,
  className,
  link,
  target,
  disable = false,
}) {
  const myType = useMemo(() => {
    switch (type) {
      case "primary":
        return styles.primary;
      case "secondary":
        return styles.secondary;
      case "note":
        return styles.note;
      case "en_cour":
        return styles.en_cour;
      case "payer":
        return styles.payer;
      case "delete":
        return styles.delete;
      case "modify":
        return styles.modify;
      default:
        break;
    }
  }, [type]);

  const mySize = useMemo(() => {
    switch (size) {
      case "small":
        return styles.small;
      case "medium":
        return styles.medium;
      case "large":
        return styles.large;
      default:
        break;
    }
  }, [size]);

  if (link) {
    return (
      <a
        target={target && "_blank"}
        href={link}
        className={classNames(styles.container, {
          [myType]: type,
          [mySize]: size,
          [className]: className,
          [styles.disable]: disable,
        })}
      >
        {text}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames(styles.container, {
        [myType]: type,
        [mySize]: size,
        [className]: className,
        [styles.disable]: disable,
      })}
    >
      {text}
    </button>
  );
}
