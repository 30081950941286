import classNames from "classnames";
import React, { useCallback } from "react";
import styles from "./Select.module.scss";
export default function Select({
  data = [],
  onChange,
  value,
  placeHolder,
  className,
}) {
  const handleSelect = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <select
      placeholder={placeHolder}
      className={classNames(styles.container, { [className]: className })}
      onChange={handleSelect}
      value={value}
    >
      <option>{placeHolder}</option>
      {data?.map((el, i) => (
        <option key={`${el.value}-${i}`} value={el.value}>
          {el.label}
        </option>
      ))}
    </select>
  );
}
