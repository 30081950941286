import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { getAllBlog } from "../../apiCalls/blog";
import Loader from "../../commons/Loader";
import Navbar from "../../components/navbar";
import Blog from "../../section/Blog";
import { useGlobalContext } from "../../store/globalContext";

export default function BlogPage() {
  const context = useGlobalContext();
  const [loader, setLoader] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const catcHallBlog = useCallback(async () => {
    await getAllBlog()
      .then((res) => {
        setBlogs(res.data.getAllBlog);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    catcHallBlog();
  }, [catcHallBlog]);

  if (loader) {
    return <Loader full />;
  }
  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={8} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Blog</h2>
        <Blog data={blogs} />
      </div>
    </div>
  );
}
