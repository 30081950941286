import { gql } from "@apollo/client";

import titan from "../../AppoloClient/apollo-client";

export const getClients = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllClientInformation {
        getAllClientInformation {
          id
          nom
          email
          telephone
          devis_id
          facture_id
          transaction_id
          type_of_payment
          step
          first_connexion
          rue
          code
          ville
          status
          contrat_id
          date_creation
          note {
            titre
            message
            date
            auteur
          }
          mon_demenagement {
            id
            client_id
            transaction_stripe
            prestataire_id
            status
            is_payed
            date_commande
            date_demenagement
            depart {
              rue
              code
              ville
            }
            arrive {
              rue
              code
              ville
            }
            option_prix {
              frais_modification
              meuble
              montage_demontage
              emballage
              deballage
              assurance
              report
              km
              depart {
                monte_meuble
                cave
                etage
                distance_logement
              }
              arrive {
                monte_meuble
                cave
                etage
                distance_logement
              }
            }
            total_km
            surface
            demontage
            emballage
            deballage
            assurance
            report
            step
            flexible {
              fin
              debut
            }
            price
            type
            surfaceFinal
            etages
            ascenseur
            cave
            monte_meuble
            commentaires
            volume
            distance_rue
            cartons
            date_modifier
            listing {
              id
              nom
              piece
              sous_categorie
              hauteur
              largeur
              profondeur
              poids
              prix_m3
              supplemet
              visuel
              demontable
              from_user
              selectPiece
              prix_montage
              visible
            }
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const UpdateStatusCommandeClient = async ({ id, formulaire_id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation TitanValidateClientStatus(
        $titanValidateClientStatusId: ID
        $formulaireId: [FormulaireIdInput]
      ) {
        titanValidateClientStatus(
          id: $titanValidateClientStatusId
          formulaire_id: $formulaireId
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      titanValidateClientStatusId: id,
      formulaireId: formulaire_id,
    },
  });
  return { data, errors };
};

export const getClientById = async ({ id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetClientbyId($getClientbyIdId: ID) {
        getClientbyId(id: $getClientbyIdId) {
          id
          nom
          email
          telephone
          devis_id
          facture_id
          transaction_id
          type_of_payment
          first_connexion
          rue
          code
          ville
          status
          contrat_id
          mon_demenagement {
            id
            client_id
            prestataire_id
            listing {
              id
              nom
              piece
              sous_categorie
              hauteur
              largeur
              profondeur
              poids
              prix_m3
              supplemet
              visuel
              demontable
              from_user
              selectPiece
            }
            status
            is_payed
            date_commande
            date_demenagement
            depart {
              rue
              code
              ville
            }
            arrive {
              rue
              code
              ville
            }
            total_km
            surface
            demontage
            emballage
            deballage
            assurance
            report
            step
            flexible {
              fin
              debut
            }
            price
            type
            surfaceFinal
            etages
            ascenseur
            cave
            monte_meuble
            commentaires
            volume
            distance_rue
            cartons
            date_modifier
            transaction_stripe
            option_prix {
              frais_modification
              meuble
              montage_demontage
              emballage
              deballage
              assurance
              report
              km
              depart {
                monte_meuble
                cave
                etage
                distance_logement
              }
              arrive {
                monte_meuble
                cave
                etage
                distance_logement
              }
            }
          }
        }
      }
    `,
    variables: {
      getClientbyIdId: id,
    },
  });
  return { data, errors };
};

export const getClientByIdForModified = async ({ id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetClientbyId($getClientbyIdId: ID) {
        getClientbyId(id: $getClientbyIdId) {
          id
          nom
          email
          telephone
        }
      }
    `,
    variables: {
      getClientbyIdId: id,
    },
  });
  return { data, errors };
};

export const addMessageClient = async ({ id, message }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation AddNoteClient(
        $addNoteClientId: ID
        $message: [NoteCLientTypeInput]
      ) {
        addNoteClient(id: $addNoteClientId, message: $message) {
          successful
          message
        }
      }
    `,
    variables: {
      addNoteClientId: id,
      message: message,
    },
  });
  return { data, errors };
};

export const deleteClient = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteUser($deleteUserId: ID) {
        deleteUser(id: $deleteUserId) {
          id
        }
      }
    `,
    variables: {
      deleteUserId: id,
    },
  });
  return { data, errors };
};
