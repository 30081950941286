export const accessoires = [
  {
    id: 1,
    nom: "Rouleau adhésif",
    prix: 1.99,
    visuel: "adhesif",
    dimension: "Dimensions : 4,8 x 66 cm",
    description: "Qualité supérieure",
    provenance: "🇪🇺 UE",
  },
  {
    id: 2,
    prix: 9.99,
    nom: "Dévidoir adhésif",
    visuel: "devidoir-a-scotch",
    dimension: "Déroule, plaque et découpe l’adhésif",
    description: "Qualité professionnelle",
    provenance: "🇪🇺 UE  (rouleau : 20 cartons)",
  },
  {
    id: 3,
    prix: 4.99,
    nom: "Film à bulles ",
    visuel: "film-bulles",
    dimension: "Dimensions : 50 cm x 10 m",
    description: "Indispensable pour les biens fragiles",
    provenance: "🇫🇷 100 % recyclable",
  },
  {
    id: 4,
    prix: 8.99,
    nom: "Couverture déménageur",
    visuel: "couverture-demenageur",
    dimension: "Dimensions : 1,5 m x 2 m",
    description: "Qualité professionnelle",
    provenance: "🇫🇷  Made in France",
  },
  {
    id: 5,
    prix: 4.99,
    nom: "Housse matelas 1 personne",
    visuel: "housse-matelas-1-personne",
    dimension: "Dimensions : 230 x 100 x 22 cm",
    description: "Étanche et résistante",
    provenance: "🇫🇷 100 % recyclable",
  },
  {
    id: 6,
    prix: 6.49,
    nom: "Housse matelas 2 personnes",
    visuel: "housse-matelas-1-personne",
    dimension: "Dimensions : 220 x 160 x 22 cm",
    description: "Étanche et résistante",
    provenance: "🇫🇷 100 % recyclable",
  },
];
