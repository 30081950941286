import classNames from "classnames";
import React from "react";

import styles from "./LinePresta.module.scss";
import eyes from "../../icons/eyes.png";

export default function LinePresta({
  el,
  children,
  seeMore,
  OpenNote,
  reload,
}) {
  return (
    <div className={classNames(styles.lineClient)}>
      <div className={styles.client}>
        <div className={styles.table}>
          <div className={styles.thead}>
            <div>
              <p>Société</p>
            </div>
            <div>
              <p>Contact</p>
            </div>
            <div>
              <p>Téléphone</p>
            </div>
            <div>
              <p>Email</p>
            </div>
            <div></div>
          </div>
          <br></br>
          <div className={styles.theadsd}>
            <div>
              <p>{el.raison_social}</p>
            </div>
            <div>
              <p>{el.nom_dirigeant}</p>
            </div>
            <div>
              <p>{el.telephone}</p>
            </div>
            <div>
              <p>{el.email}</p>
            </div>
            <div className={styles.noteColumn}>
              <button className={styles.seeMoreButton} onClick={seeMore}>
                <img className="iconsImage" src={eyes} alt="see more" />
              </button>
            </div>
          </div>
        </div>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}
