import React, { useState, useCallback } from "react";
import { updateSaison } from "../../../apiCalls/saison";
import Button from "../../../commons/Button";
import Input from "../../../commons/Input";
import styles from "./line.module.scss";

export default function LineSaison({ el }) {
  const [modified, setModified] = useState(false);
  const [myvalue, setMyValue] = useState({
    id: parseInt(el.id),
    title: el.title,
    end: el.end,
    start: el.start,
    majoration: el?.majoration,
    majorationRate: el?.majorationRate,
  });

  const handleSubmit = useCallback(async () => {
    const rate = parseFloat(myvalue?.majorationRate);
    const valueSend = {
      ...myvalue,
      majoration: rate > 0 ? true : false,
      majorationRate: rate,
    };

    await updateSaison(valueSend)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  }, [myvalue]);

  return (
    <div className={styles.line}>
      <div className={styles.name}>
        {modified ? (
          <Input
            value={myvalue.title}
            onChange={(e) => setMyValue({ ...myvalue, title: e.target.value })}
          />
        ) : (
          <p>{el.title}</p>
        )}
      </div>
      <div className={styles.monthDate}>
        {!modified && <p>{el.start}</p>}
        {modified && (
          <div className={styles.inputLine}>
            <Input
              value={myvalue.start}
              onChange={(e) =>
                setMyValue({ ...myvalue, start: e.target.value })
              }
            />
          </div>
        )}
      </div>
      <div className={styles.monthDate}>
        {!modified && <p>{el.end}</p>}
        {modified && (
          <div className={styles.inputLine}>
            <Input
              value={myvalue.end}
              onChange={(e) => setMyValue({ ...myvalue, end: e.target.value })}
            />
          </div>
        )}
      </div>
      <div className={styles.monthDate}>
        {!modified && <p>{el?.majorationRate} %</p>}
        {modified && (
          <div className={styles.inputLine}>
            <Input
              placeholder={"% majoration"}
              type={"number"}
              value={myvalue?.majorationRate}
              onChange={(e) => {
                setMyValue({ ...myvalue, majorationRate: e.target.value });
              }}
            />
          </div>
        )}
      </div>

      {modified && (
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => setModified(false)}
            text={<span style={{ fontSize: "1rem", padding: "0.6rem" }}>Annuler</span>}
            type="secondary"
            className={styles.btnStop}
          />
          <Button
            onClick={handleSubmit}
            text={<span style={{ fontSize: "1rem", padding: "0.6rem" }}>Valider</span>}
            type="primary"
          />
        </div>
      )}
      {!modified && (
        <div className={styles.buttonContainer}>
          <Button
            className="buttonModify"
            onClick={() => setModified(true)}
            text={<span style={{ fontSize: "2rem", padding: "0.7rem" }}>⚙️</span>}
            type="modify"
          />
        </div>
      )}
    </div>
  );
}
