import React from "react";
import styles from "./HeaderObject.module.scss";
export default function HeaderObject() {
  return (
    <div className={styles.line}>
      <div>Nom de l'objet</div>
      <div>Dimensions </div>
      <div>Poids</div>
      <div>Prix montage</div>
      <div>Visible</div>
      <div></div>
    </div>
  );
}
