import React, { useState, useEffect, useCallback, useContext } from "react";
import styles from "./Option.module.scss";
import Input from "../../../commons/Input";
import Select from "../../../commons/Select";
import { trueOrFlase } from "../../../utils/ENUM/trueOrFalse.js";
import Button from "../../../commons/Button";
import { modifiedContext } from "../../../store/devisContext";
import calculateDifference from "../../../utils/calculateDifference";
export default function Options({ data, prices }) {
  const prixEmballage = prices?.[0]?.prix;
  const prixDeballage = prices?.[1]?.prix;
  const countext = useContext(modifiedContext);
  const { activeMajoration, setDifference, setData, orignalDevis } =
    useContext(modifiedContext);

  const [modified, setModified] = useState(false);
  const [value, setValue] = useState({
    deballage: null,
    emballage: null,
    maxCarton: null,
    volume: null,
  });

  useEffect(() => {
    setValue({
      deballage: data?.deballage ? 1 : 0,
      emballage: data?.emballage ? 1 : 0,
      maxCarton: data?.emb_deb_data?.maxCarton,
      volume: data?.emb_deb_data?.volume,
    });
  }, [data]);

  const handleChangeCarton = useCallback(
    (e) => {
      const valueFormat = parseInt(e.target.value);
      setValue({
        deballage: value.deballage,
        emballage: value.emballage,
        maxCarton: e.target.value,
        volume: valueFormat / 10,
      });
    },
    [value.deballage, value.emballage]
  );

  const optionPrice = useCallback(
    (emb, deb) => {
      return {
        km: countext.data.option_prix[0].km,
        arrive: {
          cave: countext.data.option_prix[0].arrive.cave,
          etage: countext.data.option_prix[0].arrive.etage,
          monte_meuble: countext.data.option_prix[0].arrive.monte_meuble,
          distance_logement:
            countext.data.option_prix[0].arrive.distance_logement,
        },
        depart: {
          cave: countext.data.option_prix[0].depart.cave,
          etage: countext.data.option_prix[0].depart.etage,
          monte_meuble: countext.data.option_prix[0].depart.monte_meuble,
          distance_logement:
            countext.data.option_prix[0].depart.distance_logement,
        },
        cartonPredict: countext.data.option_prix[0].cartonPredict,
        meuble: countext.data.option_prix[0].meuble,
        report: countext.data.option_prix[0].report,
        assurance: countext.data.option_prix[0].assurance,
        deballage: deb,
        emballage: emb,
        livraison: countext.data.option_prix[0].livraison,
        bigWeight: countext.data.option_prix[0].bigWeight,
        majoration: countext.data.option_prix[0].majoration,
        montage_demontage: countext.data.option_prix[0].montage_demontage,
        carton: countext.data.option_prix[0].carton,
        frais_modification: countext.data.option_prix[0].frais_modification,
        frais_dossier: countext.data.option_prix[0].frais_dossier,
      };
    },
    [countext?.data?.option_prix]
  );

  const handleValidate = useCallback(() => {
    let myOption;
    if (value.deballage === 1 && value.emballage === 1) {
      myOption = optionPrice(
        value.maxCarton * prixEmballage,
        value.maxCarton * prixDeballage
      );
    } else if (value.deballage === 1) {
      myOption = optionPrice(0, value.maxCarton * prixDeballage);
    } else if (value.emballage === 1) {
      myOption = optionPrice(value.maxCarton * prixEmballage, 0);
    } else {
      myOption = optionPrice(0, 0);
    }
    setDifference((difference) => ({
      ...difference,
      nb_cartons: {
        modified: true,
        value: parseInt(value.maxCarton),
        price: parseInt(value.maxCarton) * 0.1 * 41 * activeMajoration,
      },
      deballage: {
        modified: true,
        value: value.deballage === 1 ? "✅" : "❌",
        price: myOption?.deballage * activeMajoration,
      },
      emballage: {
        modified: true,
        value: value.emballage === 1 ? "✅" : "❌",
        price: myOption?.emballage * activeMajoration,
      },
    }));

    setData((data) => ({
      ...data,
      option_prix: [myOption],
      deballage: value.deballage === 1 ? true : false,
      emballage: value.emballage === 1 ? true : false,
      emb_deb_data: {
        ...data.emb_deb_data,
        maxCarton: parseInt(value.maxCarton),
        cartonPredict: parseInt(value.maxCarton) * 0.1 * 41,
        volume: parseInt(value.maxCarton) * 0.1,
      },
    }));
    setModified(false);
  }, [
    activeMajoration,
    optionPrice,
    orignalDevis?.emb_deb_data?.cartonPredict,
    prixDeballage,
    prixEmballage,
    setData,
    setDifference,
    value.deballage,
    value.emballage,
    value.maxCarton,
  ]);

  return (
    <div>
      {modified ? (
        <div className={styles.inlineButton}>
          <button className="btnModify" onClick={() => setModified(false)}>
            X
          </button>

          <Button
            className={styles.buttondisquette}
            text={"💾"}
            type="primary"
            size={"small"}
            onClick={handleValidate}
          />
        </div>
      ) : (
        <div className={styles.inlineButton}>
          <button className="btnModify" onClick={() => setModified(true)}>
            🪛
          </button>
        </div>
      )}
      {!modified && (
        <div>
          <div className={styles.inline}>
            <p>
              <span className="weigthBoldmodif">Cartons :</span>{" "}
              <span className="valuemodif"> {value?.maxCarton}</span>
            </p>
          </div>
          <div className={styles.inline}>
            <p>
              <span className="weigthBoldmodif">Déballage :</span>{" "}
              <span className="valuemodif">
                {" "}
                {value?.deballage ? "Oui" : "Non"}
              </span>
            </p>
          </div>
          <div className={styles.inline}>
            <p>
              <span className="weigthBoldmodif">Emballage :</span>{" "}
              <span className="valuemodif">
                {value?.emballage ? "Oui" : "Non"}
              </span>
            </p>
          </div>
        </div>
      )}
      {modified && (
        <div>
          <div className={styles.inline}>
            <label className="weigthBoldmodif">Cartons :</label>
            <Input
              type={"number"}
              min={0}
              className={styles.input}
              value={value?.maxCarton}
              onChange={handleChangeCarton}
            />
          </div>
          <div className={styles.inline}>
            <label className="weigthBoldmodif">Deballage :</label>
            <Select
              data={trueOrFlase}
              className={styles.input}
              value={value?.deballage}
              onChange={(e) => setValue({ ...value, deballage: parseInt(e) })}
            />
          </div>
          <div className={styles.inline}>
            <label className="weigthBoldmodif">Emballage :</label>
            <Select
              data={trueOrFlase}
              className={styles.input}
              value={value?.emballage}
              onChange={(e) => setValue({ ...value, emballage: parseInt(e) })}
            />
          </div>
        </div>
      )}
    </div>
  );
}
