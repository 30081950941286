import React, { useCallback, useState } from "react";
import { updateObject } from "../../../apiCalls/objets";
import Button from "../../../commons/Button";
import Input from "../../../commons/Input";
import Select from "../../../commons/Select";
import SwitchButton from "../../../commons/SwitchButton";
import { arrayPoids } from "../../../ENUMS/poidsModelEnum";
import styles from "./ModalObject.module.scss";

export default function ModalObject({ data, close, subCategories, piece }) {
  const [price, setPrice] = useState({
    nom: data?.nom,
    id: data?.id,
    largeur: data?.largeur,
    hauteur: data?.hauteur,
    profondeur: data?.profondeur,
    prix_montage: data?.prix_montage,
    visible: data?.visible,
    poids: data?.poids,
    piece: parseInt(data?.piece),
    sous_categorie: parseInt(data?.sous_categorie),
    demontable: data?.demontable,
  });

  const handleSubmit = useCallback(async () => {
    const dataTOSend = {
      nom: price.nom,
      hauteur: price.hauteur,
      id: parseInt(price.id),
      largeur: price.largeur,
      poids: price.poids,
      prix_montage: parseFloat(price.prix_montage),
      profondeur: price.profondeur,
      visible: price.visible,
      piece: parseInt(price.piece),
      sous_categorie: parseInt(price.sous_categorie),
      demontable: price.demontable,
    };
    console.log(dataTOSend);
    await updateObject(dataTOSend)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [price]);

  return (
    <div className={styles.container}>
      <h2>Modifier un Objet</h2>
      <div className={styles.customeColumn}>
        <div>
          <label>Nom</label>
          <Input
            className={styles.inputModify}
            placeholder="Nom"
            value={price.nom}
            onChange={(e) => setPrice({ ...price, nom: e.target.value })}
          />
        </div>
        <div>
          <label>Pièce</label>
          <Select
            data={piece}
            onChange={(e) => setPrice({ ...price, piece: e })}
            value={price.piece}
            placeHolder="Piece"
          />
        </div>
        <div>
          <label>Sous-Categorie</label>
          <Select
            data={subCategories}
            onChange={(e) => setPrice({ ...price, sous_categorie: e })}
            value={price.sous_categorie}
            placeHolder="Sous Categorie"
          />
        </div>

        <div className={styles.inline}>
          <div>
            <label>Hauteur</label>
            <Input
              className={styles.inputModify1}
              placeholder="H"
              type={"number"}
              value={price.hauteur}
              onChange={(e) =>
                setPrice({ ...price, hauteur: parseInt(e.target.value) })
              }
            />
          </div>
          <div>
            <label>Profondeur</label>
            <Input
              className={styles.inputModify1}
              placeholder="P"
              type={"number"}
              value={price.profondeur}
              onChange={(e) =>
                setPrice({ ...price, profondeur: parseInt(e.target.value) })
              }
            />
          </div>
          <div>
            <label>Largeur</label>
            <Input
              className={styles.inputModify1}
              placeholder="l"
              type={"number"}
              value={price.largeur}
              onChange={(e) =>
                setPrice({ ...price, largeur: parseInt(e.target.value) })
              }
            />
          </div>
        </div>

        <div className={styles.inline}>
          {/* <div>
            <label>Poids</label>
            <Input
              className={styles.inputModify}
              value={price.poids}
              onChange={(e) => setPrice({ ...price, poids: e.target.value })}
              type={"number"}
            />
          </div> */}
          <div>
            <label>Poids</label>
            <Select
              data={arrayPoids}
              onChange={(e) => setPrice({ ...price, poids: e })}
              value={price.poids}
              placeHolder="Poids"
            />
          </div>
          <div>
            <label>Prix montage</label>
            <Input
              className={styles.inputModify}
              value={price.prix_montage}
              onChange={(e) =>
                setPrice({ ...price, prix_montage: parseInt(e.target.value) })
              }
              type="number"
            />
          </div>
        </div>
        <div className={styles.visibleContainer}>
          <p>Objet visible :</p>
          <SwitchButton
            onChange={(e) => setPrice({ ...price, visible: e })}
            data={data?.visible}
          />
        </div>
        <div className={styles.visibleContainer}>
          <p>Démontable :</p>
          <SwitchButton
            onChange={(e) => setPrice({ ...price, demontable: e })}
            data={data?.demontable}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={close}
          text="Annuler"
          size={"small"}
          type="secondary"
          className={styles.handleDismiss}
        />
        <Button
          onClick={handleSubmit}
          text="Sauvegarder"
          size={"small"}
          type="primary"
        />
      </div>
    </div>
  );
}
