import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../commons/Button";
import styles from "./navbar.module.scss";
import { deleteCookie } from "../../utils/cookies";
import { useGlobalContext } from "../../store/globalContext";
import { verifToken } from "../../utils/token";
import { getAdminById } from "../../apiCalls/admin";

export default function Navbar({ user, sectionActive }) {
  const context = useGlobalContext();
  const navigation = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (window) {
      verifToken().then((res) => {
        if (res.isValid) {
          getAdminById({ id: res.id }).then((res) => {
            context.handleSetUser(res.data.getAdminById[0]);
          });
        } else {
          deleteCookie("titan_admin_user");
          navigation("/");
        }
      });
    }
  }, [context, id, navigation]);

  const data = useMemo(() => {
    if (user?.statut === "MASTER") {
      return [
        {
          name: "Dashboard",
        },
        {
          name: "CRM",
          value: 0,
        },
        {
          name: "Clients",
        },
        {
          name: "Prestataire",
        },
        {
          name: "Objets",
        },
        {
          name: "Prix",
        },
        {
          name: "Ferie et Saison",
        },
        {
          name: "Majorations CP",
        },
        {
          name: "Blog",
        },
        {
          name: "Contact",
          value: 0,
        },
        {
          name: "Utilisateurs",
        },
      ];
    }
    return [
      {
        name: "Dashboard",
      },
      {
        name: "CRM",
        value: 0,
      },
      {
        name: "Clients",
      },
    ];
  }, [user?.statut]);

  const handleDisconnect = useCallback(() => {
    navigation("/");
    deleteCookie("titan_admin_user");
  }, [navigation]);

  const handleScrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.sticky}>
        <p className={styles.nameUser}>{user?.utilisateur} 👋</p>
        <div className={`${styles.containerButton} ${styles.scrollable}`}>
          {data.map((el, i) =>
            el.value ? (
              <Link
                className={styles.shadowNav}
                key={el.name}
                to={`/${el.name
                  .toLocaleLowerCase()
                  .replaceAll(" ", "-")}/${id}`}
              >
                <button
                  data-value={el.value}
                  className={classNames(styles.buttonNav, {
                    [styles.activeLink]: sectionActive === i,
                  })}
                  onClick={handleScrollToTop}
                >
                  <p>{el.name}</p>
                </button>
              </Link>
            ) : (
              <Link
                className={styles.shadowNav}
                key={el.name}
                to={`/${el.name
                  .toLocaleLowerCase()
                  .replaceAll(" ", "-")}/${id}`}
              >
                <button
                  className={classNames(styles.buttonNavNoValue, {
                    [styles.activeLink]: sectionActive === i,
                  })}
                  onClick={handleScrollToTop}
                >
                  <p>{el.name}</p>
                </button>
              </Link>
            )
          )}
        </div>
        <Button
          className={styles.disconnectBtn}
          type={"primary"}
          size={"large"}
          onClick={handleDisconnect}
          text={"Déconnexion"}
        ></Button>
      </div>
    </div>
  );
}
