import React, { useCallback, useState } from "react";
import styles from "./LineArticle.module.scss";
import Check from "../../../../icons/check.svg";
import Close from "../../../../icons/close.svg";
import Button from "../../../../commons/Button";
import Modal from "../../../../commons/Modal";
import { typePieces } from "../../../../utils/ENUM/pieces";
import { useEffect } from "react";
import { useContext } from "react";
import { modifiedContext } from "../../../../store/devisContext";
import { poidsModelEnum } from "../../../../utils/ENUM/poids";
import { priceM3 } from "../../../../utils/price";
import calculVolumeUserSelection from "../../../../utils/VolumeCalcul";
import {
  differenceOriginNewPriceEtage,
  weightPriceEtage,
} from "../../../../utils/Etages";
import {
  calculPriceDistance,
  calculPriceDistanceDifference,
} from "../../../../utils/DistanceLogementRue";

export default function LineArticle({
  quantity,
  nom,
  selectQUantity,
  demontages,
  el,
  weightPrice,
  globalQuantity,
  pricing,
}) {
  const countext = useContext(modifiedContext);
  const { activeMajoration, orignalDevis } = useContext(modifiedContext);

  const [quantitySelect, setQuantitySelect] = useState();
  const [selectDemontable, setSelectDemontable] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setQuantitySelect(quantity);
    setSelectDemontable(selectQUantity);
  }, []);

  const handleUpdate = useCallback(() => {
    setOpenModal(false);
  }, []);

  const addDemontage = useCallback(
    (e) => {
      if (selectDemontable + 1 > quantitySelect) return;
      let listDemontable = [...countext?.data?.demontage];
      setSelectDemontable(selectDemontable + 1);
      listDemontable.push(parseInt(el?.id));
      let oldDiffDemontage = [
        ...countext?.difference?.demontage_remontage?.add,
      ];
      const formatDifDemo = {
        nom: el.nom,
        prix: el?.prix_montage * activeMajoration,
        id: el?.id,
      };

      const concatOllDif = oldDiffDemontage.concat(formatDifDemo);

      countext?.setDifference((difference) => ({
        ...difference,
        demontage_remontage: {
          add: concatOllDif,
          remove: difference?.demontage_remontage?.remove,
        },
      }));
      countext?.setData((data) => ({
        ...data,
        demontage: listDemontable,
      }));
      countext?.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            montage_demontage:
              data?.option_prix[0]?.montage_demontage + el?.prix_montage,
          },
        ],
      }));
    },
    [activeMajoration, countext, el, quantitySelect, selectDemontable]
  );

  const removeDemontage = useCallback(
    (e) => {
      if (selectDemontable - 1 < 0) return;
      setSelectDemontable(selectDemontable - 1);
      const listDemontable = [...countext?.data?.demontage];

      const index = listDemontable.indexOf(parseInt(el?.id));

      listDemontable.splice(index, 1);

      let saveObAdd = [...countext?.difference?.demontage_remontage?.remove];
      const formatDifDemo = {
        nom: el.nom,
        prix: el?.prix_montage * activeMajoration,
        id: el?.id,
      };

      saveObAdd.push(formatDifDemo);
      countext?.setDifference((difference) => ({
        ...difference,
        demontage_remontage: {
          add: difference?.demontage_remontage?.add,
          remove: saveObAdd,
        },
      }));
      countext?.setData((data) => ({
        ...data,
        demontage: listDemontable,
      }));
      countext?.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            montage_demontage:
              data?.option_prix[0]?.montage_demontage - el?.prix_montage,
          },
        ],
      }));
    },
    [
      activeMajoration,
      countext,
      el?.id,
      el.nom,
      el?.prix_montage,
      selectDemontable,
    ]
  );

  const handleAddMeuble = useCallback(() => {
    setQuantitySelect(quantitySelect + 1);
    const listObjet = [...countext?.data?.listing];
    let memoryModification = {
      nom: el.nom,
      price: priceM3(el, el.prix_m3).toFixed(2) * activeMajoration,
    };
    if (
      el?.poids === poidsModelEnum.MORE80.value ||
      el?.poids === poidsModelEnum.MORE80.enum
    ) {
      countext.setDifference((difference) => ({
        ...difference,
        etage_depart: {
          modified: true,
          value: difference?.etage_depart?.value ?? countext?.data?.etages[0],
          price: differenceOriginNewPriceEtage(
            countext?.data?.etages[0],
            pricing?.[10].prix,
            1,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.depart?.etage,
            orignalDevis?.etages[0]
          ),
        },
        etage_arrive: {
          modified: true,
          value: difference?.etage_arrive?.value ?? countext?.data?.etages[1],
          price: differenceOriginNewPriceEtage(
            countext?.data?.etages[1],
            pricing?.[10].prix,
            1,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.arrive?.etage,
            orignalDevis?.etages[1]
          ),
        },
        distance_logement_depart: {
          modified: true,
          value:
            difference?.distance_logement_depart?.value ??
            countext?.data?.distance_rue[0],
          price: calculPriceDistanceDifference(
            countext?.data?.distance_rue[0],
            true,
            pricing?.[11].prix,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.depart?.distance_logement,
            orignalDevis?.distance_rue[0]
          ),
        },
        distance_logement_arrive: {
          modified: true,
          value:
            difference?.distance_logement_arrive?.value ??
            countext?.data?.distance_rue[1],
          price: calculPriceDistanceDifference(
            countext?.data?.distance_rue[1],
            true,
            pricing?.[11].prix,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.arrive?.distance_logement,
            orignalDevis?.distance_rue[1]
          ),
        },
      }));

      countext?.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            bigWeight: data?.option_prix[0]?.bigWeight + weightPrice,
            depart: {
              ...data.option_prix[0].depart,
              etage: weightPriceEtage(
                countext?.data?.etages[0],
                pricing?.[10].prix,
                1
              ),
              distance_logement: calculPriceDistance(
                countext?.data?.distance_rue[0],
                true,
                pricing?.[11].prix
              ),
            },
            arrive: {
              ...data.option_prix[0].arrive,
              etage: weightPriceEtage(
                countext?.data?.etages[1],
                pricing?.[10].prix,
                1
              ),
              distance_logement: calculPriceDistance(
                countext?.data?.distance_rue[1],
                true,
                pricing?.[11].prix
              ),
            },
          },
        ],
      }));
      memoryModification = {
        nom: el.nom,
        price: (priceM3(el, el.prix_m3) + 200) * activeMajoration,
      };
    }

    let saveObAdd = [...countext?.difference?.meuble?.add];
    saveObAdd.push(memoryModification);
    countext?.setDifference((difference) => ({
      ...difference,
      meuble: {
        add: saveObAdd,
        remove: difference?.meuble?.remove,
      },
    }));
    countext?.setData((data) => ({
      ...data,
      option_prix: [
        {
          ...data.option_prix[0],
          meuble:
            countext?.data?.option_prix[0]?.meuble + priceM3(el, el?.prix_m3),
        },
      ],
    }));

    listObjet.push(el);
    countext?.setData((data) => ({
      ...data,
      listing: listObjet,
      volume: calculVolumeUserSelection(listObjet),
    }));
  }, [
    activeMajoration,
    countext,
    el,
    orignalDevis?.distance_rue,
    orignalDevis?.etages,
    orignalDevis?.option_prix,
    pricing,
    quantitySelect,
    weightPrice,
  ]);

  const handleRemoveMeuble = useCallback(() => {
    if (quantitySelect - 1 <= 0) {
      removeDemontage();
    }

    setQuantitySelect(quantitySelect - 1);
    const listObjet = [...countext?.data?.listing];

    if (
      countext?.data?.option_prix[0]?.bigWeight - weightPrice === 0 ||
      countext?.data?.option_prix[0]?.bigWeight - weightPrice < 0
    ) {
      console.log(
        "OPTION PRIX OBJECT 0 ZERO",
        countext?.data?.option_prix[0]?.bigWeight,
        weightPrice
      );
      countext.setDifference((difference) => ({
        ...difference,
        etage_depart: {
          modified: true,
          value: difference?.etage_depart?.value ?? countext?.data?.etages[0],
          price: differenceOriginNewPriceEtage(
            countext?.data?.etages[0],
            pricing?.[10].prix,
            0,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.depart?.etage,
            orignalDevis?.etages[0]
          ),
        },
        etage_arrive: {
          modified: true,
          value: difference?.etage_arrive?.value ?? countext?.data?.etages[1],
          price: differenceOriginNewPriceEtage(
            countext?.data?.etages[1],
            pricing?.[10].prix,
            0,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.arrive?.etage,
            orignalDevis?.etages[1]
          ),
        },
        distance_logement_depart: {
          modified: true,
          value:
            difference?.distance_logement_depart?.value ??
            countext?.data?.distance_rue[0],
          price: calculPriceDistanceDifference(
            countext?.data?.distance_rue[0],
            false,
            pricing?.[11].prix,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.depart?.distance_logement,
            orignalDevis?.distance_rue[0]
          ),
        },
        distance_logement_arrive: {
          modified: true,
          value:
            difference?.distance_logement_arrive?.value ??
            countext?.data?.distance_rue[1],
          price: calculPriceDistanceDifference(
            countext?.data?.distance_rue[1],
            false,
            pricing?.[11].prix,
            activeMajoration,
            orignalDevis?.option_prix?.[0]?.arrive?.distance_logement,
            orignalDevis?.distance_rue[1]
          ),
        },
      }));
      countext?.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            meuble:
              countext?.data?.option_prix[0]?.meuble - priceM3(el, el?.prix_m3),
            bigWeight: 0,
            depart: {
              ...data.option_prix[0].depart,
              etage: weightPriceEtage(
                countext?.data?.etages[0],
                pricing?.[10].prix,
                0
              ),
              distance_logement: calculPriceDistance(
                parseInt(countext?.data?.distance_rue[0]),
                0,
                pricing?.[11].prix
              ),
            },
            arrive: {
              ...data.option_prix[0].arrive,
              etage: weightPriceEtage(
                countext?.data?.etages[1],
                pricing?.[10].prix,
                0
              ),
              distance_logement: calculPriceDistance(
                parseInt(countext?.data?.distance_rue[1]),
                0,
                pricing?.[11].prix
              ),
            },
          },
        ],
      }));
    } else {
      if (
        el?.poids === poidsModelEnum.MORE80.value ||
        el?.poids === poidsModelEnum.MORE80.enum
      ) {
        countext.setDifference((difference) => ({
          ...difference,
          etage_depart: {
            modified: true,
            value: difference?.etage_depart?.value ?? countext?.data?.etages[0],
            price: differenceOriginNewPriceEtage(
              countext?.data?.etages[0],
              pricing?.[10].prix,
              1,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.depart?.etage,
              orignalDevis?.etages[0]
            ),
          },
          etage_arrive: {
            modified: true,
            value: difference?.etage_arrive?.value ?? countext?.data?.etages[1],
            price: differenceOriginNewPriceEtage(
              countext?.data?.etages[1],
              pricing?.[10].prix,
              1,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.arrive?.etage,
              orignalDevis?.etages[1]
            ),
          },
          distance_logement_depart: {
            modified: true,
            value:
              difference?.distance_logement_depart?.value ??
              countext?.data?.distance_rue[0],
            price: calculPriceDistanceDifference(
              countext?.data?.distance_rue[0],
              true,
              pricing?.[11].prix,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.depart?.distance_logement,
              orignalDevis?.distance_rue[0]
            ),
          },
          distance_logement_arrive: {
            modified: true,
            value:
              difference?.distance_logement_arrive?.value ??
              countext?.data?.distance_rue[1],
            price: calculPriceDistanceDifference(
              countext?.data?.distance_rue[1],
              true,
              pricing?.[11].prix,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.arrive?.distance_logement,
              orignalDevis?.distance_rue[1]
            ),
          },
        }));
        countext?.setData((data) => ({
          ...data,
          option_prix: [
            {
              ...data.option_prix[0],
              bigWeight: data?.option_prix[0]?.bigWeight - weightPrice,
              depart: {
                ...data.option_prix[0].depart,
                etage: weightPriceEtage(
                  countext?.data?.etages[0],
                  pricing?.[10].prix,
                  1
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[0],
                  1,
                  pricing?.[11].prix
                ),
              },
              arrive: {
                ...data.option_prix[0].arrive,
                etage: weightPriceEtage(
                  countext?.data?.etages[1],
                  pricing?.[10].prix,
                  1
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[1],
                  1,
                  pricing?.[11].prix
                ),
              },
            },
          ],
        }));
      }
    }

    const goodIndex = listObjet.indexOf(el);

    const final = listObjet.filter((el, i, arr) => i !== goodIndex);
    let memoryModification = {
      nom: el.nom,
      price: priceM3(el, el.prix_m3).toFixed(2) * activeMajoration,
    };
    if (
      el?.poids === poidsModelEnum.MORE80.value ||
      el?.poids === poidsModelEnum.MORE80.enum
    ) {
      memoryModification = {
        nom: el.nom,
        price: (200 + priceM3(el, el.prix_m3)) * activeMajoration,
      };
    }

    let saveOb = [...countext?.difference?.meuble?.remove];
    let saveObAdd = [...countext?.difference?.meuble?.add];
    const fusionOBSave = saveOb.concat(memoryModification);
    const indice = countext?.difference?.meuble?.add.findIndex((objet) => {
      return (
        objet.nom === el.nom &&
        objet.price === priceM3(el, el.prix_m3).toFixed(2) * activeMajoration
      );
    });

    if (indice !== -1) {
      saveObAdd.splice(indice, 1);
    }

    countext?.setDifference((difference) => ({
      ...difference,
      meuble: {
        add: saveObAdd,
        remove: fusionOBSave,
      },
    }));

    countext?.setData((data) => ({
      ...data,
      listing: final,
    }));

    countext?.setData((data) => ({
      ...data,
      volume: calculVolumeUserSelection(final),
    }));
  }, [
    activeMajoration,
    countext,
    el,
    orignalDevis?.distance_rue,
    orignalDevis?.etages,
    orignalDevis?.option_prix,
    pricing,
    quantitySelect,
    removeDemontage,
    weightPrice,
  ]);

  return (
    <div className={styles.lineMeuble}>
      <div className={styles.box}>
        <p className="value">{quantitySelect}</p>
      </div>
      <div className={styles.box}>
        <p className="value">{nom}</p>
      </div>
      <div className={styles.box}>
        {demontages ? (
          <div className={styles.containerInputCheck}>
            ({selectDemontable} / {globalQuantity} )
            <img src={Check} alt="logo" />
          </div>
        ) : (
          <div className={styles.containerInput}>
            <img src={Close} alt="logo" />
          </div>
        )}
      </div>
      <div>
        <button className="btnModify" onClick={() => setOpenModal(true)}>
          🪛
        </button>
      </div>
      {openModal && (
        <Modal handleClose={() => setOpenModal(false)}>
          <div>
            <p className={styles.titleObject}>{nom}</p>

            {/* <p className={styles.titlePiece}>
              {
                typePieces?.filter((piece) => el?.piece === piece.value)?.[0]
                  ?.label
              }
            </p> */}
            <div>
              <div className={styles.inline}>
                <label className="value">
                  Quantité (
                  {
                    typePieces?.filter(
                      (piece) => el?.piece === piece.value
                    )?.[0]?.label
                  }
                  ) :
                </label>
                <div className={styles.containerManage}>
                  <button
                    onClick={handleRemoveMeuble}
                    className={styles.incremment}
                  >
                    -
                  </button>
                  <span>{quantitySelect}</span>
                  <button
                    onClick={handleAddMeuble}
                    className={styles.incremment}
                  >
                    +
                  </button>
                </div>
              </div>
              {el?.demontable && (
                <div className={styles.inline}>
                  <label className="value">Démontable (Toutes pièces) :</label>
                  <div className={styles.containerManage}>
                    <button
                      onClick={removeDemontage}
                      className={styles.incremment}
                    >
                      -
                    </button>
                    <span>{selectDemontable}</span>
                    <button
                      onClick={addDemontage}
                      className={styles.incremment}
                    >
                      +
                    </button>
                  </div>
                </div>
              )}
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.buttondisquette}
                  text={"💾"}
                  onClick={handleUpdate}
                  type="primary"
                  size={"medium"}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
