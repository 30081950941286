import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./Adress.module.scss";
import Button from "../../../commons/Button";
import Input from "../../../commons/Input";

import { modifiedContext } from "../../../store/devisContext";
import { zipCodeVerication } from "../../../utils/zipCode";
export default function Adresses({ data, priceKm, optionPrix, allZipCode }) {
  const [value, setValue] = useState({
    depart: {
      rue: "",
      code: "",
      ville: "",
    },
    arrive: {
      rue: "",
      code: "",
      ville: "",
    },
  });
  const context = useContext(modifiedContext);
  const { setUpdateMajoration, orignalDevis } = useContext(modifiedContext);
  const [modifyDepart, setModifiedDepart] = useState(false);
  const [modifyArrive, setModifiedArrive] = useState(false);
  const [optionPrice, setOptionPrice] = useState(optionPrix);
  const [loading, setLoading] = useState(true);
  const [originalMajorationZip, setOriginalMajorationZip] = useState();

  const handleDetectMajorationZipCode = useCallback((allZip, code) => {
    const majoretedZipCode = zipCodeVerication(allZip, code);
    return majoretedZipCode;
  }, []);

  useEffect(() => {
    if (allZipCode && orignalDevis?.depart?.code) {
      setUpdateMajoration((updateMajoration) => ({
        ...updateMajoration,
        originalMajZip: handleDetectMajorationZipCode(
          allZipCode,
          orignalDevis?.depart?.code
        ),
      }));
    }
  }, [
    allZipCode,
    handleDetectMajorationZipCode,
    orignalDevis?.depart?.code,
    setUpdateMajoration,
  ]);

  const handleSave = useCallback(
    async (km) => {
      const neutralMajorationZip =
        optionPrice?.majoration - originalMajorationZip;
      let majoretedZipCode = parseFloat(
        (
          handleDetectMajorationZipCode(allZipCode, value?.depart?.code) +
          neutralMajorationZip
        ).toFixed(2)
      );

      setOriginalMajorationZip(majoretedZipCode);
      const myOptionPrice = [
        {
          depart: optionPrice?.depart,
          arrive: optionPrice?.arrive,
          majoration: optionPrice?.majoration,
          cartonPredict: optionPrice?.cartonPredict,
          meuble: optionPrice?.meuble,
          montage_demontage: optionPrice?.montage_demontage,
          emballage: optionPrice?.emballage,
          deballage: optionPrice?.deballage,
          assurance: optionPrice?.assurance,
          carton: optionPrice?.carton,
          livraison: optionPrice?.livraison,
          report: optionPrice?.report,
          bigWeight: optionPrice?.bigWeight,
          km: parseFloat((km * priceKm).toFixed(2)),
          frais_dossier: optionPrice?.frais_dossier,
          frais_modification: optionPrice?.frais_modification,
        },
      ];
      setUpdateMajoration((updateMajoration) => ({
        ...updateMajoration,
        isModifiedZip: true,
        zip: handleDetectMajorationZipCode(allZipCode, value?.depart?.code),
        isModified: true,
      }));
      context.setDifference((difference) => ({
        ...difference,
        distance: { modified: true, value: km },
      }));
      context.setData((data) => ({
        ...data,
        total_km: km,
        arrive: value.arrive,
        depart: value.depart,
        option_prix: myOptionPrice,
      }));
    },
    [
      allZipCode,
      context,
      handleDetectMajorationZipCode,
      optionPrice?.arrive,
      optionPrice?.assurance,
      optionPrice?.bigWeight,
      optionPrice?.carton,
      optionPrice?.cartonPredict,
      optionPrice?.deballage,
      optionPrice?.depart,
      optionPrice?.emballage,
      optionPrice?.frais_dossier,
      optionPrice?.frais_modification,
      optionPrice?.livraison,
      optionPrice?.majoration,
      optionPrice?.meuble,
      optionPrice?.montage_demontage,
      optionPrice?.report,
      originalMajorationZip,
      priceKm,
      setUpdateMajoration,
      value.arrive,
      value.depart,
    ]
  );

  const catchKmModify = useCallback(async () => {
    const request = {
      origins: [value.depart.ville],
      destinations: [value.arrive.ville],
      travelMode: "DRIVING",
    };
    const services = new window.google.maps.DistanceMatrixService();

    await services
      .getDistanceMatrix(request)
      .then((res) => {
        if (res.rows[0].elements?.[0].status === "OK");
        {
          const km = parseFloat(
            (res.rows[0].elements[0].distance.value / 1000).toFixed(2)
          );
          handleSave(km);
        }
      })
      .catch((err) => console.log(err));
  }, [handleSave, value.arrive.ville, value.depart.ville]);

  const handleModifiedDepart = useCallback(async () => {
    catchKmModify();
    setModifiedDepart(false);
  }, [catchKmModify]);

  const handleModifiedArrive = useCallback(() => {
    setModifiedArrive(false);
    catchKmModify();
  }, [catchKmModify]);

  useEffect(() => {
    setValue(data);
    if (allZipCode) {
      setOriginalMajorationZip(
        handleDetectMajorationZipCode(allZipCode, data?.depart?.code)
      );
    }

    setLoading(false);
  }, [allZipCode, data, handleDetectMajorationZipCode]);

  if (loading) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      {modifyDepart ? (
        <div className={styles.lineAdress}>
          <div>
            <Input
              onChange={(e) =>
                setValue({
                  ...value,
                  depart: {
                    ...value?.depart,
                    rue: e.target.value,
                  },
                })
              }
              value={value?.depart?.rue}
            />
            <Input
              onChange={(e) =>
                setValue({
                  ...value,
                  depart: {
                    ...value?.depart,
                    code: e.target.value,
                  },
                })
              }
              value={value?.depart?.code}
            />
            <Input
              onChange={(e) => {
                setValue({
                  ...value,
                  depart: {
                    ...value?.depart,
                    ville: e.target.value,
                  },
                });
              }}
              value={value?.depart?.ville}
            />
          </div>
          <div className={styles.inlineButton}>
            <button
              className="btnModify"
              onClick={() => setModifiedDepart(false)}
            >
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleModifiedDepart}
            />
          </div>
        </div>
      ) : (
        <div className={styles.lineAdress}>
          <p>
            <span className="weigthBoldmodif"> Départ :</span>{" "}
            <span className="valuemodif">
              {" "}
              {value?.depart?.rue} {value?.depart?.code} {value?.depart?.ville}
            </span>
          </p>
          <button className="btnModify" onClick={() => setModifiedDepart(true)}>
            🪛
          </button>
        </div>
      )}
      {modifyArrive ? (
        <div className={styles.lineAdress}>
          <div>
            <Input
              onChange={(e) =>
                setValue({
                  ...value,
                  arrive: {
                    ...value.arrive,
                    rue: e.target.value,
                  },
                })
              }
              value={value?.arrive?.rue}
            />
            <Input
              onChange={(e) =>
                setValue({
                  ...value,
                  arrive: { ...value.arrive, code: e.target.value },
                })
              }
              value={value?.arrive?.code}
            />
            <Input
              onChange={(e) =>
                setValue({
                  ...value,
                  arrive: { ...value.arrive, ville: e.target.value },
                })
              }
              value={value?.arrive?.ville}
            />
          </div>
          <div className={styles.inlineButton}>
            <button
              className="btnModify"
              onClick={() => setModifiedArrive(false)}
            >
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleModifiedArrive}
            />
          </div>
        </div>
      ) : (
        <div className={styles.lineAdress}>
          <p>
            <span className="weigthBoldmodif"> Arrivée :</span>{" "}
            <span className="valuemodif">
              {" "}
              {value?.arrive?.rue} {value?.arrive?.code} {value?.arrive?.ville}
            </span>
          </p>
          <button className="btnModify" onClick={() => setModifiedArrive(true)}>
            🪛
          </button>
        </div>
      )}
    </div>
  );
}
