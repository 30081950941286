import React, { useCallback, useMemo, useState } from "react";
import styles from "./LineChantier.module.scss";
import eyes from "../../icons/eyes.png";
import Modal from "../Modal";
import RecapForm from "../../components/RecapFormulaire";
import Note from "../../components/Note";
import RecapInfo from "../../components/RecapInfo";
import { format } from "date-fns/esm";
export default function LineChantier({ el, prestataireInformation }) {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState();
  const handleSeeMore = useCallback(() => {
    setType("devis");
    setModal(true);
  }, []);

  const handleReloadNote = useCallback(() => {
    setModal(false);
  }, []);

  const PrintCorrectModalChildren = useMemo(() => {
    switch (type) {
      case "note":
        return (
          <Note
            data={el?.client[0]?.note}
            clientId={el?.client[0]?.id}
            reload={handleReloadNote}
          />
        );

      case "devis":
        return (
          <RecapForm
            dissociate={true}
            prestataireInformation={prestataireInformation}
            data={el}
            step={el?.step}
            newScreen={(e) => setType(e)}
          />
        );
      case "info":
        return <RecapInfo data={el} handleReturn={() => setType("devis")} />;
      default:
        break;
    }
  }, [el, handleReloadNote, prestataireInformation, type]);

  return (
    <div key={el.id} className={styles.line}>
      <div className={styles.elementLine}>
        <p>ID</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>{el.id}</p>
        </div>
      </div>
      <div className={styles.elementLine}>
        <p>Date</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>
            {format(new Date(el.date_demenagement), "dd/MM/yyyy")}
          </p>
        </div>
      </div>
      <div className={styles.elementLine}>
        <p>Flexible</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>
            {el?.flexible?.fin === "" ? "Non" : "Oui"}
          </p>
        </div>
      </div>
      <div className={styles.elementLine}>
        <p>Volume</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>
            {el.volume + el?.emb_deb_data?.volume ?? 0}m3
          </p>
        </div>
      </div>
      <div className={styles.elementLine}>
        <p>Départ</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>{el?.depart?.ville}</p>
        </div>
      </div>
      <div className={styles.elementLine}>
        <p>Arrivé</p>
        <div className={styles.elementValue}>
          <p className={styles.value}>{el?.arrive?.ville}</p>
        </div>
      </div>

      <div className={styles.btncontainer}>
        <button className={"seeMoreBtn"} onClick={handleSeeMore}>
          <img className="iconsImage" src={eyes} alt="see more" />
        </button>
      </div>
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          {PrintCorrectModalChildren}
        </Modal>
      )}
    </div>
  );
}
