export const poids = ["- 80kg", "Entre 80 kg à 200kg"];

export const poidsModelEnum = {
  LESS80: {
    value: "- 80kg",
    enum: "LESS80",
  },
  MORE80: {
    value: "Entre 80 kg à 200kg",
    enum: "MORE80",
  },
};
