import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateStatusCommandeClient } from "../../apiCalls/clients";

import { deleteDevis } from "../../apiCalls/devis";
import Button from "../../commons/Button";
import LineClient from "../../commons/LineClient";
import Modal from "../../commons/Modal";
import SearchBar from "../../commons/SearchBar/SearchBar";
import { useGlobalContext } from "../../store/globalContext";
import { removeAccents } from "../../utils/AccentRemove";

import styles from "./Clients.module.scss";

export default function Client({ data, type }) {
  const [clientCrm, setClientCrm] = useState(data);
  const [devisSelect, setDevisSelect] = useState();
  const { userInformation } = useGlobalContext();
  const history = useNavigate();

  const [leadChoose, setLeadChoose] = useState();

  useEffect(() => {
    setClientCrm(data);
  }, [data]);

  const [modal, setModal] = useState(false);
  const filterClient = useCallback(
    (e) => {
      if (e === "") {
        setClientCrm(data);
        return;
      }

      const resultat = clientCrm.filter(
        (el) =>
          removeAccents(el.nom).toLowerCase().indexOf(removeAccents(e)) > -1 ||
          el.client[0]?.email?.toLowerCase().indexOf(e) > -1 ||
          el.telephone.indexOf(e) > -1
      );

      setClientCrm(resultat);
    },
    [clientCrm, data]
  );

  const handleSeeMore = useCallback(() => {
    setModal(true);
  }, []);

  const handleOpenNote = useCallback((e) => {
    setModal(true);
  }, []);

  const handleDelete = useCallback((type, e) => {
    setLeadChoose(e.id);
    setDevisSelect(e);
    setModal(true);
  }, []);

  const handleSubmitDelete = useCallback(async () => {
    const uuid = devisSelect?.unique_id;
    const infoUser = devisSelect?.client[0]?.formulaire_id?.map((el) => ({
      date: el.date,
      devis_id: el.devis_id,
      status: el.status,
    }));
    const formId = infoUser.filter((el) => el.devis_id !== uuid);

    UpdateStatusCommandeClient({
      id: parseInt(devisSelect?.client[0].id),
      formulaire_id: formId,
    })
      .then(() => {
        deleteDevis({ id: parseInt(leadChoose) })
          .then(() => {
            setLeadChoose("");
            setModal(false);
            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("this ", err));

    return;
  }, [devisSelect?.client, devisSelect?.unique_id, leadChoose]);

  return (
    <div className={styles.container}>
      <div className="header">
        <div className={styles.buttonLeadOrClient}>
          <SearchBar onChange={filterClient} />
          <Button
            className={styles.buttonLeadOrClienttaille}
            type={"primary"}
            size={"small"}
            text={type === "Lead" ? "Clients" : "Leads"}
            onClick={() =>
              type === "Lead"
                ? history(`/clients/${userInformation.id}`)
                : history(`/leads/${userInformation.id}`)
            }
          />
        </div>
      </div>
      {clientCrm?.map((el, i) => (
        <LineClient
          key={el.id}
          OpenNote={handleOpenNote}
          seeMore={() => handleSeeMore(el)}
          el={el}
        >
          <div className={styles.containerBtn}>
            <a
              className="buttonModify"
              href={`/modification/3/${el.id}/${el.unique_id}`}
              target="_blanck"
            >
              <span style={{ fontSize: "2rem" }}>⚙️</span>
            </a>
            {
              <Button
                className={styles.buttonannuler}
                type={"delete"}
                onClick={() => handleDelete("devis", el)}
                text={<span style={{ fontSize: "2rem" }}>🗑️</span>}
              />
            }
          </div>
        </LineClient>
      ))}
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          <div className={styles.childrenModal}>
            <h3 className="orangeText">Suppression du Lead n°{leadChoose}</h3>
            <br></br>
            <p>Vous êtes sur le point de supprimer définitivement !</p>
            <div className={styles.buttonModalContainer}>
              <Button
                type={"primary"}
                size="small"
                text={"Supprimer définitivement"}
                onClick={handleSubmitDelete}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
