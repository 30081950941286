import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { getAllPrestataire } from "../../apiCalls/prestataires";
import Button from "../../commons/Button";
import LinePresta from "../../commons/LinePresta";
import SearchBar from "../../commons/SearchBar/SearchBar";
import CreatePresta from "../../components/CreatePresta";
import DescriptionPresta from "../../components/DescriptionPresta";
import Navbar from "../../components/navbar";

import { useGlobalContext } from "../../store/globalContext";
import { removeAccents } from "../../utils/AccentRemove";

export default function Prestaires() {
  const context = useGlobalContext();
  const [type, setType] = useState("ALL");
  const [allPrestataire, setAllPrestataire] = useState([]);
  const [selectPrestaire, setSelectPrestaire] = useState();
  const [words, setWords] = useState("");
  const catchAllPresta = useCallback(async () => {
    await getAllPrestataire()
      .then((res) => {
        const data = res.data.getAllPrestataireInformation;
        setAllPrestataire(data.slice().sort((a, b) => b.ca_total - a.ca_total));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    catchAllPresta();
  }, [catchAllPresta]);

  const handleSeeMore = useCallback((e) => {
    setType("SEE");
    setSelectPrestaire(e);
  }, []);

  const printCorrectElement = useMemo(() => {
    switch (type) {
      case "ALL":
        if (words.length > 2) {
          return allPrestataire?.map(
            (el) =>
              (el.telephone.toLowerCase().indexOf(words) > -1 ||
                removeAccents(el.raison_social)
                  .toLowerCase()
                  .indexOf(removeAccents(words)) > -1 ||
                el.email.toLowerCase().indexOf(words) > -1) && (
                <LinePresta
                  key={el.id}
                  el={el}
                  seeMore={() => handleSeeMore(el)}
                />
              )
          );
        }
        return allPrestataire?.map((el) => (
          <LinePresta key={el.id} el={el} seeMore={() => handleSeeMore(el)} />
        ));
      case "SEE":
        return (
          <DescriptionPresta
            handleBack={() => setType("ALL")}
            data={selectPrestaire}
          />
        );
      case "NEW":
        return <CreatePresta handleBack={() => setType("ALL")} />;
      default:
        return <></>;
    }
  }, [allPrestataire, handleSeeMore, selectPrestaire, type, words]);

  const handleFilter = useCallback((e) => {
    setWords(e.toLowerCase());
  }, []);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={3} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Prestaires</h2>
        <div className={"managePresta"}>
          <Button
            text={<span style={{ fontSize: "1.2rem", padding: "1.2rem" }}>➕ Créer un nouveau prestataire</span>}
            type="primary"
            onClick={() => setType("NEW")}
          />
        </div>
        {type === "ALL" && (
          <div className="searcBarContainer">
            <SearchBar onChange={handleFilter} />
          </div>
        )}
        <div className="containerElement">{printCorrectElement}</div>
      </div>
    </div>
  );
}
