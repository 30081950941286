import classNames from "classnames";
import React, { useCallback } from "react";
import { useState } from "react";
import { createFerie } from "../../apiCalls/ferie";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import styles from "./Ferie.module.scss";
import LineFerie from "./line";
export default function Ferie({ data }) {
  const [create, setCreate] = useState(false);
  const [value, setValue] = useState({
    title: "",
    day: "",
    month: "",
  });

  const handleSubmit = useCallback(async () => {
    await createFerie(value)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [value]);

  return (
    <div className={styles.container}>
      <br />
      <div className={styles.header}>
        {!create && (
          <Button
            className={styles.buttonAdd}
            type={"primary"}
            text="➕ Ajouter"
            size={"small"}
            onClick={() => setCreate(true)}
          />
        )}
        <h2>Jour{data?.length > 0 && "s"} Fériés</h2>
      </div>

      <br />
      <div className={classNames(styles.line, styles.orangeLine)}>
        <div className={styles.name}>Nom</div>
        <div className={styles.monthDate}>Jour / Mois</div>
      </div>
      {data?.map((el) => (
        <LineFerie key={el.id} el={el} />
      ))}
      {create && (
        <div className={styles.line}>
          <div className={styles.name}>
            <Input
              value={value.title}
              onChange={(e) => setValue({ ...value, title: e.target.value })}
            />
          </div>
          <div className={styles.monthDate}>
            <div className={styles.inputLine}>
              <Input
                value={value.day}
                onChange={(e) => setValue({ ...value, day: e.target.value })}
              />
              <span> / </span>
              <Input
                value={value.month}
                onChange={(e) => setValue({ ...value, month: e.target.value })}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              text={"Annuler"}
              size={"small"}
              onClick={() => setCreate(false)}
              type="secondary"
            />
            <Button
              text={"Valider"}
              size={"small"}
              onClick={handleSubmit}
              type="primary"
            />
          </div>
        </div>
      )}
    </div>
  );
}
