import React, { useCallback, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useState } from "react";
import { useEffect } from "react";

export default function RichText({ value, onChange }) {
  const editorReference = useRef();
  const [editorState, setEditorState] = useState();
  useEffect(() => {
    editorReference.current.focusEditor();
    if (value) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      return;
    }
    setEditorState(EditorState.createEmpty());
  }, [editorReference]);

  const onEditorStateChange = useCallback(
    (data) => {
      setEditorState(data);
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    },
    [editorState, onChange]
  );

  return (
    <Editor
      ref={editorReference}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        image: {
          uploadEnabled: false,
          alt: { present: true, mandatory: true },
          urlEnabled: true,
          alignmentEnabled: false,
          previewImage: true,
          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          defaultSize: {
            height: "auto",
            width: "auto",
          },
        },
        fontFamily: {
          options: ["Montserrat", "inter", "roboto"],
        },
        emoji: {
          emojis: [
            "😀",
            "😁",
            "😂",
            "😃",
            "😉",
            "😋",
            "😎",
            "😍",
            "😗",
            "🤗",
            "🤔",
            "😣",
            "😫",
            "😴",
            "😌",
            "🤓",
            "😛",
            "😜",
            "😠",
            "😇",
            "😷",
            "😈",
            "👻",
            "😺",
            "😸",
            "😹",
            "😻",
            "😼",
            "😽",
            "🙀",
            "🙈",
            "🙉",
            "🙊",
            "👼",
            "👮",
            "🕵",
            "💂",
            "👳",
            "🎅",
            "👸",
            "👰",
            "👲",
            "🙍",
            "🙇",
            "🚶",
            "🏃",
            "💃",
            "⛷",
            "🏂",
            "🏌",
            "🏄",
            "🚣",
            "🏊",
            "⛹",
            "🏋",
            "🚴",
            "👫",
            "💪",
            "👈",
            "👉",
            "👉",
            "👆",
            "🖕",
            "👇",
            "🖖",
            "🤘",
            "🖐",
            "👌",
            "👍",
            "👎",
            "✊",
            "👊",
            "👏",
            "🙌",
            "🙏",
            "🐵",
            "🐶",
            "🐇",
            "🐥",
            "🐸",
            "🐌",
            "🐛",
            "🐜",
            "🐝",
            "🍉",
            "🍄",
            "🍔",
            "🍤",
            "🍨",
            "🍪",
            "🎂",
            "🍰",
            "🍾",
            "🍷",
            "🍸",
            "🍺",
            "🌍",
            "🚑",
            "⏰",
            "🌙",
            "🌝",
            "🌞",
            "⭐",
            "🌟",
            "🌠",
            "🌨",
            "🌩",
            "⛄",
            "🔥",
            "🎄",
            "🎈",
            "🎉",
            "🎊",
            "🎁",
            "🎗",
            "🏀",
            "🏈",
            "🎲",
            "🔇",
            "🔈",
            "📣",
            "🔔",
            "🎵",
            "🎷",
            "💰",
            "🖊",
            "📅",
            "✅",
            "❎",
            "💯",
          ],
        },
      }}
    />
  );
}
