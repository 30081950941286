import React, { useCallback, useState } from "react";
import {
  deleteMajorationZipCode,
  updateMajorationZipCode,
} from "../../apiCalls/zipCode";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import styles from "./LineCodePostal.module.scss";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
export default function LineCodePostal({ data }) {
  const [modify, setModify] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [newvalue, setNewValue] = useState({
    majoration: data?.majoration,
    list: data?.list,
    id: data?.id,
  });
  const handleSubmit = useCallback(async () => {
    const formatValue = newvalue?.list?.map((el) => el.value);
    const dataSend = {
      id: parseInt(data?.id),
      majoration: parseFloat(newvalue?.majoration),
      list: formatValue,
    };
    await updateMajorationZipCode({ value: dataSend }).then((res) =>
      window.location.reload()
    );
  }, [data?.id, newvalue?.list, newvalue?.majoration]);

  const handleOpenBox = useCallback(() => {
    setOpenBox(!openBox);
  }, [openBox]);

  const handleDelete = useCallback(async () => {
    await deleteMajorationZipCode({ id: data?.id }).then((res) =>
      window.location.reload()
    );
  }, [data?.id]);

  const catchValueTagify = useCallback(
    (e) => {
      setNewValue({ ...newvalue, list: e.detail.tagify.getCleanValue() });
    },
    [newvalue]
  );

  return (
    <div className={styles.container}>
      {modify ? (
        <div className={styles.tagContainer}>
          <Tags
            className={styles.inputTag}
            defaultValue={newvalue?.list}
            autoFocus={false}
            onChange={catchValueTagify}
            settings={{
              maxTags: 100,
              required: true,
              placeholder: "Code postal",

              delimiters: ",| |:",
              duplicates: false,
            }}
          />
        </div>
      ) : (
        <div onClick={handleOpenBox} className={styles.lineContainer}>
          <p>Liste des codes postaux majorées à {data?.majoration * 100}% :</p>
          <span className={styles.dropDownIcon}></span>
          {openBox && (
            <div className={styles.boxValue}>
              <ul>
                {data?.list?.map((el, i) => (
                  <li key={i} className={styles.linezip}>
                    {el}
                  </li>
                ))}
              </ul>
              <div></div>
            </div>
          )}
        </div>
      )}

      {modify ? (
        <div className={styles.majorationContainerInput}>
          <Input
            min={0}
            max={1}
            value={newvalue?.majoration}
            onChange={(e) =>
              setNewValue({ ...newvalue, majoration: e.target.value })
            }
          />
        </div>
      ) : (
        <div className={styles.majorationContainer}>{data?.majoration}%</div>
      )}
      <div className={styles.buttonContainer}>
        {modify ? (
          <>
            <Button
              text={"Annuler"}
              size={"small"}
              onClick={() => {
                setModify(false);
                setNewValue({
                  majoration: data?.majoration,
                  list: data?.list,
                  id: data?.id,
                });
              }}
              type="secondary"
            />
            <Button
              text={"Valider"}
              size={"small"}
              onClick={handleSubmit}
              type="primary"
            />
          </>
        ) : (
          <>
            <Button
              className="buttonModify"
              onClick={() => {
                setModify(!modify);
              }}
              text={<span style={{ fontSize: "2rem", padding: "0.5rem" }}>⚙️</span>}
              type="modify"
            />
            <Button
              onClick={handleDelete}
              text={<span style={{ fontSize: "2rem", padding: "0.5rem"}}>🗑️</span>}
              type="delete"
            />
          </>
        )}
      </div>
    </div>
  );
}
