import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getAllDevis } from "../../apiCalls/devis";
import Loader from "../../commons/Loader";
import Navbar from "../../components/navbar";
import Client from "../../section/Clients";
import { useGlobalContext } from "../../store/globalContext";

export default function ClientPage() {
  const context = useGlobalContext();
  const [allDevis, setAllDevis] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleCatchCLients = useCallback(async () => {
    await getAllDevis()
      .then((res) => {
        setAllDevis(
          res.data.getCommandeAdmin
            .filter((el) => el.status === "VALIDATED")
            .reverse()
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    handleCatchCLients();
  }, [handleCatchCLients]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={2} />

      <div className={"leftSide"}>
        <h2 className={"title"}>Clients</h2>
        {loading && <Loader full />}
        <Client data={allDevis} refresh={handleCatchCLients} />
      </div>
    </div>
  );
}
