import { gql } from "@apollo/client";

import titan from "../../AppoloClient/apollo-client";

export const createUtiliseur = async ({
  utilisateur,
  email,
  telephone,
  password,
  email_connexion,
  service,
  region,
  region_name,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateAdmin(
        $utilisateur: String
        $email: String
        $password: String
        $telephone: String
        $emailConnexion: String
        $service: String
        $region: [String]
        $regionName: String
      ) {
        createAdmin(
          utilisateur: $utilisateur
          email: $email
          password: $password
          telephone: $telephone
          email_connexion: $emailConnexion
          service: $service
          region: $region
          region_name: $regionName
        ) {
          utilisateur
        }
      }
    `,
    variables: {
      utilisateur: utilisateur,
      email: email,
      password: password,
      telephone: telephone,
      emailConnexion: email_connexion,
      service: service,
      region: region,
      regionName: region_name,
    },
  });
  return { data, errors };
};

export const loginAdmin = async ({ email, password }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query LoginAdmin($email: String, $password: String) {
        loginAdmin(email: $email, password: $password) {
          id
          utilisateur
          email
          telephone
          password
          email_connexion
          service
          region
          token
          statut
        }
      }
    `,
    variables: {
      email: email,
      password: password,
    },
  });
  return { data, errors };
};

export const getAdminById = async ({ id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query ExampleQuery($getAdminByIdId: ID) {
        getAdminById(id: $getAdminByIdId) {
          id
          utilisateur
          email
          telephone
          password
          email_connexion
          service
          region
          token
          statut
        }
      }
    `,
    variables: {
      getAdminByIdId: id,
    },
  });
  return { data, errors };
};

export const getAllAdmin = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllAdmin {
        getAllAdmin {
          id
          utilisateur
          email
          telephone
          password
          email_connexion
          service
          region
          token
          statut
          region_name
        }
      }
    `,
  });
  return { data, errors };
};

export const deleteAdmin = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteAdmin($deleteAdminId: ID) {
        deleteAdmin(id: $deleteAdminId) {
          id
          utilisateur
          email
          telephone
          password
          email_connexion
          service
          region
          token
          statut
        }
      }
    `,
    variables: {
      deleteAdminId: id,
    },
  });
  return { data, errors };
};
