export const typePieces = [
  {
    label: "Salon / Salle à manger",
    value: 1,
  },
  {
    label: "Cuisine",
    value: 2,
  },
  {
    label: "Chambre",
    value: 3,
  },
  {
    label: "Salle de bain",
    value: 4,
  },
  {
    label: "Garage et Jardin",
    value: 5,
  },
  {
    label: "Divers",
    value: 6,
  },
];
