import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAdminById, loginAdmin } from "../../apiCalls/admin";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import styles from "./SignIn.module.scss";
import { deleteCookie, setCookie } from "../../utils/cookies";
import { verifToken } from "../../utils/token";
import { useGlobalContext } from "../../store/globalContext";
export default function SignIn() {
  const navigate = useNavigate();
  const context = useGlobalContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useState(() => {
    if (window) {
      verifToken().then((res) => {
        if (res.isValid) {
          getAdminById({ id: res.id }).then((res) => {
            context.handleSetUser(res.data.getAdminById[0]);
          });
          navigate(`/dashboard/${res.id}`);
        } else {
          deleteCookie("titan_admin_user");
          return;
        }
      });
    }
  }, []);

  const redirect = useCallback(
    (id) => {
      console.log("enter");
      setTimeout(() => {
        navigate(`/dashboard/${id}`);
      }, 1000);
    },
    [navigate]
  );

  const handleOpen = useCallback(async () => {
    await loginAdmin({ email: email, password: password })
      .then((res, req) => {
        setCookie("titan_admin_user", res.data.loginAdmin[0].token, 1);
        redirect(res.data.loginAdmin[0].id);
      })
      .catch((err) => console.log(err));
  }, [email, password, redirect]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p className={styles.title}>Connexion administrateur</p>
        <div className={styles.containerInput}>
          <Input
            className={styles.input}
            type="text"
            placeholder={"Votre identifiant"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            className={styles.input}
            type="password"
            placeholder={"Votre mot de passe"}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            text={"Se connecter"}
            size={"medium"}
            onClick={() => handleOpen()}
          />
        </div>
      </div>
    </div>
  );
}
