import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import Button from "../../../../commons/Button";
import Input from "../../../../commons/Input";
import Select from "../../../../commons/Select";
import { majoration } from "../../../../utils/ENUM/majoration";

import styles from "./GeneralLine.module.scss";
export default function GeneralLine({ label, value, handleUpdate, type }) {
  const [modified, setModified] = useState(false);
  const [name, setName] = useState();
  useEffect(() => {
    if (type === "Date") {
      setName(format(new Date(value?.replaceAll("-", "/")), "dd/MM/yyyy"));
      return;
    }
    setName(value);
  }, [type, value]);

  const compo = useMemo(() => {
    if (type === "Majoration") {
      return (
        <Select value={name} data={majoration} onChange={(e) => setName(e)} />
      );
    }
    if (type === "Flexible") {
      return (
        <div>
          <Input
            disabled={true}
            value={name?.debut}
            placeholder={"Flexible début"}
            onChange={(e) =>
              setName({ ...name, debut: e.target.value?.replaceAll("/", "-") })
            }
          />
          <Input
            value={name?.fin}
            placeholder={"Flexible fin"}
            onChange={(e) =>
              setName({ ...name, fin: e.target.value?.replaceAll("/", "-") })
            }
          />
        </div>
      );
    }

    return <Input value={name} onChange={(e) => setName(e.target.value)} />;
  }, [name, type]);

  const handleSave = useCallback(() => {
    if (type === "Date") {
      const format = name.replaceAll("/", "-");
      const test =
        format.split("-")[2] +
        "-" +
        format.split("-")[1] +
        "-" +
        format.split("-")[0];
      handleUpdate(test);
    } else {
      handleUpdate(name);
    }

    setModified(false);
  }, [handleUpdate, name, type]);

  const myName = useMemo(() => {
    if (type === "Majoration") {
      if (value === 1) {
        return "Non";
      }
      return "Oui";
    }
    if (type === "Flexible") {
      return value?.fin !== ""
        ? `Oui du ${value?.debut?.replaceAll(
            "-",
            "/"
          )} au ${value?.fin?.replaceAll("-", "/")}`
        : "Non";
    }
    if (type === "Date") {
      if (value) {
        return format(new Date(value?.replaceAll("-", "/")), "dd/MM/yyyy");
      }
    }
    return value;
  }, [type, value]);

  return (
    <>
      {modified ? (
        <div className={styles.container}>
          <div>{compo}</div>
          <div className={styles.inlineButton}>
            <button className="btnModify" onClick={() => setModified(false)}>
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleSave}
            />
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div>
            <p>
              <span className="weigthBold">{label} :</span>{" "}
              <span className={styles.value}> {myName}</span>
            </p>
          </div>
          <button className="btnModify" onClick={() => setModified(true)}>
            🪛
          </button>
        </div>
      )}
    </>
  );
}
