import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { getAllDevis } from "../../apiCalls/devis";
import Loader from "../../commons/Loader";
import Navbar from "../../components/navbar";
import Crm from "../../section/Crm";
import { useGlobalContext } from "../../store/globalContext";

export default function CRMPage() {
  const context = useGlobalContext();
  const [allDevis, setAllDevis] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleCatchCLients = useCallback(async () => {
    await getAllDevis()
      .then((res) => {
        const resultat = res.data.getCommandeAdmin.filter(
          (el) => el.status === "EN COUR" || el.status === "PAYED"
        );

        setAllDevis(resultat.reverse());
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    handleCatchCLients();
  }, [handleCatchCLients]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={1} />
      <div className={"leftSide"}>
        <h2 className={"title"}>CRM</h2>
        {loading && <Loader />}
        <Crm
          data={allDevis}
          refresh={handleCatchCLients}
          reload={handleCatchCLients}
        />
      </div>
    </div>
  );
}
