import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getAllBlog = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query RootQuery {
        getAllBlog {
          id
          title
          text
          date
          visible
          image_principal
          meta_title
          meta_description
        }
      }
    `,
  });
  return { data, errors };
};

export const createBlog = async ({
  text,
  title,
  visible,
  slug,
  date,
  image_principal,
  metaDescription,
  metaTitle,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateBlog(
        $text: String
        $title: String
        $date: String
        $visible: Boolean
        $slug: String
        $imagePrincipal: String
        $metaDescription: String
        $metaTitle: String
      ) {
        createBlog(
          text: $text
          title: $title
          date: $date
          visible: $visible
          slug: $slug
          image_principal: $imagePrincipal
          meta_description: $metaDescription
          meta_title: $metaTitle
        ) {
          id
          title
          text
          date
          visible
          slug
          image_principal
          meta_description
          meta_title
        }
      }
    `,
    variables: {
      title: title,
      text: text,
      date: date,
      visible: visible,
      slug: slug,
      imagePrincipal: image_principal,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    },
  });
  return { data, errors };
};

export const updateBlog = async ({
  id,
  title,
  text,
  date,
  visible,
  slug,
  image_principal,
  metaDescription,
  metaTitle,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateBlog(
        $imagePrincipal: String
        $slug: String
        $date: String
        $text: String
        $title: String
        $updateBlogId: ID
        $visible: Boolean
        $metaDescription: String
        $metaTitle: String
      ) {
        updateBlog(
          image_principal: $imagePrincipal
          slug: $slug
          date: $date
          text: $text
          title: $title
          id: $updateBlogId
          visible: $visible
          meta_description: $metaDescription
          meta_title: $metaTitle
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      updateBlogId: id,
      title: title,
      text: text,
      date: date,
      visible: visible,
      slug: slug,
      imagePrincipal: image_principal,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    },
  });
  return { data, errors };
};

export const deleteBlog = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteBlog($deleteBlogId: ID) {
        deleteBlog(id: $deleteBlogId) {
          successful
          message
        }
      }
    `,
    variables: {
      deleteBlogId: id,
    },
  });
  return { data, errors };
};
