import React from "react";

import Navbar from "../../components/navbar";
import Contact from "../../section/Contact";
import { useGlobalContext } from "../../store/globalContext";

export default function ContactPage() {
  const context = useGlobalContext();

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={9} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Contacts</h2>
        <Contact />
      </div>
    </div>
  );
}
