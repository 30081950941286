import titan from "../../AppoloClient/apollo-client";
import { gql } from "@apollo/client";

export const getSaison = async () => {
  const { data } = await titan.query({
    query: gql`
      query GetAllSaison {
        getAllSaison {
          id
          slug
          title
          start
          end
          majoration
          majorationRate
        }
      }
    `,
  });
  return { data };
};

export const updateSaison = async ({
  id,
  title,
  start,
  end,
  majoration,
  majorationRate,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateSaison(
        $updateSaisonId: ID
        $title: String
        $start: String
        $end: String
        $slug: String
        $majoration: Boolean
        $majorationRate: Float
      ) {
        updateSaison(
          id: $updateSaisonId
          title: $title
          start: $start
          end: $end
          slug: $slug
          majoration: $majoration
          majorationRate: $majorationRate
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      updateSaisonId: id,
      title: title,
      start: start,
      end: end,
      slug: title.toLowerCase().replaceAll(" ", "-"),
      majoration: majoration,
      majorationRate: majorationRate,
    },
  });
  return { data, errors };
};
export const CreateSaison = async ({
  title,
  start,
  end,
  majorationRate,
  majoration,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateSaison(
        $title: String
        $start: String
        $end: String
        $slug: String
        $majoration: Boolean
        $majorationRate: Float
      ) {
        createSaison(
          title: $title
          start: $start
          end: $end
          slug: $slug
          majoration: $majoration
          majorationRate: $majorationRate
        ) {
          id
          slug
          title
          start
          end
        }
      }
    `,
    variables: {
      title: title,
      start: start,
      end: end,
      slug: title.toLowerCase().replaceAll(" ", "-"),
      majoration: majoration,
      majorationRate: majorationRate,
    },
  });
  return { data, errors };
};
