import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./RecapForm.module.scss";
import Button from "../../commons/Button";

import Check from "../../icons/check.svg";
import Close from "../../icons/close.svg";
import {
  printCorrectQuantityIsSelect,
  printUniqueObject,
  totalQuantity,
} from "../../utils/duplicate";
import reverseDemontageMontage from "../../utils/reverseDemontage/index";
import { noop } from "lodash";
import CustomDropDown from "../../components/CustomDropDown";
import etapesEnum from "../../ENUMS/enumEtape";
import NewAvailable from "../../commons/LineChantier/Available";
import { getDevisPdfById } from "../../apiCalls/devisPdf";
export default function RecapForm({
  dissociate,
  prestataireInformation,
  data,
  step,
  newScreen,
}) {
  const more = [
    {
      id: 0,
      name: "Emballage",
      check: data?.emballage,
      choice: "",
    },
    {
      id: 1,
      name: "Déballage",
      check: data?.deballage,
      choice: "",
    },
    {
      id: 2,
      name: "Assurance",
      check: data?.assurance === null && false,
      choice:
        data?.assurance === null
          ? ""
          : data?.assurance === 0
          ? "De base"
          : "Premium 🎉",
    },
    {
      id: 3,
      name: "Report",
      check: data?.report === null && false,
      choice:
        data?.report === null
          ? ""
          : data?.report
          ? "AVEC Report"
          : "SANS Report",
    },
  ];

  const [allDevisPdf, setAllDevisPdf] = useState([]);
  const fixQuantity = useCallback(
    (id) => {
      return totalQuantity(id, data?.listing) ?? 0;
    },
    [data]
  );

  const newObject = useMemo(() => {
    if (data?.emb_deb_data?.maxCarton > 0) {
      let listing = [...data?.listing];
      const formatCarton = {
        id: "0",
        nom: "Cartons",
        selectPiece: 6,
        pieces: 6,
        visuel: "carton-standard",
        demontable: false,
        hauteur: 0,
        largeur: 0,
        poids: "LESS80",
        prix_m3: 0,
        prix_montage: null,
        profondeur: 0,
        sous_categorie: 13,
        supplement: 0,
        quantity: data?.emb_deb_data?.maxCarton,
      };
      listing.push(formatCarton);
      return listing;
    }
    return data?.listing;
  }, [data?.emb_deb_data?.maxCarton, data?.listing]);

  const myObjectSelect = useMemo(() => {
    return printUniqueObject(newObject);
  }, [newObject]);

  const demontages = reverseDemontageMontage(
    data?.demontage,
    myObjectSelect,
    null,
    true
  );

  const printCorrectSelectQuantity = useCallback((data, element) => {
    const value = printCorrectQuantityIsSelect(data, element.nom);
    return value;
  }, []);

  const handleOpen = useCallback(
    (i) => {
      newScreen(i);
    },
    [newScreen]
  );

  const handleDevis = useCallback(async (unique_id) => {
    await getDevisPdfById(unique_id).then((res) =>
      setAllDevisPdf(res.data.getDevisPdfById)
    );
  }, []);

  const myCarton = useMemo(() => {
    const uniqueCarton =
      data?.emb_deb_data?.cartonSelect?.map((el) => el.object) ?? [];
    return uniqueCarton;
  }, [data?.emb_deb_data?.cartonSelect]);

  const myAccessoire = useMemo(() => {
    const uniqueAccessoire =
      data.emb_deb_data?.accessoireSelect?.map((el) => el.object) ?? [];
    return uniqueAccessoire;
  }, [data?.emb_deb_data?.accessoireSelect]);

  const myEtape = useMemo(() => {
    return step === 2 ? "Inventaire" : etapesEnum[step - 1];
  }, [step]);

  useEffect(() => {
    handleDevis(data.unique_id);
  }, [data.unique_id, handleDevis]);

  return (
    <div className={styles.container}>
      <h2>Récapitulatif de la commande</h2>
      <p className={styles.step}>ÉTAPE : {myEtape}</p>
      <br />

      <CustomDropDown onClick={noop} label={"Objets"} withoutTotal>
        <div className={styles.containerTable} style={{ width: "100%" }}>
          <div className={styles.header}>
            <div className={styles.boxHeader}>
              <p>Quantité</p>
            </div>
            <div className={styles.boxHeader}>
              <p>Liste des meubles</p>
            </div>
            <div className={styles.boxHeader}>
              <p>Montage / Démontage</p>
            </div>
          </div>
          <div className={styles.maxHeigthTalbe}>
            {myObjectSelect?.map((element, i) => (
              <div
                key={`${element.id}-${element.nom}-${i}`}
                className={styles.lineMeuble}
              >
                <div className={styles.box}>
                  <p>{element?.quantity ?? fixQuantity(element.id)}</p>
                </div>
                <div className={styles.box}>
                  <p>{element.nom}</p>
                </div>
                <div className={styles.box}>
                  <p>
                    {demontages?.includes(element.nom) ? (
                      <div className={styles.containerInputCheck}>
                        ({printCorrectSelectQuantity(demontages, element)} /{" "}
                        {fixQuantity(element.id)} )
                        <img src={Check} alt="logo" />
                      </div>
                    ) : (
                      <div className={styles.containerInput}>
                        <img src={Close} alt="logo" />
                      </div>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomDropDown>

      {(data?.emb_deb_data?.cartonSelect?.length > 0 ||
        data?.emb_deb_data?.accessoireSelect > 0) && (
        <CustomDropDown onClick={noop} label={"Accessoires"} withoutTotal>
          {data?.emb_deb_data && (
            <div className={classNames(styles.containerTable)}>
              <div className={styles.header}>
                <div className={styles.boxHeader}>
                  <p>Quantité</p>
                </div>
                <div className={styles.boxHeader}>
                  <p>Articles</p>
                </div>
                <div className={styles.boxHeader}>
                  <p>Prix</p>
                </div>
              </div>

              <div className={styles.maxHeigthTalbe}>
                {myCarton?.map((element, i) => (
                  <div
                    key={`${element.id}-${element.nom}-${i}`}
                    className={styles.lineMeuble}
                  >
                    <div className={styles.box}>
                      <p>{data.emb_deb_data.cartonSelect[i].quantity}</p>
                    </div>
                    <div className={styles.box}>
                      <p>{element.nom}</p>
                    </div>
                    <div className={styles.box}>
                      <p>
                        {parseFloat(
                          data.emb_deb_data.cartonSelect[i].quantity *
                            element.prix
                        ).toFixed(2)}{" "}
                        €
                      </p>
                    </div>
                  </div>
                ))}
                {myAccessoire?.map((element, i) => (
                  <div
                    key={`${element.id}-${element.nom}-${i}`}
                    className={styles.lineMeuble}
                  >
                    <div className={styles.box}>
                      <p>{data.emb_deb_data.accessoireSelect[i].quantity}</p>
                    </div>
                    <div className={styles.box}>
                      <p>{element.nom}</p>
                    </div>
                    <div className={styles.box}>
                      <p>
                        {parseFloat(
                          data.emb_deb_data.accessoireSelect[i].quantity *
                            element.prix
                        ).toFixed(2)}{" "}
                        €
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CustomDropDown>
      )}

      <CustomDropDown onClick={noop} label={"Informations"} withoutTotal>
        <div className={styles.containerElement}>
          {more.map((element, i) => {
            return (
              <div
                key={`${element.id}-${element.name}`}
                className={classNames(styles.addtionnalElement)}
              >
                <div className={styles.additionalName}>
                  <p>{element.name}</p>
                </div>
                <div className={styles.additionalValue}>
                  <span>
                    {element.choice !== "" ? (
                      element.choice
                    ) : element.check ? (
                      <div className={styles.containerInput}>
                        <img src={Check} alt="logo" />
                      </div>
                    ) : (
                      <div className={styles.containerInput}>
                        {" "}
                        <img src={Close} alt="logo" />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </CustomDropDown>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttonrecapform}
          text={"➕ d'informations"}
          type="primary"
          size={"small"}
          onClick={() => handleOpen("info")}
        />
        {dissociate && (
          <NewAvailable devis={data} prestataire={prestataireInformation} />
        )}
        <Button
          className={styles.buttonrecapform}
          text={"État du paiement"}
          type="primary"
          size={"small"}
          onClick={() => handleOpen("paiement")}
        />
      </div>

      <div className={styles.listDevisPdf}>
        <h3>Les devis </h3>
        {allDevisPdf?.length > 0 ? (
          <div className={styles.listPdfContainer}>
            <hr></hr>
            {allDevisPdf.map((el, i) => (
              <a
                className={styles.devisLink}
                key={i}
                target="_blanck"
                href={el.link}
                aria-label="Voir devis"
              >
                Devis {el.key} généré le {el.creation_date}
              </a>
            ))}
          </div>
        ) : (
          <p className={styles.noDevisPdf}>
            <strong>Aucun</strong>
          </p>
        )}
      </div>
    </div>
  );
}
