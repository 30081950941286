import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./Contact.module.scss";
export default function Contact() {
  const [emailPro, setEmailPro] = useState();
  const [emailParticulier, setEmailParticulier] = useState();

  const handleCatchEmailPro = useCallback(async () => {
    await axios
      .get(
        "https://www.dev-server.titan-demenagement.com/api/email/email-send-pro"
      )
      .then((res) => setEmailPro(res.data?.length))
      .catch((err) => console.log(err));
  }, []);

  const handleCatchEmailParticulier = useCallback(async () => {
    await axios
      .get(
        "https://www.dev-server.titan-demenagement.com/api/email/email-send-part"
      )
      .then((res) => setEmailParticulier(res.data?.length))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    handleCatchEmailPro();
    handleCatchEmailParticulier();
  }, [handleCatchEmailParticulier, handleCatchEmailPro]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Les nouveaux contacts de la journée</h3>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <p className={styles.label}>Clients :</p>
          <p className={styles.number}>{emailParticulier}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.label}>Prestataires :</p>
          <p className={styles.number}>{emailPro}</p>
        </div>
      </div>
    </div>
  );
}
