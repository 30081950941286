import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getAllSubCategorie = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query AllSubCategorie {
        allSubCategorie {
          id
          nom
        }
      }
    `,
  });
  return { data, errors };
};
