import React, { useState, useCallback } from "react";
import { updateFerie } from "../../../apiCalls/ferie";
import Button from "../../../commons/Button";
import Input from "../../../commons/Input";
import styles from "./line.module.scss";

export default function LineFerie({ el }) {
  const [modified, setModified] = useState(false);
  const [myvalue, setMyValue] = useState({
    id: parseInt(el.id),
    title: el.title,
    day: el.day,
    month: el.month,
  });

  const handleSubmit = useCallback(async () => {
    await updateFerie(myvalue)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  }, [myvalue]);

  return (
    <div className={styles.line}>
      <div className={styles.name}>
        {modified ? (
          <Input
            value={myvalue.title}
            onChange={(e) => setMyValue({ ...myvalue, title: e.target.value })}
          />
        ) : (
          <p>{el.title}</p>
        )}
      </div>
      <div className={styles.monthDate}>
        {!modified && (
          <p>
            {el.day} / {el.month}
          </p>
        )}
        {modified && (
          <div className={styles.inputLine}>
            <Input
              value={myvalue.day}
              onChange={(e) => setMyValue({ ...myvalue, day: e.target.value })}
            />
            <span> / </span>
            <Input
              value={myvalue.month}
              onChange={(e) =>
                setMyValue({ ...myvalue, month: e.target.value })
              }
            />
          </div>
        )}
      </div>
      {modified && (
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => setModified(false)}
            text={<span style={{ fontSize: "1rem", padding: "0.7rem" }}>Annuler</span>}
            type="secondary"
            className={styles.btnStop}
          />
          <Button
            onClick={handleSubmit}
            text={<span style={{ fontSize: "1.5rem", padding: "0.4rem" }}>💾</span>}
            type="primary"
          />
        </div>
      )}
      {!modified && (
        <div className={styles.buttonContainer}>
          <Button
            className="buttonModify"
            onClick={() => setModified(true)}
            text={<span style={{ fontSize: "2rem" }} role="img" aria-label="poubelle">⚙️</span>}
            size={"small"}
          />
        </div>
      )}
    </div>
  );
}
