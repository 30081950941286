import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Account from "./pages/Account";
import SignIn from "./pages/SignIn";

import { globalContext } from "./store/globalContext";
import { useCallback, useEffect, useState } from "react";
import CRMPage from "./pages/CRM";
import ClientPage from "./pages/Clients";
import ProfilePage from "./pages/Profils";
import ObjetPage from "./pages/Objets";
import PrixPage from "./pages/Prix";
import PrestairePage from "./pages/Prestataire";
import BlogPage from "./pages/Blog";
import ContactPage from "./pages/Contact";
import LeadPage from "./pages/Leads";
import FerieEtSaison from "./pages/FerieEtSaison";
import Modification from "./pages/Modification";
import MajorationCodePostal from "./pages/MajorationCodePostal";

function App() {
  const [user, setUser] = useState();

  const handleSetUser = useCallback((e) => {
    setUser(e);
  }, []);

  const value = {
    userInformation: user,
    handleSetUser,
  };

  return (
    <globalContext.Provider value={value}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />

          <Route path="/dashboard/:id" element={<Account />} />

          <Route path="/crm/:id" element={<CRMPage />} />

          <Route path="/clients/:id" element={<ClientPage />} />

          <Route path="/leads/:id" element={<LeadPage />} />

          <Route path="/utilisateurs/:id" element={<ProfilePage />} />

          <Route path="/objets/:id" element={<ObjetPage />} />

          <Route path="/prix/:id" element={<PrixPage />} />

          <Route path="/ferie-et-saison/:id" element={<FerieEtSaison />} />

          <Route
            path="/majorations-cp/:id"
            element={<MajorationCodePostal />}
          />

          <Route path="/prestataire/:id" element={<PrestairePage />} />

          <Route path="/blog/:id" element={<BlogPage />} />

          <Route path="/contact/:id" element={<ContactPage />} />

          <Route
            path="/modification/:id/:devis/:unique"
            element={<Modification />}
          />
        </Routes>
      </BrowserRouter>
    </globalContext.Provider>
  );
}

export default App;
