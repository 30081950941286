import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getAllAdmin } from "../../apiCalls/admin";
import Navbar from "../../components/navbar";
import Profils from "../../section/Profils";
import { useGlobalContext } from "../../store/globalContext";

export default function ProfilePage() {
  const context = useGlobalContext();
  const [allAdmin, setAllAdmin] = useState([]);

  const handleCatchAdmin = useCallback(async () => {
    await getAllAdmin()
      .then((res) =>
        setAllAdmin(
          res.data.getAllAdmin.filter(
            (el) => el.id !== context?.userInformation?.id
          )
        )
      )
      .catch((err) => console.log(err));
  }, [context?.userInformation?.id]);

  useEffect(() => {
    setAllAdmin([]);
    handleCatchAdmin();
  }, [handleCatchAdmin]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={10} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Utilisateurs</h2>
        <Profils
          data={allAdmin.filter((el) => el.id !== context?.userInformation?.id)}
          refresh={handleCatchAdmin}
        />
      </div>
    </div>
  );
}
