import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../commons/Button";
import LineCodePostal from "../../components/LineCodePostal";
import Navbar from "../../components/navbar";
import { useGlobalContext } from "../../store/globalContext";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import Input from "../../commons/Input";
import styles from "./MajorationCodePostal.module.scss";
import {
  createMajorationZipCode,
  getMajorationZipCode,
} from "../../apiCalls/zipCode";

export default function MajorationCodePostal() {
  const context = useGlobalContext();
  const [onCreate, setOnCreate] = useState(false);
  const [newData, setNewData] = useState({
    majoration: 0,
    list: [],
  });
  const [datalocal, setDataLocal] = useState([]);

  const [bdZipCode, setBdZipCode] = useState([]);

  const catchZipCode = useCallback(async () => {
    await getMajorationZipCode().then((res) => {
      setBdZipCode(res.data.getAllZipCode);
    });
  }, []);

  useEffect(() => {
    catchZipCode();
  }, [catchZipCode]);

  const allDAta = useMemo(() => {
    if (datalocal.length === 1) {
      return bdZipCode.concat(datalocal);
    }
    return bdZipCode;
  }, [bdZipCode, datalocal]);

  const handleCreate = useCallback(() => {
    setOnCreate(true);
  }, []);

  const catchValueTagify = useCallback(
    (e) => {
      setNewData({ ...newData, list: e.detail.tagify.getCleanValue() });
    },
    [newData]
  );
  const handleCancel = useCallback(() => {
    setOnCreate(false);
    setNewData({
      majoration: 0,
      list: [],
    });
  }, []);

  const handleValidate = useCallback(async () => {
    const listValue = newData?.list.map((el) => el.value);
    const dataSend = {
      majoration: parseFloat(newData?.majoration),
      list: listValue,
    };
    await createMajorationZipCode({ value: dataSend }).then((res) => {
      window.location.reload();
    });
  }, [newData?.list, newData?.majoration]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={7} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Majorations codes postaux</h2>
        <div style={{ paddingBottom: 30 }}>
          <Button
            text={
              <span style={{ fontSize: "1rem", padding: "1.2rem" }}>
                ➕ Créer une nouvelle liste de code postaux majorés
              </span>
            }
            type="primary"
            onClick={handleCreate}
          />
        </div>
        {onCreate && (
          <div className={styles.lineCreate}>
            <div className={styles.tagContainer}>
              <Tags
                className={styles.inputTag}
                defaultValue=""
                autoFocus={false}
                onChange={catchValueTagify}
                settings={{
                  maxTags: 100,
                  required: true,
                  placeholder: "Ajouter les codes postaux ",

                  delimiters: ",| |:",
                  duplicates: false,
                }}
              />
            </div>
            <div className={styles.inputContainer}>
              <Input
                value={newData?.majoration}
                onChange={(e) =>
                  setNewData({ ...newData, majoration: e.target.value })
                }
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className="buttonModify"
                text={
                  <span style={{ fontSize: "1.5rem", padding: "0.7rem" }}>
                    ❌
                  </span>
                }
                onClick={handleCancel}
              />
              <Button
                text={
                  <span style={{ fontSize: "1.5rem", padding: "0.7rem" }}>
                    💾
                  </span>
                }
                type="primary"
                onClick={handleValidate}
              />
            </div>
          </div>
        )}
        {allDAta?.map((el, i) => (
          <LineCodePostal data={el} key={i} />
        ))}
      </div>
    </div>
  );
}
