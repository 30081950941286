import _ from "lodash";

export const roundNumber = (number, order) => {
  let res = 0;
  if (!order) {
    res = _.round(number, 2);
  } else {
    res = _.round(number, order);
  }
  return res;
};
