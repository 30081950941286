import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Button from "../../commons/Button";
import LineClient from "../../commons/LineClient";
import SearchBar from "../../commons/SearchBar/SearchBar";
import { UpdateStatusCommandeClient } from "../../apiCalls/clients";
import styles from "./crm.module.scss";
import Modal from "../../commons/Modal";
import { deleteDevis, UpdateStatusCommandeStatus } from "../../apiCalls/devis";
import { removeAccents } from "../../utils/AccentRemove";
export default function Crm({ data }) {
  const [clientCrm, setClientCrm] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const [devisChoose, setDevisChoose] = useState();
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setClientCrm({
      data: data,
      status: "all",
    });
  }, [data]);

  const handleChoose = useCallback(
    (type) => {
      if (type === "all") {
        setClientCrm({
          data: data,
          status: type,
        });
        return;
      }
      let element = [...data];
      const resultat = element.filter((el) => el.status === type);
      setClientCrm({ data: resultat, status: type });
    },
    [data]
  );

  const filterClient = useCallback(
    (e) => {
      if (e === "") {
        setClientCrm({ data: data, status: "all" });
        return;
      }
      const arrayOriginal = [...clientCrm.data];
      const resultat = arrayOriginal.filter(
        (el) =>
          removeAccents(el.client[0].nom)
            .toLowerCase()
            .indexOf(removeAccents(e)) > -1 ||
          el.client[0].email.toLowerCase().indexOf(e) > -1 ||
          el.client[0].telephone.toLowerCase().indexOf(e) > -1
      );
      setClientCrm({ data: resultat, status: "all" });
    },
    [clientCrm, data]
  );

  const handleFinalValidate = useCallback((client, idDevis, devisUnique) => {
    const clientData = client.formulaire_id?.map((el) => ({
      date: el.date,
      devis_id: el.devis_id,
      status: el.devis_id === devisUnique ? "VALIDATED" : el.status,
    }));
    UpdateStatusCommandeClient({
      id: parseInt(client.id),
      formulaire_id: clientData,
    })
      .then(() => {
        UpdateStatusCommandeStatus({
          id: parseInt(idDevis),
          status: "VALIDATED",
        }).then(() => window.location.reload());
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCreateLeads = useCallback((client, idDevis, devisUnique) => {
    const clientData = client.formulaire_id?.map((el) => ({
      date: el.date,
      devis_id: el.devis_id,
      status: el.devis_id === devisUnique ? "LEADS" : el.status,
    }));
    UpdateStatusCommandeClient({
      id: parseInt(client.id),
      formulaire_id: clientData,
    })
      .then((res) => {
        UpdateStatusCommandeStatus({
          id: parseInt(idDevis),
          status: "LEADS",
        }).then(() => window.location.reload());
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmitDelete = useCallback(async () => {
    const uuid = devisChoose?.unique_id;

    const infoUser = devisChoose?.client[0]?.formulaire_id?.map((el) => ({
      date: el.date,
      devis_id: el.devis_id,
      status: el.status,
    }));
    const formId = infoUser.filter((el) => el.devis_id !== uuid);

    UpdateStatusCommandeClient({
      id: parseInt(devisChoose?.client[0].id),
      formulaire_id: formId,
    }).then(() => {
      deleteDevis({ id: parseInt(devisChoose?.id) })
        .then(() => {
          setDevisChoose("");
          setModalDelete(false);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    });

    return;
  }, [devisChoose?.client, devisChoose?.id, devisChoose?.unique_id]);

  const handleModalDelete = useCallback((e) => {
    setDevisChoose(e);
    setModalDelete(true);
  }, []);

  const handleSeeMore = useCallback(() => {
    setModal(true);
  }, []);

  const handleOpenNote = useCallback((e) => {
    setModal(true);
  }, []);

  return (
    <div className={styles.container}>
      <div className="header">
        <div className={styles.searchBar}>
          <SearchBar onChange={filterClient} />
        </div>
        <div className={styles.buttonContainer}>
          {clientCrm?.status !== "all" && (
            <Button
              onClick={() => handleChoose("all")}
              size={"small"}
              type="Encour"
              text={"Tout"}
            />
          )}
          <Button
            onClick={() => handleChoose("EN COUR")}
            size={"small"}
            type="en_cour"
            text={"En Cours"}
          />
          <Button
            onClick={() => handleChoose("PAYED")}
            size={"small"}
            type="payer"
            text={"Payé"}
          />
        </div>
      </div>
      {clientCrm?.data?.map((el, i) => (
        <LineClient
          OpenNote={handleOpenNote}
          seeMore={() => handleSeeMore(el)}
          key={el.id}
          el={el}
        >
          <div className={styles.containerButton}>
            {el.status === "PAYED" ? (
              <Button
                onClick={() =>
                  handleFinalValidate(el.client[0], el.id, el.unique_id)
                }
                type="primary"
                size={"small"}
                text={
                  <>
                    C’est vendu{" "}
                    <span
                      style={{ fontSize: "1.5rem", marginLeft: "7px" }}
                      role="img"
                      aria-label="champagne"
                    >
                      🍾
                    </span>
                  </>
                }
              />
            ) : (
              <Button
                onClick={() =>
                  handleCreateLeads(el.client[0], el.id, el.unique_id)
                }
                type="primary"
                size={"small"}
                text={
                  <>
                    Pas intéressé{" "}
                    <span
                      style={{ fontSize: "1.4rem", marginLeft: "7px" }}
                      role="img"
                      aria-label="triste"
                    >
                      ☹️
                    </span>
                  </>
                }
              />
            )}{" "}
            <a
              className="buttonModify"
              href={`/modification/3/${el.id}/${el.unique_id}`}
              target="_blanck"
            >
              <span
                style={{ fontSize: "2rem" }}
                role="img"
                aria-label="engrenage"
              >
                ⚙️
              </span>
            </a>
            <Button
              onClick={() => handleModalDelete(el)}
              type={"delete"}
              size={"small"}
              text={
                <span
                  style={{ fontSize: "2rem" }}
                  role="img"
                  aria-label="poubelle"
                >
                  🗑️
                </span>
              }
            />
          </div>
        </LineClient>
      ))}
      {modal && <Modal handleClose={() => setModal(false)} />}
      {modalDelete && (
        <Modal handleClose={() => setModalDelete(false)}>
          <div className={styles.modalDelete}>
            <p className="orangeText">Devis n° {devisChoose.id}</p>
            <br></br>
            <p>Vous êtes sur le point de supprimer définitivement !</p>
            <br></br>
            <br></br>
            <Button
              onClick={() => setModalDelete(false)}
              className={styles.buttonannuler}
              type={"primary"}
              size={"small"}
              text={`Annuler`}
            />
            <br></br>
            <Button
              className={styles.buttonDelete}
              onClick={handleSubmitDelete}
              type={"delete"}
              size={"small"}
              text={`Supprimer`}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
