import titan from "../../AppoloClient/apollo-client";
import { gql } from "@apollo/client";

export const getPrice = async () => {
  const { data } = await titan.query({
    query: gql`
      query {
        getAllPrice {
          id
          label
          prix
        }
      }
    `,
  });
  return { data };
};

export const updatePrice = async ({ id, prix }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation Mutation($updatePriceId: ID, $prix: Float) {
        updatePrice(id: $updatePriceId, prix: $prix) {
          successful
          message
        }
      }
    `,
    variables: {
      updatePriceId: id,
      prix: prix,
    },
  });
  return { data, errors };
};
