import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDevisById } from "../../apiCalls/devis";
import { getAllObject } from "../../apiCalls/objets";
import { getPrice } from "../../apiCalls/prix";
import { modifiedContext } from "../../store/devisContext";
import ModificationPage from "../../section/Modification";
import { priceFromFront } from "../../utils/price";
import { useMemo } from "react";
import { getMajorationZipCode } from "../../apiCalls/zipCode";
import { getSaison } from "../../apiCalls/saison";

export default function Modification() {
  const { devis } = useParams();
  const [loading, setLoading] = useState(false);
  const [allPrice, setAllPrice] = useState();
  const [data, setData] = useState();
  const [orignalDevis, setOrignalDevis] = useState();
  const [priceDevis, setPriceDevis] = useState(0);
  const [allZipCode, setAllZipCode] = useState();
  const [allMeuble, setAllMeuble] = useState();
  const [allSaison, setAllSaison] = useState();
  const [step, setStep] = useState(1);
  const [upadateMajoration, setUpdateMajoration] = useState({
    date: 0,
    zip: 0,
    isModifiedZip: false,
    isModifiedDate: false,
    originalMajDate: 0,
    originalMajZip: 0,
  });
  const [difference, setDifference] = useState({
    distance: { modified: false, value: null },
    date: { modified: false, value: null },
    meuble: { add: [], remove: [] },
    demontage_remontage: { add: [], remove: [] },
    nb_cartons: { modified: false, value: null },
    deballage: { modified: false, value: null },
    emballage: { modified: false, value: null },
    assurrance: { modified: false, value: null },
    report: { modified: false, value: null },
    etage_depart: { modified: false, value: null, price: null },
    etage_arrive: { modified: false, value: null, price: null },
    distance_logement_depart: { modified: false, value: null, price: null },
    distance_logement_arrive: { modified: false, value: null, price: null },
    ascenceur_depart: { modified: false, value: null, price: null },
    ascenceur_arrive: { modified: false, value: null, price: null },
    cave_depart: { modified: false, value: null, price: null },
    cave_arrive: { modified: false, value: null, price: null },
    monte_meuble_depart: { modified: false, value: null, price: null },
    monte_meuble_arrive: { modified: false, value: null, price: null },
  });

  const handleCatchDevis = useCallback(async (id) => {
    await getDevisById({ id: parseInt(id) })
      .then((res) => {
        setData(res.data.getCommandeById[0]);
        setOrignalDevis(res.data.getCommandeById[0]);
        setPriceDevis(
          priceFromFront(
            res.data.getCommandeById[0].option_prix[0],
            res.data.getCommandeById[0].emb_deb_data?.cartonPredict,
            res.data.getCommandeById[0]
          )
        );

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const catchAllRegion = useCallback(async () => {
    await getMajorationZipCode().then((res) =>
      setAllZipCode(res.data.getAllZipCode)
    );
  }, []);

  const handlePrice = useCallback(async () => {
    await getPrice().then((res) => setAllPrice(res.data.getAllPrice));
  }, []);

  const catchAllMeuble = useCallback(async () => {
    await getAllObject().then((res) => setAllMeuble(res.data.getALLObject));
  }, []);

  const catchMajorationSaison = useCallback(async () => {
    await getSaison().then((res) => setAllSaison(res.data.getAllSaison));
  }, []);

  const priceAfterModification = useMemo(() => {
    if (data?.option_prix?.[0] && data?.emb_deb_data) {
      return priceFromFront(
        data?.option_prix?.[0],
        data?.emb_deb_data?.cartonPredict,
        orignalDevis
      );
    }
    return 0;
  }, [data?.option_prix, orignalDevis, data?.emb_deb_data]);

  const activeMajoration = useMemo(() => {
    if (step === 1) {
      return orignalDevis?.option_prix?.[0]?.majoration;
    }
    let majorationModified = orignalDevis?.option_prix?.[0]?.majoration;
    if (upadateMajoration?.isModifiedDate && upadateMajoration?.isModifiedZip) {
      majorationModified = upadateMajoration?.date + upadateMajoration?.zip + 1;
    }
    if (
      upadateMajoration?.isModifiedDate &&
      !upadateMajoration?.isModifiedZip
    ) {
      majorationModified =
        upadateMajoration?.date + upadateMajoration?.originalMajZip + 1;
    }
    if (
      !upadateMajoration?.isModifiedDate &&
      upadateMajoration?.isModifiedZip
    ) {
      majorationModified =
        upadateMajoration?.originalMajDate + upadateMajoration?.zip;
    }

    setData((data) => ({
      ...data,
      option_prix: [
        {
          ...data.option_prix[0],
          majoration: majorationModified,
        },
      ],
    }));
    return majorationModified.toFixed(2);
  }, [
    orignalDevis?.option_prix,
    step,
    upadateMajoration?.date,
    upadateMajoration?.isModifiedDate,
    upadateMajoration?.isModifiedZip,
    upadateMajoration?.originalMajDate,
    upadateMajoration?.originalMajZip,
    upadateMajoration?.zip,
  ]);

  useEffect(() => {
    setLoading(true);
    handleCatchDevis(devis);
    handlePrice();
    catchAllMeuble();
    catchAllRegion();
    catchMajorationSaison();
  }, [
    catchAllMeuble,
    catchAllRegion,
    catchMajorationSaison,
    devis,
    handleCatchDevis,
    handlePrice,
  ]);

  // console.log("LIVE activeMajoration", activeMajoration);
  // console.log("LIVE data send to bdd", data);
  // console.log("LIVE original", orignalDevis?.option_prix?.[0]);
  // console.log("LIVE difference", difference);
  // console.log("LIVE upadateMajoration", upadateMajoration);
  if (loading) {
    return;
  }

  return (
    <modifiedContext.Provider
      value={{
        setData,
        data,
        setDifference,
        difference,
        orignalDevis,
        setStep,
        step,
        upadateMajoration,
        setUpdateMajoration,
        activeMajoration,
      }}
    >
      <ModificationPage
        originPrice={priceDevis}
        data={data}
        allPrice={allPrice}
        allMeuble={allMeuble}
        allZipCode={allZipCode}
        newPrice={priceAfterModification}
        allSaison={allSaison}
      />
    </modifiedContext.Provider>
  );
}
