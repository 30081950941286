export const poidsModelEnum = {
  LESS80: {
    value: "- 80kg",
    enum: "LESS80",
  },
  MORE80: {
    value: "Entre 80 kg à 200kg",
    enum: "MORE80",
  },
};

export const arrayPoids = [
  {
    label: "- 80kg",
    value: "LESS80",
  },
  {
    label: "Entre 80 kg à 200kg",
    value: "MORE80",
  },
];
