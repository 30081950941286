import React, { useCallback, useContext, useEffect, useState } from "react";
import { modifiedContext } from "../../../store/devisContext";

import format from "date-fns/format";
import parse from "date-fns/parse";
import GeneralLine from "../InfoGeneral/generalLine";

export default function DateAndFlexible({ data, allSaison }) {
  const countext = useContext(modifiedContext);
  const { setUpdateMajoration, orignalDevis } = useContext(modifiedContext);
  const [saisonFormat, setFormatSaison] = useState([]);

  useEffect(() => {
    if (allSaison) {
      const data = allSaison.map((el) =>
        el.start.replaceAll("/", " ").split(" ")
      );
      const dataEnd = allSaison.map((el) =>
        el.end.replaceAll("/", " ").split(" ")
      );
      const final = data.map((el) => `${el[1]}-${el[0]}-${el[2]}`);
      const finalEnd = dataEnd.map((el) => `${el[1]}-${el[0]}-${el[2]}`);

      setFormatSaison({
        start: final.map((el) => new Date(el.replaceAll("-", "/"))),
        end: finalEnd.map((el) => new Date(el.replaceAll("-", "/"))),
        majoration: final.map((e, i) => allSaison[i].majorationRate),
        len: allSaison.length,
      });
    }
  }, [allSaison]);

  const formatingDate = useCallback((e) => {
    const toArray = e.replaceAll("-", " ").split(" ");
    const format = new Date(`${toArray[0]}/${toArray[1]}/${toArray[2]}`);
    return format;
  }, []);

  const verifcationSaison = useCallback(
    (e) => {
      const saisonAllArray = new Array(saisonFormat);
      const size = saisonAllArray[0]?.start.length;
      const format = formatingDate(e);
      let res = 1;
      for (let i = 0; i < size; i++) {
        if (
          format > saisonAllArray[0]?.start[i] &&
          format < saisonAllArray[0]?.end[i]
        ) {
          res = saisonAllArray[0].majoration[i];
        }
      }

      return res === 1 ? 1 : res / 100 + 1;
    },
    [formatingDate, saisonFormat]
  );

  useEffect(() => {
    if (saisonFormat?.start && saisonFormat.end) {
      setUpdateMajoration((updateMajoration) => ({
        ...updateMajoration,
        originalMajDate: verifcationSaison(orignalDevis?.date_demenagement),
      }));
    }
  }, [
    orignalDevis?.date_demenagement,
    saisonFormat,
    setUpdateMajoration,
    verifcationSaison,
  ]);

  const handleUpdateDate = useCallback(
    (e, isFLexible = false) => {
      countext.setData((data) => ({
        ...data,
        date_demenagement: e,
        flexible: {
          debut: format(new Date(e), "dd-MM-yyyy"),
          fin: data?.flexible?.fin,
        },
      }));

      setUpdateMajoration((updateMajoration) => ({
        ...updateMajoration,
        isModified: true,
        isModifiedDate: true,
        date: parseFloat(
          verifcationSaison(e) < 1
            ? 1 - verifcationSaison(e)
            : verifcationSaison(e) - 1
        ),
      }));
      countext.setDifference((difference) => ({
        ...difference,
        date: { modifiedContext: true, value: e },
        majoration: { modifiedContext: true, value: verifcationSaison(e) },
      }));
    },
    [countext, setUpdateMajoration, verifcationSaison]
  );

  const handleUpdateFlexible = useCallback(
    (e) => {
      countext?.setData((data) => ({
        ...data,
        flexible: e,
      }));
      const parsedDate = parse(e?.debut, "dd-MM-yyyy", new Date());
      const dateFormatee = format(parsedDate, "yyyy-MM-dd");
      handleUpdateDate(dateFormatee, true);
    },
    [countext, handleUpdateDate]
  );

  return (
    <div>
      <GeneralLine
        handleUpdate={handleUpdateDate}
        label={<span style={{ color: "black" }}>Date du démanagement</span>}
        value={data?.date_demenagement}
        type={"Date"}
      />
      <GeneralLine
        handleUpdate={handleUpdateFlexible}
        label={<span style={{ color: "black" }}>Flexible</span>}
        type={"Flexible"}
        value={data?.flexible}
      />
    </div>
  );
}
