export const printUniqueObjectPerPiecelisting = (products) => {
  const seen = new Set();
  const seenTwo = new Set();

  let data = products;

  const filteredArr = data.filter((el) => {
    const duplicate = seen.has(el.id) && seenTwo.has(el.piece);
    seen.add(el.id);
    seenTwo.add(el.piece);

    return !duplicate;
  });
  return filteredArr;
};
