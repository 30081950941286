export const totalQuantity = (id, products) => {
  let data = products;
  const test = data.filter((e) => e.id === id);
  return test.length > 1 ? test.length : 1;
};

export const printUniqueObject = (products) => {
  let data = products;
  const ids = data?.map((o) => o.id);
  const filtered = data?.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  return filtered;
};

export const printCorrectActualQuantity = (id, array) => {
  let data = array.filter((el) => el.id === id);
  return data[0]?.value ?? 0;
};

export const totalQuantity2 = (id, products) => {
  let data = products;
  const test = data.filter((e) => e === id);
  return test.length > 1 ? test.length : 1;
};

export const createObjectDuplicateFormat = (array) => {
  const ids = array.map((el) => el);
  const duplicate = array.map((el) => ({
    id: el.toString(),
    value: totalQuantity2(el, ids),
  }));
  const data = printUniqueObject(duplicate);
  return data;
};

export const printCorrectQuantityIsSelect = (array, id) => {
  let n = 0;

  for (let i = 0; i < array.length; i++) {
    if (array[i] === id) {
      n++;
    }
  }

  return n;
};

export const printUniqueObjectPerPiece = (products) => {
  let data = products;
  const ids = data?.map((o) => ({ id: o.id, piece: o.piece }));
  console.log(ids);
  const filtered = data?.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  return filtered;
};
