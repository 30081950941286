import React, { useCallback } from "react";
import styles from "./Modify.module.scss";
import { useState } from "react";
import Button from "../../../commons/Button";
import SwitchButton from "../../../commons/SwitchButton";
import { format } from "date-fns";
import RichText from "../../../components/WYSING";
import { deleteBlog, updateBlog } from "../../../apiCalls/blog";
export default function ModifyArticle({ data, handleBack }) {
  const [value, setValue] = useState({
    title: data?.title,
    text: data?.text,
    visible: data?.visible,
    date: format(new Date(), "dd/MM/yyyy"),
    image_principal: data?.image_principal,
    metaDescription: data?.meta_description,
    metaTitle: data?.meta_title,
  });

  const handleDelete = useCallback(async () => {
    await deleteBlog({ id: parseInt(data?.id) }).then(() => handleBack());
  }, [data?.id, handleBack]);

  const handleCatchWords = useCallback(
    (e, delta, source, editor) => {
      setValue({ ...value, text: e });
    },
    [value]
  );

  const handleSubmit = useCallback(async () => {
    if (value.title === "" || value.text === "" || value.visible === null) {
      return;
    }
    await updateBlog({
      id: parseInt(data?.id),
      title: value.title,
      text: value.text,
      visible: value.visible,
      date: value.date,
      slug: value.title.toLowerCase().replaceAll(" ", "-"),
      image_principal: value.image_principal,
      metaDescription: value?.metaDescription,
      metaTitle: value?.metaTitle,
    })
      .then((res) => window.location.reload())
      .catch((err) => console.log(err));
  }, [
    data?.id,
    value.date,
    value.image_principal,
    value?.metaDescription,
    value?.metaTitle,
    value.text,
    value.title,
    value.visible,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.richTextEditor}>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Titre de l'article"
            value={value.title}
            onChange={(e) => setValue({ ...value, title: e.target.value })}
          />
        </div>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Meta Title"
            value={value.metaTitle}
            onChange={(e) => setValue({ ...value, metaTitle: e.target.value })}
          />
        </div>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Meta description"
            value={value.metaDescription}
            onChange={(e) =>
              setValue({ ...value, metaDescription: e.target.value })
            }
          />
        </div>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Titre de l'article"
            value={value.image_principal}
            onChange={(e) =>
              setValue({ ...value, image_principal: e.target.value })
            }
          />
        </div>
        <RichText onChange={handleCatchWords} value={value.text} />
      </div>
      <div className={styles.switchContainer}>
        <p className={styles.label}>Visible : </p>
        <SwitchButton
          data={value.visible}
          onChange={(e) => setValue({ ...value, visible: e })}
        />
      </div>
      <div className={styles.containerBtn}>
        <div className={styles.button}>
          <Button
            text={<span style={{ fontSize: "2rem", padding: "0.3rem" }}>💾</span>}
            type="primary"
            onClick={handleSubmit}
            className={styles.validateBtn}
          />
        </div>
        <div className={styles.button}>
          <Button
            text={<span style={{ fontSize: "2rem", padding: "0.3rem" }}>🗑️</span>}
            type="delete"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
