import React, { useState } from "react";
import { useCallback } from "react";
import { CreatePrestataire } from "../../apiCalls/prestataires";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import styles from "./CreatePresta.module.scss";
export default function CreatePresta({ handleBack }) {
  const [create, setCreate] = useState({
    effectif: "",
    monte_meuble: "",
    objet_lourd: "",
    vehicule: "",
    statut: "",
    region: "",
    password: "",
    nom_dirigeant: "",
    siret: "",
    email: "",
    telephone: "",
    ville: "",
    raison_social: "",
    email_connexion: "",
  });

  const [alert, setAlert] = useState("");
  const handleSubmit = useCallback(() => {
    setAlert("");
    if (
      create.effectif === "" ||
      create.monte_meuble === "" ||
      create.objet_lourd === "" ||
      create.vehicule === "" ||
      create.statut === "" ||
      create.region === "" ||
      create.password === "" ||
      create.nom_dirigeant === "" ||
      create.siret === "" ||
      create.email === "" ||
      create.telephone === "" ||
      create.ville === "" ||
      create.raison_social === "" ||
      create.email_connexion === ""
    ) {
      setAlert("Merci de renseigner tous les champs");
      return;
    }
    CreatePrestataire(create)
      .then(() => {
        setCreate({
          effectif: "",
          monte_meuble: "",
          objet_lourd: "",
          vehicule: "",
          statut: "",
          region: "",
          password: "",
          nom_dirigeant: "",
          siret: "",
          email: "",
          telephone: "",
          ville: "",
          raison_social: "",
          email_connexion: "",
        });
        window.location.reload();
      })
      .catch((err) => console.log(err));
    return;
  }, [create]);

  return (
    <div className={styles.container}>
      <Button
        type={"primary"}
        text="Retour"
        onClick={handleBack}
        size="small"
      />
      <div className={styles.containerForm}>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Société :"}
            value={create.raison_social}
            onChange={(e) =>
              setCreate({ ...create, raison_social: e.target.value })
            }
          />
          <Input
            placeholder={"Statut :"}
            value={create.statut}
            onChange={(e) => setCreate({ ...create, statut: e.target.value })}
          />
        </div>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Téléphone :"}
            value={create.telephone}
            onChange={(e) =>
              setCreate({ ...create, telephone: e.target.value })
            }
          />
          <Input
            placeholder={"Ville :"}
            value={create.ville}
            onChange={(e) => setCreate({ ...create, ville: e.target.value })}
          />
        </div>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Email :"}
            value={create.email}
            onChange={(e) => setCreate({ ...create, email: e.target.value })}
          />
          <Input
            placeholder={"Région :"}
            value={create.region}
            onChange={(e) => setCreate({ ...create, region: e.target.value })}
          />
        </div>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Email connexion :"}
            value={create.email_connexion}
            onChange={(e) =>
              setCreate({ ...create, email_connexion: e.target.value })
            }
          />
          <Input
            placeholder={"Mot de passe :"}
            value={create.password}
            onChange={(e) => setCreate({ ...create, password: e.target.value })}
          />
        </div>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Siret :"}
            value={create.siret}
            onChange={(e) => setCreate({ ...create, siret: e.target.value })}
          />
          <Input
            placeholder={"Contact :"}
            value={create.nom_dirigeant}
            onChange={(e) =>
              setCreate({ ...create, nom_dirigeant: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.containerMiseADispo}>
        <h2>Mise à disposition</h2>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Vehicules :"}
            value={create.vehicule}
            onChange={(e) => setCreate({ ...create, vehicule: e.target.value })}
          />
          <Input
            placeholder={"Objets Lourd :"}
            value={create.objet_lourd}
            onChange={(e) =>
              setCreate({ ...create, objet_lourd: e.target.value })
            }
          />
        </div>
        <div className={styles.formInLine}>
          <Input
            placeholder={"Monte-meubles :"}
            value={create.monte_meuble}
            onChange={(e) =>
              setCreate({ ...create, monte_meuble: e.target.value })
            }
          />
          <Input
            placeholder={"Effectifs :"}
            value={create.effectif}
            onChange={(e) => setCreate({ ...create, effectif: e.target.value })}
          />
        </div>
        <div className={styles.buttoncontainer}>
          {alert !== "" && <p>{alert}</p>}
          <Button
            type={"primary"}
            size="small"
            text={"+ Ajouter"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
