import React, { useCallback, useEffect, useState } from "react";
import { createUtiliseur, deleteAdmin } from "../../apiCalls/admin";
import Button from "../../commons/Button";
import Input from "../../commons/Input";
import LineAdmin from "../../commons/LineAdmin";
import Select from "../../commons/Select";
import { fonctions } from "../../ENUMS/enums-fonction";
import { useGlobalContext } from "../../store/globalContext";
import { regionDepartment } from "../../utils/Region";
import styles from "./Profils.module.scss";

export default function Profils({ data, refresh }) {
  const { userInformation } = useGlobalContext();
  const [alert, setAlert] = useState();
  const [allAdmin, setAllAdmin] = useState([]);
  const [creationOpen, setCreationOpen] = useState(false);
  const [value, setValue] = useState({
    nom: "",
    email: "",
    fonction: {
      label: "",
      value: "",
    },
    region: {
      label: "",
      value: "",
      data: [],
    },
    telephone: "",
    email_connexion: "",
    password: "",
  });

  useEffect(() => {
    setAllAdmin(data);
  }, [data]);

  const handleOpenNewProfils = useCallback(() => {
    setCreationOpen(!creationOpen);
  }, [creationOpen]);

  const handleChangeFonction = useCallback(
    (e) => {
      setValue({
        ...value,
        fonction: {
          label: fonctions.filter((el) => el.value === e).label,
          value: e,
        },
      });
    },
    [value]
  );

  const handleChangeRegion = useCallback(
    (e) => {
      const region = regionDepartment.filter((el) => el.value === e)[0].data;

      setValue({
        ...value,
        region: {
          label: e,
          value: e,
          data: region,
        },
      });
    },
    [value]
  );

  const handleSubmit = useCallback(() => {
    if (
      !value.nom ||
      !value.email ||
      !value.email_connexion ||
      !value.password ||
      !value.telephone ||
      !value.fonction.value
    ) {
      return;
    }
    const dataSend = {
      utilisateur: value.nom,
      email: value.email,
      password: value.password,
      telephone: value.telephone,
      service: value.fonction.value,
      region: value.region.data,
      email_connexion: value.email_connexion,
      region_name: value.region.label,
    };
    createUtiliseur(dataSend)
      .then(() => {
        refresh();
        setCreationOpen(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [
    refresh,
    value.email,
    value.email_connexion,
    value.fonction.value,
    value.nom,
    value.password,
    value.region.data,
    value.region.label,
    value.telephone,
  ]);

  const handleDelete = useCallback(
    async (id) => {
      await deleteAdmin({ id: id })
        .then((res) => {
          setAlert("Utilisateur supprimé avec succès");
          setAllAdmin(
            res.data.deleteAdmin.filter((el) => el.id !== userInformation.id)
          );
          window.location.reload();
        })
        .catch((err) => console.log(err));
    },
    [userInformation?.id]
  );

  if (creationOpen) {
    return (
      <div className={styles.container}>
        <div className={styles.headerButton}>
          <Button
            onClick={handleOpenNewProfils}
            type="secondary"
            text={
              <span style={{ fontSize: "1.2rem", padding: "0.6rem" }}>
                ✖️ Annuler
              </span>
            }
          />
        </div>
        <div className={styles.formulaire}>
          <div className="lineInput">
            <div className={styles.containerButton}>
              <Input
                placeholder={"Nom :"}
                type="name"
                value={value.nom}
                onChange={(e) => setValue({ ...value, nom: e.target.value })}
              />
            </div>
            <div className={styles.containerButton}>
              <Input
                placeholder={"Email : "}
                type="email"
                value={value.email}
                onChange={(e) => setValue({ ...value, email: e.target.value })}
              />
            </div>
          </div>
          <div className="lineInput">
            <div className={styles.containerButton}>
              <Select
                value={value.fonction.value}
                placeHolder={"Fonction"}
                data={fonctions}
                onChange={handleChangeFonction}
              />
            </div>
            <div className={styles.containerButton}>
              <Input
                placeholder={"Téléphone :"}
                type="phone"
                value={value.telephone}
                onChange={(e) =>
                  setValue({ ...value, telephone: e.target.value })
                }
              />
            </div>
          </div>
          {value.fonction.value === "COMMERCIAL" && (
            <div className={styles.containerButton}>
              <Select
                value={value.region.value}
                placeHolder={"Région"}
                data={regionDepartment}
                onChange={handleChangeRegion}
              />
            </div>
          )}
          <div className="lineInput">
            <div className={styles.containerButton}>
              <Input
                placeholder={"Email connection :"}
                type="email"
                value={value.email_connexion}
                onChange={(e) =>
                  setValue({ ...value, email_connexion: e.target.value })
                }
              />
            </div>
            <div className={styles.containerButton}>
              <Input
                placeholder={"Mot de passe :"}
                type="password"
                value={value.password}
                onChange={(e) =>
                  setValue({ ...value, password: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          type={"primary"}
          text={
            <span style={{ fontSize: "1.2rem", padding: "0.6rem" }}>
              ➕ Ajouter
            </span>
          }
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerButton}>
        <Button
          onClick={handleOpenNewProfils}
          type="primary"
          text={
            <span style={{ fontSize: "1.2rem", padding: "1rem" }}>
              ➕ Créer un nouveau profils
            </span>
          }
        />
      </div>
      {alert && <p>{alert}</p>}
      {allAdmin?.map((el) => (
        <LineAdmin key={el.id} el={el} onDelete={handleDelete} />
      ))}
    </div>
  );
}
