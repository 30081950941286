import { gql } from "@apollo/client";

import titan from "../../AppoloClient/apollo-client";

export const getToken = async ({ token }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query VerifiedToken {
        verifiedToken {
          value
          message
          successful
        }
      }
    `,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
  return { data, errors };
};
