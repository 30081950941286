import { getToken } from "../../apiCalls/token";
import { deleteCookie, getCookie } from "../cookies";

export const verifToken = async () => {
  const token = getCookie("titan_admin_user");
  if (token) {
    const res = await getToken({
      token: token,
    });
    if (res.data.verifiedToken.successful) {
      return {
        isValid: true,
        id: res.data.verifiedToken.value,
      };
    }
    deleteCookie("titan_admin_user");
    return {
      isValid: false,
      id: null,
    };
  }
  return true;
};
