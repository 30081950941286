import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const deleteDevis = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteCommande($deleteCommandeId: ID) {
        deleteCommande(id: $deleteCommandeId) {
          successful
          message
        }
      }
    `,
    variables: {
      deleteCommandeId: id,
    },
  });
  return { data, errors };
};

export const getTotalDevis = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetCommandeAdmin {
        getCommandeAdmin {
          id
          date_commande
          status
          prestataire_id
        }
      }
    `,
  });
  return { data, errors };
};

export const getAllDevis = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetCommandeAdmin {
        getCommandeAdmin {
          id
          unique_id
          client_id
          nom
          telephone
          transaction_stripe
          prestataire_id
          listing {
            id
            nom
            piece
            sous_categorie
            hauteur
            largeur
            profondeur
            poids
            prix_m3
            supplemet
            visuel
            demontable
            from_user
            selectPiece
            prix_montage
            visible
          }
          status
          is_payed
          date_commande
          date_demenagement
          depart {
            rue
            code
            ville
          }
          arrive {
            rue
            code
            ville
          }
          option_prix {
            frais_modification
            meuble
            montage_demontage
            emballage
            deballage
            assurance
            bigWeight
            report
            km
            depart {
              monte_meuble
              cave
              etage
              distance_logement
            }
            arrive {
              monte_meuble
              cave
              etage
              distance_logement
            }
          }
          total_km
          surface
          demontage
          emballage
          deballage
          assurance
          report
          step
          flexible {
            fin
            debut
          }
          price
          type
          surfaceFinal
          etages
          ascenseur
          cave
          monte_meuble
          commentaires
          volume
          distance_rue
          cartons
          date_modifier
          client {
            id
            nom
            email
            telephone
            devis_id
            facture_id
            transaction_id
            type_of_payment
            step
            first_connexion
            note {
              titre
              message
              date
              auteur
            }
            status
            contrat_id
            surface
            date_creation
            formulaire_id {
              devis_id
              date
              status
            }
          }
          date_emission_devis
          numero_devis
          emb_deb_data {
            maxCarton
            prixDeballage
            prixEmballage
            prixCarton
            totalCarton
            volume
            livraison
            cartonSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
            accessoireSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const UpdateStatusCommandeStatus = async ({ id, status }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation AdminSetStatusCommande(
        $adminSetStatusCommandeId: ID
        $status: String
      ) {
        adminSetStatusCommande(id: $adminSetStatusCommandeId, status: $status) {
          successful
          message
        }
      }
    `,
    variables: {
      adminSetStatusCommandeId: id,
      status: status,
    },
  });
  return { data, errors };
};

export const deleteCommande = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteCommande($deleteCommandeId: ID) {
        deleteCommande(id: $deleteCommandeId) {
          successful
          message
        }
      }
    `,
    variables: {
      deleteCommandeId: id,
    },
  });
  return { data, errors };
};

export const getTotalCa = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetTotalCa {
        getTotalCa {
          id
          date_commande
          ca {
            frais_modification
            majoration
            meuble
            montage_demontage
            emballage
            deballage
            assurance
            report
            bigWeight
            livraison
            carton
            km
            depart {
              monte_meuble
              cave
              etage
              distance_logement
            }
            arrive {
              monte_meuble
              cave
              etage
              distance_logement
            }
            carton
            frais_dossier
          }
          emb {
            maxCarton
            prixDeballage
            prixEmballage
            prixCarton
            totalCarton
            volume
            livraison
            cartonPredict
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const getDevisById = async ({ id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetCommandeById($getCommandeByIdId: ID) {
        getCommandeById(id: $getCommandeByIdId) {
          id
          client_id
          transaction_stripe
          prestataire_id
          type
          step
          frais_modification
          listing {
            id
            nom
            piece
            sous_categorie
            hauteur
            largeur
            profondeur
            poids
            prix_m3
            supplemet
            visuel
            demontable
            from_user
            selectPiece
            prix_montage
            visible
          }
          status
          date_commande
          date_demenagement
          depart {
            rue
            code
            ville
          }
          arrive {
            rue
            code
            ville
          }
          total_km
          surface
          demontage
          emballage
          deballage
          assurance
          report
          flexible {
            fin
            debut
          }
          price
          surfaceFinal
          etages
          ascenseur
          cave
          monte_meuble
          commentaires
          volume
          distance_rue
          cartons
          numero_devis
          emb_deb_data {
            maxCarton

            prixCarton
            totalCarton
            volume
            cartonPredict
            livraison
            cartonSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
            accessoireSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
          }
          know_carton
          nom
          telephone
          new_date_demenagement
          extra_weight
          date_emission_devis
          date_modifier
          unique_id
          option_prix {
            frais_dossier
            frais_modification
            majoration
            meuble
            cartonPredict
            montage_demontage
            emballage
            deballage
            assurance
            carton
            livraison
            bigWeight
            report
            km
            depart {
              monte_meuble
              cave
              etage
              distance_logement
            }
            arrive {
              monte_meuble
              cave
              etage
              distance_logement
            }
          }
        }
      }
    `,
    variables: {
      getCommandeByIdId: id,
    },
  });
  return { data, errors };
};

export const updateAdressDevis = async ({
  id,
  depart,
  arrive,
  totalKm,
  optionPrix,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateCommande(
        $depart: AdresseTypeInput
        $arrive: AdresseTypeInput
        $totalKm: Float
        $uniqueId: String
        $optionPrix: [OptionsPriceInput]
      ) {
        updateCommande(
          depart: $depart
          arrive: $arrive
          total_km: $totalKm
          unique_id: $uniqueId
          option_prix: $optionPrix
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      depart: depart,
      optionPrix: optionPrix,
      arrive: arrive,
      totalKm: totalKm,
      uniqueId: id,
    },
  });
  return { data, errors };
};

export const getDevisForMail = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetCommandeAdmin {
        getCommandeAdmin {
          nom
          status
          date_demenagement
          client {
            email
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const updateAssuranceDevis = async ({ id, assurance, optionPrix }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateCommande(
        $assurance: Int
        $uniqueId: String
        $optionPrix: [OptionsPriceInput]
      ) {
        updateCommande(
          assurance: $assurance
          unique_id: $uniqueId
          option_prix: $optionPrix
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      assurance: assurance,
      uniqueId: id,
      optionPrix: optionPrix,
    },
  });
  return { data, errors };
};

export const updateReportDevis = async ({ id, report, optionPrix }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateCommande(
        $optionPrix: [OptionsPriceInput]
        $report: Boolean
        $uniqueId: String
      ) {
        updateCommande(
          option_prix: $optionPrix
          report: $report
          unique_id: $uniqueId
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      report: report,
      uniqueId: id,
      optionPrix: optionPrix,
    },
  });
  return { data, errors };
};

export const updateDevisGlobal = async (mydata) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation AdminUpdate(
        $adminUpdateId: ID
        $surface: Int
        $depart: AdresseTypeInput
        $arrive: AdresseTypeInput
        $step: Int
        $flexible: FlexibleTypeInput
        $totalKm: Float
        $type: [Int]
        $surfaceFinal: [Int]
        $etages: [Int]
        $cave: [Boolean]
        $ascenseur: [Boolean]
        $monteMeuble: [String]
        $commentaires: [String]
        $volume: Float
        $distanceRue: [Int]
        $optionPrix: [OptionsPriceInput]
        $listing: [ObjectsTypeInput]
        $uniqueId: String
        $embDebData: EmbDebInput
        $deballage: Boolean
        $demontage: [Int]
        $emballage: Boolean
        $numeroDevis: String
        $report: Boolean
        $knowCarton: Boolean
        $extraWeight: Boolean
        $nom: String
        $telephone: String
        $dateDemenagement: String
        $newDateDemenagement: String
        $dateModifier: Boolean
        $dateEmissionDevis: String
        $prestataireId: Int
        $assurance: Int
        $fraisModification: Int
        $printPriceModified: Float
        $adminPriceModified: AdminPriceModifiedInput
      ) {
        adminUpdate(
          id: $adminUpdateId
          surface: $surface
          depart: $depart
          arrive: $arrive
          step: $step
          flexible: $flexible
          total_km: $totalKm
          type: $type
          surfaceFinal: $surfaceFinal
          etages: $etages
          cave: $cave
          ascenseur: $ascenseur
          monte_meuble: $monteMeuble
          commentaires: $commentaires
          volume: $volume
          distance_rue: $distanceRue
          option_prix: $optionPrix
          listing: $listing
          unique_id: $uniqueId
          emb_deb_data: $embDebData
          deballage: $deballage
          demontage: $demontage
          emballage: $emballage
          numero_devis: $numeroDevis
          report: $report
          know_carton: $knowCarton
          extra_weight: $extraWeight
          nom: $nom
          telephone: $telephone
          date_demenagement: $dateDemenagement
          new_date_demenagement: $newDateDemenagement
          date_modifier: $dateModifier
          date_emission_devis: $dateEmissionDevis
          prestataire_id: $prestataireId
          assurance: $assurance
          frais_modification: $fraisModification
          print_price_modified: $printPriceModified
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      adminUpdateId: mydata.unique_id,
      surface: mydata.surface,
      depart: mydata.depart,
      arrive: mydata.arrive,
      flexible: mydata.flexible,
      totalKm: mydata.total_km,
      type: mydata.type,
      surfaceFinal: mydata.surfaceFinal,
      etages: mydata.etages,
      ascenseur: mydata.ascenseur,
      cave: mydata.cave,
      monteMeuble: mydata.monte_meuble,
      commentaires: mydata.commentaires,
      volume: mydata.volume,
      distanceRue: mydata.distance_rue,
      optionPrix: mydata.option_prix,
      listing: mydata.listing,
      uniqueId: mydata.unique_id,
      embDebData: mydata.emb_deb_data,
      deballage: mydata.deballage,
      demontage: mydata.demontage,
      emballage: mydata.emballage,
      extraWeight: mydata.extra_weight,
      knowCarton: mydata.know_carton,
      numeroDevis: mydata.numero_devis,
      report: mydata.report,
      nom: mydata.nom,
      telephone: mydata.telephone,
      dateDemenagement: mydata.date_demenagement,
      newDateDemenagement: mydata.new_date_demenagement,
      dateModifier: mydata.date_modifier,
      dateEmissionDevis: mydata.date_emission_devis,
      prestataireId: mydata.prestataire_id,
      assurance: mydata.assurance,
      fraisModification: mydata.frais_modification,
      printPriceModified: mydata.print_price_modified,
    },
  });
  return { data, errors };
};
