import React, { useState } from "react";
import { useCallback } from "react";
import Modal from "../../commons/Modal";
import Button from "../../commons/Button";
import styles from "./DescriptionPresta.module.scss";
import { useMemo } from "react";
import { useGlobalContext } from "../../store/globalContext";

import { deletePrestataire } from "../../apiCalls/prestataires";
import AllChantier from "./allChantier";
import Info from "./Info";
export default function DescriptionPresta({ handleBack, data }) {
  const { userInformation } = useGlobalContext();
  const [modal, setModal] = useState(false);

  const [type, setType] = useState();
  const [printing, setPrinting] = useState("info");
  const handleRemovePresta = useCallback(() => {
    setType("DELETE");
    setModal(true);
  }, []);

  const handleDeletePrestataire = useCallback(async () => {
    await deletePrestataire({ id: parseInt(data?.id) })
      .then((res) => {
        handleBack();
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [data?.id, handleBack]);

  const printCorrectModalChild = useMemo(() => {
    switch (type) {
      case "DELETE":
        return (
          <div className={styles.containerAddNote}>
            <h3>Supprimer {data?.raison_social}</h3>
            <div className={styles.buttonNoteCOntainer}>
              <Button
                type="primary"
                text={"Supprimer"}
                size="small"
                onClick={handleDeletePrestataire}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }, [data?.raison_social, handleDeletePrestataire, type]);

  const printElement = useMemo(() => {
    switch (printing) {
      case "info":
        return (
          <Info
            data={data}
            user={userInformation.utilisateur}
            handleRemovePresta={handleRemovePresta}
          />
        );
      case "all":
        return <AllChantier prestataire={data} type="all" id={data.id} />;
      default:
        break;
    }
  }, [data, handleRemovePresta, printing, userInformation.utilisateur]);

  const printCorrectCa = useCallback(() => {
    if (data?.ca_total) {
      return parseFloat(data?.ca_total?.toFixed(2));
    }
    return 0;
  }, [data?.ca_total]);

  return (
    <div className={styles.container}>
      <Button
        onClick={printing === "info" ? handleBack : () => setPrinting("info")}
        text="🔙 Retour"
        size="small"
        type="primary"
      />
      <h2 className={styles.title}>{data?.raison_social}</h2>
      <div className={styles.buttonContainerHeader}>
        {printing === "info" && (
          <>
            <Button
              text={"🟢 Tous les chantiers"}
              type="primary"
              size={"small"}
              onClick={() => setPrinting("all")}
            />

            <div className={styles.CAContainer}>
              <p>CA HT TOTAL : {printCorrectCa()} €</p>
            </div>
          </>
        )}
        {printing !== "info" && <h3>Tous les chantiers</h3>}
      </div>
      {printElement}
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          {printCorrectModalChild}
        </Modal>
      )}
    </div>
  );
}
