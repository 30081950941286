import React, { useCallback, useContext, useMemo, useState } from "react";
import Modal from "../../../commons/Modal";
import Input from "../../../commons/Input";
import Button from "../../../commons/Button";
import styles from "./VerificationModal.module.scss";
import { modifiedContext } from "../../../store/devisContext";
import format from "date-fns/format";
import Loader from "../../../commons/Loader";
import { totalQuantity2 } from "../../../utils/duplicate";
import { roundNumber } from "../../../utils/roundNumber/roundNumber";

const distances = [
  {
    label: "0 à 30 m",
    value: 0,
  },
  {
    label: "30 à 50 m",
    value: 1,
  },
  {
    label: "50 à 100 m",
    value: 2,
  },
];
export default function VerificationModal({
  handleValidateDiff,
  handleClose,
  allMeuble,
}) {
  const { difference, orignalDevis, setData, data } =
    useContext(modifiedContext);
  const [retenuePrice, setRetenuePrice] = useState();
  const [verification, setVerification] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRetenue = useCallback(
    (e) => {
      const value = parseFloat(e.target.value);
      setRetenuePrice(e.target.value);
      setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data?.option_prix[0],
            frais_modification: value,
          },
        ],
      }));
    },
    [setData]
  );

  const orignalDevisMix = useMemo(() => {
    let value = [];
    for (const objet of allMeuble) {
      if (orignalDevis.demontage.includes(parseInt(objet.id))) {
        value.push({
          nom: objet.nom,
          prix_montage: objet.prix_montage,
          quantity: totalQuantity2(parseInt(objet.id), orignalDevis?.demontage),
        });
      }
    }
    return value;
  }, [allMeuble, orignalDevis.demontage]);

  return (
    <Modal freeHeigth={true} handleClose={handleClose}>
      {loading && <Loader full />}
      <div className={styles.mainContainer}>
        <div className={styles.containerPrincipal}>
          <h3 className={styles.titleRecap}>Recap modification</h3>
          <div>
            <div className={styles.generalInformationContainer}>
              <div className={styles.separatorArray}>
                <p>Information general</p>
              </div>
              <table className={styles.tableau}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Original</th>
                    <th>Modifier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Distance</td>
                    <td>
                      {orignalDevis?.total_km} km{" "}
                      <span className={styles.price}>
                        ({orignalDevis?.option_prix?.[0]?.km} €)
                      </span>
                    </td>
                    <td>
                      {difference?.distance?.value && (
                        <>
                          {difference?.distance?.value}km{" "}
                          <span className={styles.price}>
                            ({roundNumber(data?.option_prix?.[0]?.km)} €)
                          </span>
                        </>
                      )}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>
                      {format(
                        new Date(orignalDevis?.date_demenagement),
                        "dd/MM/yyyy"
                      )}
                    </td>
                    <td>
                      {difference?.date?.value && (
                        <>
                          {format(
                            new Date(difference?.date?.value),
                            "dd/MM/yyyy"
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>NB Cartons</td>
                    <td>
                      {orignalDevis?.emb_deb_data?.maxCarton}
                      <span className={styles.price}>
                        {" "}
                        (
                        {roundNumber(
                          orignalDevis?.emb_deb_data?.cartonPredict *
                            orignalDevis?.option_prix?.[0]?.majoration
                        )}{" "}
                        €)
                      </span>
                    </td>
                    <td>
                      {difference?.nb_cartons?.value}{" "}
                      <span className={styles.price}>
                        {difference?.nb_cartons?.price &&
                          `(${roundNumber(
                            difference?.nb_cartons?.price ?? 0
                          )} €)`}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Emballage</td>
                    <td>
                      {orignalDevis?.option_prix?.[0]?.emballage === 0
                        ? "❌"
                        : "✅"}{" "}
                      <span className={styles.price}>
                        ({orignalDevis?.option_prix?.[0]?.emballage} €)
                      </span>
                    </td>
                    <td>
                      {difference?.emballage?.value && (
                        <>
                          {difference?.emballage?.value}{" "}
                          <span className={styles.price}>
                            ({roundNumber(difference?.emballage?.price)} €)
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Déballage</td>
                    <td>
                      {orignalDevis?.option_prix?.[0]?.deballage === 0
                        ? "❌"
                        : "✅"}{" "}
                      <span className={styles.price}>
                        ({orignalDevis?.option_prix?.[0]?.deballage} €)
                      </span>
                    </td>
                    <td>
                      {difference?.deballage?.value && (
                        <>
                          {difference?.deballage?.value}{" "}
                          <span className={styles.price}>
                            ({roundNumber(difference?.deballage?.price)} €)
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Assurance</td>
                    <td>
                      {orignalDevis?.option_prix?.[0]?.assurance === 0
                        ? "Standart"
                        : "Premium  🎉 "}{" "}
                      <span className={styles.price}>
                        ({orignalDevis?.option_prix?.[0]?.assurance} €)
                      </span>
                    </td>
                    <td>
                      {difference?.assurance?.value && (
                        <>
                          {difference?.assurance?.value}{" "}
                          <span className={styles.price}>
                            ({roundNumber(difference?.assurance?.price)} €)
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Report</td>
                    <td>
                      {orignalDevis?.option_prix?.[0]?.report === 0
                        ? "❌"
                        : "✅"}{" "}
                      <span className={styles.price}>
                        ({orignalDevis?.option_prix?.[0]?.report} €)
                      </span>
                    </td>
                    <td>
                      {difference?.report?.value && (
                        <>
                          {difference?.report?.value}{" "}
                          <span className={styles.price}>
                            ({roundNumber(difference?.report?.price)} €)
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className={styles.separatorArray}>
                <p>Meuble</p>
              </div>
              <div className={styles.inline}>
                <div className={styles.originModif}>
                  <div className={styles.header}>
                    <p className={styles.Label}>Ajouter</p>
                  </div>
                  {difference?.meuble?.add?.map((el, i) => (
                    <div key={i} className={styles.lineMeuble}>
                      {el.nom}{" "}
                      <span className={styles.price}>{el?.price}€</span>
                    </div>
                  ))}
                </div>
                <div className={styles.originModif}>
                  <div className={styles.header}>
                    <p className={styles.Label}>Retirer</p>
                  </div>
                  {difference?.meuble?.remove?.map((el, i) => (
                    <div key={i} className={styles.lineMeuble}>
                      {el.nom}{" "}
                      <span className={styles.price}>{el?.price}€</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.separatorArray}>
                <p>Montage demontage</p>
              </div>
              <div className={styles.inline}>
                <div className={styles.originModif}>
                  <div className={styles.header}>
                    <p className={styles.Label}>Origine</p>
                  </div>
                  {orignalDevisMix?.map((el, i) => (
                    <div key={i} className={styles.lineMeuble}>
                      {el?.quantity} - {el?.nom}{" "}
                      <span className={styles.price}>
                        {(
                          el?.quantity *
                          (el?.prix_montage *
                            orignalDevis?.option_prix?.[0]?.majoration)
                        ).toFixed(2)}
                        €
                      </span>
                    </div>
                  ))}
                </div>
                <div className={styles.originModif}>
                  <div className={styles.header}>
                    <p className={styles.Label}>Modification Ajouter</p>
                  </div>
                  {difference?.demontage_remontage?.add.map((el, i) => (
                    <div key={i} className={styles.lineMeuble}>
                      {el.nom}{" "}
                      <span className={styles.price}>
                        {el.prix ?? el.price}€
                      </span>
                    </div>
                  ))}
                </div>
                <div className={styles.originModif}>
                  <div className={styles.header}>
                    <p className={styles.Label}>Modification Retirer</p>
                  </div>
                  {difference?.demontage_remontage.remove?.map((el, i) => (
                    <div key={i} className={styles.lineMeuble}>
                      {el.nom}{" "}
                      <span className={styles.price}>
                        {el.prix ?? el.price}€
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.logementArray}>
              <div className={styles.containerArriveDepart}>
                <div className={styles.separatorArray}>
                  <p>Logement Depart</p>
                </div>
                <table className={styles.tableau}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Original</th>
                      <th>Modifier</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Etages</td>
                      <td>
                        {orignalDevis?.etages?.[0]}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.depart?.etage *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>{" "}
                      </td>
                      <td>
                        {difference?.etage_depart && (
                          <>
                            {difference?.etage_depart?.value}{" "}
                            <span className={styles.price}>
                              ({roundNumber(difference?.etage_depart?.price)} €)
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Distance Logement</td>
                      <td>
                        {
                          distances?.filter(
                            (el) =>
                              el.value ===
                              parseInt(orignalDevis?.distance_rue?.[0])
                          )?.[0]?.label
                        }{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.depart
                              ?.distance_logement *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {difference?.distance_logement_depart?.value !==
                          null && (
                          <>
                            {
                              distances?.filter(
                                (el) =>
                                  el.value ===
                                  parseInt(
                                    difference?.distance_logement_depart?.value
                                  )
                              )?.[0]?.label
                            }{" "}
                            <span className={styles.price}>
                              (
                              {roundNumber(
                                difference?.distance_logement_depart?.price
                              )}{" "}
                              €)
                            </span>
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Cave</td>
                      <td>
                        {orignalDevis?.cave?.[0] ? "✅" : "❌"}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.depart?.cave *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {difference?.cave_depart?.value === null
                          ? ""
                          : difference?.cave_depart?.value
                          ? "✅"
                          : "❌"}{" "}
                        {difference?.cave_depart?.value === null ? (
                          ""
                        ) : (
                          <span className={styles.price}>
                            ({roundNumber(difference?.cave_depart?.price)} €)
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Monte Meuble</td>
                      <td>
                        {orignalDevis?.monte_meuble?.[0] !== "0" ? "✅" : "❌"}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.depart
                              ?.monte_meuble *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {difference?.monte_meuble_depart?.value === null
                          ? ""
                          : difference?.monte_meuble_depart?.value === 0
                          ? "❌"
                          : "✅"}{" "}
                        {difference?.monte_meuble_depart?.value === null ? (
                          ""
                        ) : (
                          <span className={styles.price}>
                            (
                            {roundNumber(
                              difference?.monte_meuble_depart?.price
                            )}{" "}
                            €)
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Arrive */}
              <div className={styles.containerArriveDepart}>
                <div className={styles.separatorArray}>
                  <p>Logement Arrive</p>
                </div>
                <table className={styles.tableau}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Original</th>
                      <th>Modifier</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Etages</td>
                      <td>
                        {orignalDevis?.etages?.[1]}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.arrive?.etage *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {difference?.etage_arrive?.value && (
                          <>
                            {difference?.etage_arrive?.value}{" "}
                            <span className={styles.price}>
                              ({roundNumber(difference?.etage_arrive?.price)} €)
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Distance Logement</td>
                      <td>
                        {" "}
                        {
                          distances?.filter(
                            (el) =>
                              el.value ===
                              parseInt(orignalDevis?.distance_rue?.[1])
                          )?.[0]?.label
                        }{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.arrive
                              ?.distance_logement *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {
                          distances?.filter(
                            (el) =>
                              el.value ===
                              parseInt(
                                difference?.distance_logement_arrive?.value
                              )
                          )?.[0]?.label
                        }{" "}
                        {difference?.distance_logement_arrive?.value && (
                          <span className={styles.price}>
                            (
                            {roundNumber(
                              difference?.distance_logement_arrive?.price
                            )}{" "}
                            €)
                          </span>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Cave</td>
                      <td>
                        {orignalDevis?.cave?.[1] ? "✅" : "❌"}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.arrive?.cave *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>{" "}
                      </td>
                      <td>
                        {difference?.cave_arrive?.value === null
                          ? ""
                          : difference?.cave_arrive?.value
                          ? "✅"
                          : "❌"}{" "}
                        {difference?.cave_arrive?.value === null ? (
                          ""
                        ) : (
                          <span className={styles.price}>
                            ({roundNumber(difference?.cave_arrive?.price)} €)
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Monte Meuble</td>
                      <td>
                        {orignalDevis?.monte_meuble?.[1] !== "0" ? "✅" : "❌"}{" "}
                        <span className={styles.price}>
                          (
                          {roundNumber(
                            orignalDevis?.option_prix?.[0]?.arrive
                              ?.monte_meuble *
                              orignalDevis?.option_prix?.[0]?.majoration
                          )}{" "}
                          €)
                        </span>
                      </td>
                      <td>
                        {difference?.monte_meuble_arrive?.value === null
                          ? ""
                          : difference?.monte_meuble_arrive?.value === 0
                          ? "❌"
                          : "✅"}{" "}
                        {difference?.monte_meuble_arrive?.value === null ? (
                          ""
                        ) : (
                          <span className={styles.price}>
                            (
                            {roundNumber(
                              difference?.monte_meuble_arrive?.price
                            )}{" "}
                            €)
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.inputContainer}>
            <Input
              placeholder={"Frais de retenue"}
              value={retenuePrice}
              onChange={handleRetenue}
            />
          </div>
          <div className={styles.containerRadio}>
            <input
              onChange={(e) => setVerification(e.target.checked)}
              type="checkbox"
            ></input>
            <label>Je confirme la modification des frais de retenue</label>
          </div>
          <div className={styles.validateContainer}>
            <Button
              onClick={() => {
                if (verification) {
                  setLoading(true);
                  handleValidateDiff();
                  return;
                }
              }}
              text="✔️ Valider"
              size={"small"}
              type="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
