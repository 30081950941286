import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getAllPiece = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllRooms {
        getAllRooms {
          id
          nom
          visuel
        }
      }
    `,
  });
  return { data, errors };
};
