import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { createObject, deleteObjet, updateObject } from "../../apiCalls/objets";
import { getAllPiece } from "../../apiCalls/pieces";
import { getAllSubCategorie } from "../../apiCalls/subCategorie";
import Button from "../../commons/Button";

import HeaderObject from "../../commons/HeaderObject";
import Input from "../../commons/Input";
import LineObject from "../../commons/LineObject";
import Modal from "../../commons/Modal";
import SearchBar from "../../commons/SearchBar/SearchBar";
import Select from "../../commons/Select";
import SwitchButton from "../../commons/SwitchButton";
import { arrayPoids } from "../../ENUMS/poidsModelEnum";
import { removeAccents } from "../../utils/AccentRemove";
import ModalObject from "./modalObject";
import styles from "./Objets.module.scss";
export default function Objets({ data }) {
  const [allObject, setAllObject] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [selectToDelete, setSelectTodelete] = useState();
  const [modalModify, setModalModify] = useState(false);
  const [arrayPiece, setArrayPiece] = useState();
  const [subCategories, setSubCategorie] = useState();
  const [selectObjet, setSelectObjet] = useState([]);
  const [price, setPrice] = useState({
    largeur: "",
    hauteur: "",
    profondeur: "",
    prix_montage: "",
    visible: false,
    poids: "",
    nom: "",
    visuel: "",
    piece: "",
    prix_m3: "",
    demontable: false,
    supplemet: 0,
    sousCategorie: "",
  });
  useEffect(() => {
    setAllObject(data);
  }, [data]);

  const catchAllPiece = useCallback(async () => {
    await getAllPiece().then((res) => {
      const data = res.data.getAllRooms.map((el) => ({
        id: parseInt(el.id),
        value: parseInt(el.id),
        label: el.nom,
      }));
      setArrayPiece(data);
    });
  }, []);

  const catchAllSubCat = useCallback(async () => {
    await getAllSubCategorie().then((res) => {
      const data = res.data.allSubCategorie.map((el) => ({
        id: parseInt(el.id),
        value: parseInt(el.id),
        label: el.nom,
      }));
      setSubCategorie(data);
    });
  }, []);

  useEffect(() => {
    catchAllPiece();
    catchAllSubCat();
  }, [catchAllPiece]);

  const handleDelete = useCallback(async () => {
    await deleteObjet({ id: parseInt(selectToDelete?.id) }).then(() => {
      window.location.reload();
    });
  }, [selectToDelete?.id]);

  const filterObjectMyName = useCallback(
    (e) => {
      if (e === "") {
        setAllObject(data);
        return;
      }
      const arrayOriginal = [...allObject];

      const resultat = arrayOriginal.filter(
        (el) =>
          removeAccents(el.nom).toLowerCase().indexOf(removeAccents(e)) > -1
      );
      setAllObject(resultat);
    },
    [allObject, data]
  );

  const handleCreate = useCallback(async () => {
    const data = {
      largeur: price.largeur,
      hauteur: price.hauteur,
      profondeur: price.profondeur,
      prix_montage: price.prix_montage,
      visible: price.visible,
      poids: price.poids,
      nom: price.nom,
      visuel: price.visuel,
      piece: parseInt(price.piece),
      prix_m3: 41,
      demontable: price.demontable,
      supplemet: 0,
      sousCategorie: parseInt(price.sousCategorie),
    };
    await createObject(data)
      .then((res) => window.location.reload())
      .catch((err) => console.log(err));
  }, [price]);

  return (
    <div className={styles.container}>
      <div>
        <Button
          text={"➕ Ajouter un objet"}
          onClick={() => setModalCreate(true)}
          type={"primary"}
          size="medium"
        />
        <br />
      </div>
      <div className="header">
        <div className={styles.searchBar}>
          <SearchBar onChange={filterObjectMyName} />
        </div>
      </div>

      <HeaderObject />

      {allObject?.map((el) => (
        <LineObject
          data={el}
          key={el.id}
          setModalDelete={setModalDelete}
          select={setSelectTodelete}
          selectToModiFy={setSelectObjet}
          setModalModify={setModalModify}
        />
      ))}

      {modalModify && (
        <Modal handleClose={() => setModalModify(false)}>
          <ModalObject
            close={() => setModalModify(false)}
            data={selectObjet}
            subCategories={subCategories}
            piece={arrayPiece}
          />
        </Modal>
      )}

      {modalCreate && (
        <Modal handleClose={() => setModalCreate(false)}>
          <div className={styles.modalDelete}>
            <h2>Créer un nouvel objet</h2>
            <br />
            <div className={styles.containerInput}>
              <Input
                className={styles.inputModify}
                value={price.nom}
                onChange={(e) => setPrice({ ...price, nom: e.target.value })}
                placeholder="Nom"
              />
              <Input
                className={styles.inputModify}
                value={price.visuel}
                onChange={(e) => setPrice({ ...price, visuel: e.target.value })}
                placeholder="Visuel"
              />
              <Select
                data={arrayPiece}
                onChange={(e) => setPrice({ ...price, piece: e })}
                value={price.piece}
                placeHolder="Pièce"
              />
              <br />
              <Select
                data={subCategories}
                onChange={(e) => setPrice({ ...price, sousCategorie: e })}
                value={price.sousCategorie}
                placeHolder="Sous-catégorie"
              />
              <br />
              <div className={styles.hplContainer}>
                <Input
                  className={styles.inputModify}
                  placeholder="Hauteur cm"
                  type={"number"}
                  value={price.hauteur}
                  onChange={(e) =>
                    setPrice({ ...price, hauteur: parseInt(e.target.value) })
                  }
                />
                <Input
                  className={styles.inputModify}
                  placeholder="Longueur cm"
                  type={"number"}
                  value={price.profondeur}
                  onChange={(e) =>
                    setPrice({ ...price, profondeur: parseInt(e.target.value) })
                  }
                />
                <Input
                  className={styles.inputModify}
                  placeholder="Largeur cm"
                  type={"number"}
                  value={price.largeur}
                  onChange={(e) =>
                    setPrice({ ...price, largeur: parseInt(e.target.value) })
                  }
                />
              </div>
              <Select
                data={arrayPoids}
                onChange={(e) => setPrice({ ...price, poids: e })}
                value={price.poids}
                placeHolder="Poids"
              />
              <br />
              <div className={styles.hplContainer}>
                {/* <Input
                  className={styles.inputModify}
                  value={price.prix_m3}
                  onChange={(e) =>
                    setPrice({ ...price, prix_m3: e.target.value })
                  }
                  type={"number"}
                  placeholder="Prix m3"
                /> */}

                <Input
                  className={styles.inputModify}
                  value={price.prix_montage}
                  onChange={(e) =>
                    setPrice({
                      ...price,
                      prix_montage: parseInt(e.target.value),
                    })
                  }
                  placeholder="Prix Montage"
                  type="number"
                />
                {/* <Input
                  className={styles.inputModify}
                  value={price.supplemet}
                  onChange={(e) =>
                    setPrice({
                      ...price,
                      supplemet: parseInt(e.target.value),
                    })
                  }
                  placeholder="Supplement"
                  type="number"
                /> */}
              </div>
              <div className={styles.lineSwitch}>
                <div className={styles.switchContainer}>
                  <p>Visible :</p>
                  <SwitchButton
                    value={price.visible}
                    onChange={(e) => setPrice({ ...price, visible: e })}
                    data={data?.visible}
                  />
                </div>
                <div className={styles.switchContainer}>
                  <p>Démontable :</p>
                  <SwitchButton
                    value={price.demontable}
                    onChange={(e) => setPrice({ ...price, demontable: e })}
                    data={data?.demontable}
                  />
                </div>
              </div>
            </div>
            <Button
              onClick={handleCreate}
              text="Valider"
              size={"medium"}
              type="primary"
            />
          </div>
        </Modal>
      )}
      {modalDelete && (
        <Modal handleClose={() => setModalDelete(false)}>
          <div className={styles.modalDelete}>
            <p>
              Êtes vous sûre de vouloir supprimer l'objet{" "}
              <span className="orangeText weigth">{selectToDelete?.nom}</span> ?
            </p>
            <Button
              onClick={handleDelete}
              text="✖️ Supprimer"
              size={"small"}
              type="delete"
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
