export const regionDepartment = [
  {
    label: "normandie",
    value: "normandie",
    data: ["27", "76", "14", "50", "61"],
  },
  {
    label: "idf",
    value: "idf",
    data: ["75", "78", "77", "91", "92", "93", "94", "95"],
  },
  {
    label: "bretagne",

    value: "bretagne",
    data: ["22", "29", "35", "56"],
  },
  {
    label: "est",

    value: "est",
    data: ["08", "10", "51", "52", "54", "55", "57", "67", "68", "88"],
  },
  {
    label: "bourgogne",
    value: "bourgogne",
    data: ["21", "25", "39", "58", "70", "71", "89", "90"],
  },
  {
    label: "centre",
    value: "centre",
    data: ["18", "28", "36", "37", "41", "45"],
  },
  {
    label: "aquitaine",
    value: "aquitaine",
    data: [
      "16",
      "18",
      "19",
      "23",
      "24",
      "33",
      "40",
      "47",
      "64",
      "79",
      "86",
      "87",
    ],
  },
  {
    label: "paca",
    value: "paca",
    data: ["04", "05", "06", "13", "83", "84"],
  },
  {
    label: "auvergne-rhone-alpe",
    value: "auvergne-rhone-alpe",
    data: [
      "03",
      "01",
      "07",
      "15",
      "26",
      "37",
      "42",
      "43",
      "63",
      "69",
      "73",
      "74",
    ],
  },
  {
    label: "nord",
    value: "nord",
    data: ["02", "59", "60", "62", "80"],
  },
  {
    label: "loire",
    value: "loire",
    data: ["44", "49", "53", "72", "85"],
  },
  {
    label: "occitanie",
    value: "occitanie",
    data: [
      "11",
      "12",
      "09",
      "46",
      "48",
      "30",
      "31",
      "32",
      "34",
      "65",
      "66",
      "81",
      "82",
    ],
  },
];
