import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getAllObject } from "../../apiCalls/objets";
import Navbar from "../../components/navbar";
import Objets from "../../section/Objets";
import { useGlobalContext } from "../../store/globalContext";

export default function ObjetPage() {
  const context = useGlobalContext();
  const [allObject, setAllObject] = useState([]);

  const handleCatchAllObject = useCallback(async () => {
    await getAllObject()
      .then((res) => setAllObject(res.data.getALLObject))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    handleCatchAllObject();
  }, [handleCatchAllObject]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={4} />
      <div className={"leftSide"}>
        <h2 className={"title"}>OBJETS</h2>
        <Objets data={allObject} />
      </div>
    </div>
  );
}
