import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getAllDevis } from "../../apiCalls/devis";
import Navbar from "../../components/navbar";
import Client from "../../section/Clients";
import { useGlobalContext } from "../../store/globalContext";

export default function LeadPage() {
  const context = useGlobalContext();
  const [allDevis, setAllDevis] = useState([]);

  const handleCatchCLients = useCallback(async () => {
    await getAllDevis()
      .then((res) =>
        setAllDevis(
          res.data.getCommandeAdmin
            .filter((el) => el.status === "LEADS")
            .reverse()
        )
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    handleCatchCLients();
  }, [handleCatchCLients]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={2} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Leads</h2>
        <Client type="Lead" data={allDevis} refresh={handleCatchCLients} />
      </div>
    </div>
  );
}
