import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getPrice } from "../../apiCalls/prix";
import Navbar from "../../components/navbar";
import Prix from "../../section/Prix";
import { useGlobalContext } from "../../store/globalContext";

export default function PrixPage() {
  const context = useGlobalContext();
  const [allPrice, setAllPrice] = useState([]);

  const handleCatchPrice = useCallback(async () => {
    await getPrice().then((res) => setAllPrice(res.data.getAllPrice));
  }, []);

  useEffect(() => {
    handleCatchPrice();
  }, [handleCatchPrice]);

  return (
    <div className={"main"}>
      <Navbar user={context?.userInformation} sectionActive={5} />
      <div className={"leftSide"}>
        <h2 className={"title"}>Prix</h2>
        <Prix data={allPrice} refresh={handleCatchPrice} />
      </div>
    </div>
  );
}
