import classNames from "classnames";
import React, { useCallback, useState } from "react";
import styles from "./Saison.module.scss";
import LineSaison from "./line";
import Button from "../../commons/Button";
import { CreateSaison } from "../../apiCalls/saison";
import Input from "../../commons/Input";
export default function Saison({ data }) {
  const [create, setCreate] = useState(false);
  const [value, setValue] = useState({
    title: "",
    end: "",
    start: "",
    majoration: null,
    majorationRate: "",
  });

  const handleSubmit = useCallback(async () => {
    const rate = parseFloat(value?.majorationRate);
    const valueSend = {
      ...value,
      majoration: rate > 0 ? true : false,
      majorationRate: rate,
    };
    await CreateSaison(valueSend)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [value]);

  return (
    <div className={styles.container}>
      <br />
      <div className={styles.header}>
        {!create && (
          <Button
            className={styles.buttonAdd}
            type={"primary"}
            text="➕ Ajouter"
            size={"small"}
            onClick={() => setCreate(true)}
          />
        )}
        <h2>Majoration des périodes</h2>
      </div>
      <br />
      <div className={classNames(styles.line, styles.orangeLine)}>
        <div className={styles.name}>Nom</div>
        <div className={styles.monthDate}>Début</div>
        <div className={styles.monthDate}>Fin</div>
      </div>
      {data?.map((el) => (
        <LineSaison key={el.id} el={el} />
      ))}
      {create && (
        <div className={styles.line}>
          <div className={styles.name}>
            <Input
              placeholder={"Nom"}
              value={value.title}
              onChange={(e) => setValue({ ...value, title: e.target.value })}
            />
          </div>
          <div className={styles.name}>
            <Input
              placeholder={"% majoration"}
              type={"number"}
              value={value.majorationRate}
              onChange={(e) =>
                setValue({ ...value, majorationRate: e.target.value })
              }
            />
          </div>

          <div className={styles.inputLine}>
            <Input
              placeholder={"Début"}
              value={value.start}
              onChange={(e) => setValue({ ...value, start: e.target.value })}
            />
            <span> / </span>
            <Input
              placeholder={"Fin"}
              value={value.end}
              onChange={(e) => setValue({ ...value, end: e.target.value })}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              text={"Annuler"}
              size={"small"}
              onClick={() => setCreate(false)}
              type="secondary"
            />
            <Button
              text={"Valider"}
              size={"small"}
              onClick={handleSubmit}
              type="primary"
            />
          </div>
        </div>
      )}
    </div>
  );
}
