import { format } from "date-fns";
import React from "react";

import distances from "../../utils/ENUM/distance";
import trueOrFalse from "../../utils/ENUM/trueOrFalse";
import typeOfLeave from "../../utils/ENUM/typeOfLeave";
import styles from "./RecapInfo.module.scss";

const space = [
  " Un studio <20 m²",
  "20 à 30 m²",
  "40 à 60 m²",
  "60 à 80 m²",
  "80 à 100 m²",
  "100 à 200 m²",
  "200 m² et plus",
];
export default function RecapInfo({ data }) {
  const InfoBlock = ({ label, value }) => {
    return (
      <div className={styles.informationBlock}>
        <p>
          <span>{label} : </span> {value}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h2>Plus d'informations</h2>
      <div className={styles.header}>
        <p>Les informations clients</p>
      </div>
      <div className={styles.informationPrimary}>
        <div className={styles.inlineInformation}>
          <div className={styles.columnInformation}>
            <InfoBlock value={data?.nom} label="Nom Complet" />
            <InfoBlock value={data?.telephone} label="Téléphone" />
          </div>
          <div className={styles.columnInformation}>
            <InfoBlock value={data?.client[0].email} label="Email" />
            <InfoBlock value={space[data?.surface]} label="Surface" />
          </div>
        </div>
        <InfoBlock
          value={
            data.step > 1
              ? format(
                  new Date(data?.date_demenagement.replaceAll("-", "/")),
                  "dd/MM/yyyy"
                )
              : ""
          }
          label="Date de déménagement"
        />
        <InfoBlock
          value={
            data?.step > 1
              ? data?.flexible?.debut === "" || data?.flexible?.fin === ""
                ? "Non"
                : ` Oui du ${data?.flexible?.debut?.replaceAll(
                    "-",
                    "/"
                  )} au ${data?.flexible?.fin?.replaceAll("-", "/")}`
              : ""
          }
          label="Flexible"
        />
      </div>
      {data.step > 1 && (
        <div>
          <div className={styles.inlineInformation}>
            <div className={styles.destArrInfo}>
              <p>Adresse de départ</p>
            </div>
            <div className={styles.destArrInfo}>
              <p>Adresse d'arrivée</p>
            </div>
          </div>
        </div>
      )}

      {data.step > 1 && (
        <div>
          <div className={styles.inlineInformation}>
            <div className={styles.columnInformation}>
              <InfoBlock value={data?.depart?.rue} label="Rue" />
              <InfoBlock value={data?.depart?.code} label="Code Postal" />
              <InfoBlock value={data?.depart?.ville} label="Ville" />
              <InfoBlock
                value={data.step > 7 ? typeOfLeave[data?.type?.[0]] : ""}
                label="Type"
              />

              <InfoBlock
                value={
                  data.step > 7
                    ? data?.etages[0] === 0
                      ? "RDC"
                      : data?.etages[0]
                    : ""
                }
                label="Nombre d'étages"
              />
              <InfoBlock
                value={data.step > 7 ? distances[data?.distance_rue[0]] : ""}
                label="Distance Logement Rue"
              />
              <InfoBlock
                value={
                  data.step > 7 ? (data?.ascenseur[0] ? "Oui" : "Non") : ""
                }
                label="Ascenseur"
              />
              <InfoBlock
                value={data.step > 7 ? (data?.cave[0] ? "Oui" : "Non") : ""}
                label="Cave"
              />
              <InfoBlock
                value={data.step > 7 ? trueOrFalse[data?.monte_meuble[0]] : ""}
                label="Monte-meubles"
              />
              <InfoBlock
                value={data.step > 7 ? data?.commentaires[0] : ""}
                label="Message"
              />
            </div>
            <div className={styles.columnInformation}>
              <InfoBlock value={data?.arrive?.rue} label="Rue" />
              <InfoBlock value={data?.arrive?.code} label="Code Postal" />
              <InfoBlock value={data?.arrive?.ville} label="Ville" />
              <InfoBlock
                value={data.step > 7 ? typeOfLeave[data?.type[1]] : ""}
                label="Type"
              />

              <InfoBlock
                value={
                  data.step > 7
                    ? data?.etages[1] === 0
                      ? "RDC"
                      : data?.etages[1]
                    : ""
                }
                label="Nombre d'étages"
              />
              <InfoBlock
                value={data.step > 7 ? distances[data?.distance_rue[1]] : ""}
                label="Distance Logement Rue"
              />
              <InfoBlock
                value={
                  data.step > 7 ? (data?.ascenseur[1] ? "Oui" : "Non") : ""
                }
                label="Ascenseur"
              />
              <InfoBlock
                value={data.step > 7 ? (data?.cave[1] ? "Oui" : "Non") : ""}
                label="Cave"
              />
              <InfoBlock
                value={data.step > 7 ? trueOrFalse[data?.monte_meuble[1]] : ""}
                label="Monte-meubles"
              />
              <InfoBlock
                value={data.step > 7 ? data?.commentaires[1] : ""}
                label="Message"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
