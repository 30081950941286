import classNames from "classnames";
import React from "react";
import Button from "../Button";
import styles from "./LineAdmin.module.scss";

export default function LineAdmin({ el, children, onDelete }) {
  return (
    <div
      className={classNames(styles.lineClient, {
        [styles.payer]: el.status === "PAYED",
        [styles.enCour]: el.status === "EN_COUR",
        [styles.valider]: el.status === "VALIDATED",
      })}
      key={el.id}
    >
      <div className={styles.container}>
        <div>
          <p className={styles.textHeader}>Nom</p>
        </div>
        <div>
          <p className={styles.textHeader}>Email</p>
        </div>
        <div>
          <p className={styles.textHeader}>Téléphone</p>
        </div>
        <div>
          <p className={styles.textHeader}>Fonction</p>
        </div>
        <div></div>
        <div>
          <p className={styles.label}>{el.utilisateur}</p>
        </div>
        <div>
          <p className={styles.label}>{el.email}</p>
        </div>
        <div>
          <p className={styles.label}>{el.telephone}</p>
        </div>
        <div>
          <p className={styles.label}>
            {el.service}{" "}
            <span>{el.service === "COMMERCIAL" && el.region_name}</span>
          </p>
        </div>
        <div className={styles.btn}>
          <Button
            onClick={() => onDelete(el.id)}
            text={<span style={{ fontSize: "2rem", padding: "0.4rem" }}>🗑️</span>}
            type="delete"
          />
        </div>
      </div>

      {children}
    </div>
  );
}
