import React, { useCallback } from "react";
import { useState } from "react";

export default function SwitchButton({ data, onChange, disable = false }) {
  const [value, setValue] = useState(data);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.checked);
      onChange(e.target.checked);
    },
    [onChange]
  );
  if (disable) {
    return (
      <label className="switch">
        <input
          checked={value}
          disabled
          onChange={handleChange}
          type="checkbox"
        />
        <span className="slider round"></span>
      </label>
    );
  }
  return (
    <label className="switch">
      <input checked={value} onChange={handleChange} type="checkbox" />
      <span className="slider round"></span>
    </label>
  );
}
