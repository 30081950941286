import { eachWeekOfInterval, getDaysInMonth } from "date-fns";

export const week = () => {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const totalDay = getDaysInMonth(new Date(year, month));
  const result = eachWeekOfInterval({
    start: new Date(year, month, 1),
    end: new Date(year, month, totalDay),
  });
  return result;
};

export const formatPerfectDate = (data) => {
  const value = data.replaceAll("/", " ").split(" ");
  return new Date(value[2], value[1] - 1, value[0]);
};
