import React, { useState, useMemo } from "react";
import styles from "./Blog.module.scss";
import Button from "../../commons/Button";
import CreateArticle from "./create";
import AllArticles from "./articles";
import { useCallback } from "react";
import ModifyArticle from "./Modify";
export default function Blog({ data }) {
  const [type, setType] = useState("ALL");
  const [selectBlog, setSelectBlog] = useState();
  const printButton = useMemo(() => {
    switch (type) {
      case "ADD":
        return (
          <div className={styles.buttonContainerBack}>
            <Button
              type={"primary"}
              size="small"
              text={"🔙 Retour"}
              onClick={() => setType("ALL")}
            />
          </div>
        );
      case "MODIFY":
        return (
          <div className={styles.buttonContainerBack}>
            <Button
              type={"primary"}
              size="small"
              text={"🔙 Retour"}
              onClick={() => setType("ALL")}
            />
          </div>
        );
      default:
        return (
          <div className={styles.buttonContainer}>
            <Button
              type={"primary"}
              size="small"
              text={<span style={{ fontSize: "1.2rem", padding: "0.7rem" }}>➕ Ajouter un article</span>}
              onClick={() => setType("ADD")}
            />
          </div>
        );
    }
  }, [type]);

  const handleModify = useCallback((e) => {
    setType("MODIFY");
    setSelectBlog(e);
  }, []);

  const printChildren = useMemo(() => {
    switch (type) {
      case "ALL":
        return <AllArticles modify={handleModify} data={data} />;

      case "ADD":
        return <CreateArticle />;
      default:
        return (
          <ModifyArticle data={selectBlog} handleBack={() => setType("ALL")} />
        );
    }
  }, [data, handleModify, selectBlog, type]);

  return (
    <div className={styles.container}>
      {printButton}
      {printChildren}
    </div>
  );
}
