import React, { useCallback, useContext, useState } from "react";
import { useMemo } from "react";
import Button from "../../../commons/Button";
import { accessoires } from "../../../Mockup/Accesoires";
import { cartons } from "../../../Mockup/Cartons";
import { modifiedContext } from "../../../store/devisContext";
import styles from "./Accessoires.module.scss";
import AddAccessoireLine from "./addAccessoireLine";
import LineCarton from "./lineCarton";

export default function Accessoires({ data }) {
  const context = useContext(modifiedContext);
  const myCarton = useMemo(() => {
    const uniqueCarton = data?.cartonSelect?.map((el) => el.object) ?? [];
    return uniqueCarton;
  }, [data?.cartonSelect]);

  const myAccessoire = useMemo(() => {
    const uniqueAccessoire =
      data?.accessoireSelect?.map((el) => el.object) ?? [];
    return uniqueAccessoire;
  }, [data?.accessoireSelect]);

  const dataAll = cartons.concat(accessoires);

  const [addAcc, setAddAcc] = useState(false);

  const handleAddNewAccessoires = useCallback(
    (target, el) => {
      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          prixCarton: data.emb_deb_data.prixCarton + el.prix,
        },
      }));
      context.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            carton: data.option_prix[0].carton + el.prix,
          },
        ],
      }));

      let oldCarton = data.cartonSelect === null ? [] : [...data.cartonSelect];
      let oldAccessoire =
        data.accessoireSelect === null ? [] : [...data.accessoireSelect];
      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          livraison: 18.5,
          totalCarton: 10,
        },
      }));
      if (target === "carton") {
        oldCarton.push({
          quantity: 1,
          object: el,
        });
        context.setData((data) => ({
          ...data,
          emb_deb_data: {
            ...data.emb_deb_data,
            cartonSelect: oldCarton,
          },
        }));
        setAddAcc(false);
        return;
      }
      oldAccessoire.push({
        quantity: 1,
        object: el,
      });
      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          accessoireSelect: oldAccessoire,
        },
      }));
      setAddAcc(false);
    },
    [context, data?.accessoireSelect, data?.cartonSelect]
  );

  const handleModifyQuantity = useCallback(
    (element, quantity, type, originalElement) => {
      let oldCarton = [...context?.data?.emb_deb_data?.cartonSelect];
      let oldAccessoire = [...context?.data?.emb_deb_data?.accessoireSelect];
      const price = element.prix * quantity;
      const orignalPrice =
        originalElement.quantity * originalElement.object.prix;

      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          prixCarton: data.emb_deb_data?.prixCarton - orignalPrice + price,
        },
      }));
      context.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            carton: data.option_prix[0].carton - orignalPrice + price,
          },
        ],
      }));

      if (type === "carton") {
        const index = oldCarton.indexOf(originalElement);
        const valideElement = {
          quantity: quantity,
          object: element,
        };
        oldCarton.splice(index, 1, valideElement);
        context.setData((data) => ({
          ...data,
          emb_deb_data: {
            ...data.emb_deb_data,
            cartonSelect: oldCarton,
          },
        }));
        return;
      }
      const index = oldAccessoire.indexOf(originalElement);
      const valideElement = {
        quantity: quantity,
        object: element,
      };
      oldAccessoire.splice(index, 1, valideElement);
      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          accessoireSelect: oldAccessoire,
        },
      }));
      return;
    },
    [context]
  );

  const handleDelete = useCallback(
    (element, type, i) => {
      let oldCarton = [...context?.data?.emb_deb_data?.cartonSelect];
      let oldAccessoire = [...context?.data?.emb_deb_data?.accessoireSelect];
      if (type === "carton") {
        oldCarton.splice(i, 1);
        context.setData((data) => ({
          ...data,
          emb_deb_data: {
            ...data.emb_deb_data,
            cartonSelect: oldCarton,
          },
        }));

        return;
      }
      oldAccessoire.splice(i, 1);
      context.setData((data) => ({
        ...data,
        emb_deb_data: {
          ...data.emb_deb_data,
          accessoireSelect: oldAccessoire,
        },
      }));

      return;
    },
    [context]
  );

  return (
    <div>
      <div className={styles.headerElement}>
        {addAcc ? (
          <Button
            type="delete"
            text={
              <span
                style={{ fontSize: "1rem", padding: "0.7rem", color: "white" }}
              >
                ✖️ Annuler
              </span>
            }
            onClick={() => setAddAcc(false)}
          />
        ) : (
          <Button
            type="primary"
            text={
              <span style={{ fontSize: "1rem", padding: "0.7rem" }}>
                ➕ Ajouter
              </span>
            }
            onClick={() => setAddAcc(true)}
          />
        )}
      </div>
      {addAcc && (
        <div>
          {dataAll.map((element) => (
            <AddAccessoireLine
              element={element}
              key={element.nom}
              handleAddAcc={handleAddNewAccessoires}
            />
          ))}
        </div>
      )}
      {!addAcc && (
        <div>
          {myCarton?.map((element, i) => (
            <LineCarton
              handleModify={handleModifyQuantity}
              element={element}
              data={data.cartonSelect}
              i={i}
              key={`${element.id}-${element.nom}-${i}`}
              handleDelete={handleDelete}
              type={"carton"}
            />
          ))}
          {myAccessoire?.map((element, i) => (
            <LineCarton
              handleModify={handleModifyQuantity}
              element={element}
              data={data.accessoireSelect}
              i={i}
              key={`${element.id}-${element.nom}-${i}`}
              type="accessoires"
              handleDelete={handleDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
}
