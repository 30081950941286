import React from "react";
import Form from "./block";
import styles from "./InformationLogement.module.scss";
export default function InformationLogement({
  data,
  prices,
  unique,
  optionPrix,
}) {
  return (
    <div className={styles.container}>
      <Form
        prices={prices}
        data={data}
        i={0}
        unique={unique}
        optionPrix={optionPrix}
      />
      <Form
        prices={prices}
        data={data}
        i={1}
        unique={unique}
        optionPrix={optionPrix}
      />
    </div>
  );
}
