import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getAllObject = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query RootQuery {
        getALLObject {
          id
          nom
          piece
          sous_categorie
          hauteur
          largeur
          profondeur
          poids
          prix_m3
          supplemet
          visuel
          demontable
          from_user
          selectPiece
          prix_montage
          visible
        }
      }
    `,
  });
  return { data, errors };
};

export const updateObject = async ({
  id,
  hauteur,
  profondeur,
  largeur,
  visible,
  prix_montage,
  poids,
  piece,
  nom,
  sous_categorie,
  demontable,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation Mutation(
        $updateObjectId: ID
        $hauteur: Int
        $profondeur: Int
        $largeur: Int
        $visible: Boolean
        $prixMontage: Float
        $poids: POIDS_ENUM
        $piece: Int
        $sousCategorie: Int
        $nom: String
        $demontable: Boolean
      ) {
        updateObject(
          id: $updateObjectId
          hauteur: $hauteur
          profondeur: $profondeur
          largeur: $largeur
          visible: $visible
          prix_montage: $prixMontage
          poids: $poids
          piece: $piece
          sous_categorie: $sousCategorie
          nom: $nom
          demontable: $demontable
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      updateObjectId: id,
      hauteur: hauteur,
      profondeur: profondeur,
      largeur: largeur,
      visible: visible,
      prixMontage: prix_montage,
      poids: poids,
      piece: piece,
      nom: nom,
      sousCategorie: sous_categorie,
      demontable: demontable,
    },
  });
  return { data, errors };
};

export const createObject = async ({
  hauteur,
  profondeur,
  largeur,
  visible,
  prix_montage,
  poids,
  demontable,
  piece,
  nom,
  sousCategorie,
  prix_m3,
  visuel,
  supplemet,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateObjects(
        $nom: String
        $piece: Int
        $sousCategorie: Int
        $hauteur: Int
        $largeur: Int
        $profondeur: Int
        $prixM3: Int
        $visuel: String
        $supplemet: Float
        $demontable: Boolean
        $poids: POIDS_ENUM
        $visible: Boolean
        $prixMontage: Float
      ) {
        createObjects(
          nom: $nom
          piece: $piece
          sous_categorie: $sousCategorie
          hauteur: $hauteur
          largeur: $largeur
          profondeur: $profondeur
          prix_m3: $prixM3
          visuel: $visuel
          supplemet: $supplemet
          demontable: $demontable
          poids: $poids
          visible: $visible
          prix_montage: $prixMontage
        ) {
          nom
        }
      }
    `,
    variables: {
      nom: nom,
      piece: piece,
      sousCategorie: sousCategorie,
      hauteur: hauteur,
      largeur: largeur,
      profondeur: profondeur,
      prixM3: prix_m3,
      visuel: visuel,
      supplemet: supplemet,
      demontable: demontable,
      poids: poids,
      visible: visible,
      prixMontage: prix_montage,
    },
  });
  return { data, errors };
};

export const createObjectModif = async ({
  hauteur,
  profondeur,
  largeur,
  visible,
  prix_montage,
  poids,
  demontable,
  piece,
  nom,
  sousCategorie,
  prix_m3,
  visuel,
  supplemet,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateObjectAdminModify(
        $piece: Int
        $sousCategorie: Int
        $largeur: Int
        $hauteur: Int
        $profondeur: Int
        $prixM3: Int
        $visuel: String
        $supplemet: Float
        $demontable: Boolean
        $poids: POIDS_ENUM
        $visible: Boolean
        $prixMontage: Float
        $fromUser: Boolean
        $nom: String
      ) {
        createObjectAdminModify(
          piece: $piece
          sous_categorie: $sousCategorie
          largeur: $largeur
          hauteur: $hauteur
          profondeur: $profondeur
          prix_m3: $prixM3
          visuel: $visuel
          supplemet: $supplemet
          demontable: $demontable
          poids: $poids
          visible: $visible
          prix_montage: $prixMontage
          from_user: $fromUser
          nom: $nom
        ) {
          id
        }
      }
    `,
    variables: {
      nom: nom,
      piece: piece,
      sousCategorie: sousCategorie,
      hauteur: hauteur,
      largeur: largeur,
      profondeur: profondeur,
      prixM3: prix_m3,
      visuel: visuel,
      supplemet: supplemet,
      demontable: demontable,
      poids: poids,
      visible: visible,
      prixMontage: prix_montage,
    },
  });
  return { data, errors };
};

export const deleteObjet = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeleteObjet($deleteObjetId: ID) {
        deleteObjet(id: $deleteObjetId) {
          successful
          message
        }
      }
    `,
    variables: {
      deleteObjetId: id,
    },
  });
  return { data, errors };
};
