const etapesEnum = [
  "Inventaire",
  "Démontage / Remontage",
  "Emballage / Déballage",
  "Besoin de materiels",
  "Assurance",
  "Report",
  "Caractéristiques logements",
  "Devis",
];
export default etapesEnum;
