import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import Select from "../../../commons/Select";
import Button from "../../../commons/Button";
import styles from "./AssuranceReport.module.scss";
import { modifiedContext } from "../../../store/devisContext";

const assurances = [
  {
    label: "Assurance Premium",
    value: 1,
  },
  {
    label: "Assurance contractuel",
    value: 0,
  },
];

const trueOrFlase = [
  {
    value: 0,
    label: "Non",
  },
  {
    value: 1,
    label: "Oui",
  },
];
export default function AssuranceOrReport({ data, allPrice, optionPrix }) {
  const [assurance, setAssurance] = useState();
  const [report, setReport] = useState();
  const [optionPrice, setOptionPrice] = useState();
  const [loading, setLoading] = useState(true);
  const [modifiedAssurance, setModifiedAssurance] = useState(false);
  const [modifiedReport, setModifiedReport] = useState(false);
  const { setData, setDifference } = useContext(modifiedContext);

  useEffect(() => {
    setReport(data?.report);
    setAssurance(data?.assurance);
    setOptionPrice(optionPrix);
    setLoading(false);
  }, [data?.assurance, data?.report, optionPrix]);

  const handleModifiedAssurance = useCallback(async () => {
    let price = 0;
    if (assurance === 1) {
      price = allPrice.filter((el) => el.label === "assurance_premium")[0]
        ?.prix;
    }

    const myOptionPrice = {
      majoration: optionPrice.majoration,
      meuble: optionPrice.meuble,
      montage_demontage: optionPrice.montage_demontage,
      emballage: optionPrice.emballage,
      deballage: optionPrice.deballage,
      assurance: price,
      cartonPredict: optionPrice?.cartonPredict,
      carton: optionPrice.carton,
      livraison: optionPrice.livraison,
      report: optionPrice.report,
      bigWeight: optionPrice.bigWeight,
      km: optionPrice.km,
      frais_dossier: optionPrice.frais_dossier,
      frais_modification: optionPrice.frais_modification,
      depart: {
        monte_meuble: optionPrice.depart.monte_meuble,
        cave: optionPrice.depart.cave,
        etage: optionPrice.depart.etage,
        distance_logement: optionPrice.depart.distance_logement,
      },
      arrive: {
        monte_meuble: optionPrice.arrive.monte_meuble,
        cave: optionPrice.arrive.cave,
        etage: optionPrice.arrive.etage,
        distance_logement: optionPrice.arrive.distance_logement,
      },
    };
    setDifference((difference) => ({
      ...difference,
      assurance: {
        modified: true,
        value: assurance ? "Premium 🎉" : "Standart",
        price: price,
      },
    }));
    setData((data) => ({
      ...data,
      option_prix: [myOptionPrice],
      assurance: assurance,
    }));

    setOptionPrice(myOptionPrice);
    setModifiedAssurance(false);
  }, [
    allPrice,
    assurance,
    optionPrice?.frais_dossier,
    optionPrice?.arrive.cave,
    optionPrice?.arrive.distance_logement,
    optionPrice?.arrive.etage,
    optionPrice?.arrive.monte_meuble,
    optionPrice?.bigWeight,
    optionPrice?.carton,
    optionPrice?.cartonPredict,
    optionPrice?.deballage,
    optionPrice?.depart.cave,
    optionPrice?.depart.distance_logement,
    optionPrice?.depart.etage,
    optionPrice?.depart.monte_meuble,
    optionPrice?.emballage,
    optionPrice?.frais_modification,
    optionPrice?.km,
    optionPrice?.livraison,
    optionPrice?.majoration,
    optionPrice?.meuble,
    optionPrice?.montage_demontage,
    optionPrice?.report,
    setData,
    setDifference,
  ]);

  const handleModifiedReport = useCallback(async () => {
    let price = 0;
    if (report === 1) {
      price = allPrice.filter((el) => el.label === "report")[0]?.prix;
    }
    const myOptionPrice = {
      majoration: optionPrice.majoration,
      meuble: optionPrice.meuble,
      montage_demontage: optionPrice.montage_demontage,
      emballage: optionPrice.emballage,
      deballage: optionPrice.deballage,
      assurance: optionPrice.assurance,
      cartonPredict: optionPrice?.cartonPredict,
      carton: optionPrice.carton,
      livraison: optionPrice.livraison,
      report: price,
      frais_dossier: optionPrice?.frais_dossier,
      frais_modification: optionPrice.frais_modification,
      bigWeight: optionPrice.bigWeight,
      km: optionPrice.km,
      depart: {
        monte_meuble: optionPrice.depart.monte_meuble,
        cave: optionPrice.depart.cave,
        etage: optionPrice.depart.etage,
        distance_logement: optionPrice.depart.distance_logement,
      },
      arrive: {
        monte_meuble: optionPrice.arrive.monte_meuble,
        cave: optionPrice.arrive.cave,
        etage: optionPrice.arrive.etage,
        distance_logement: optionPrice.arrive.distance_logement,
      },
    };

    setDifference((difference) => ({
      ...difference,
      report: {
        modified: true,
        value: report === 1 ? "✅" : "❌",
        price: price,
      },
    }));
    setData((data) => ({
      ...data,
      option_prix: [myOptionPrice],
      report: report === 1 ? true : false,
    }));
    setOptionPrice(myOptionPrice);
    setModifiedReport(false);
  }, [
    allPrice,
    optionPrice?.frais_dossier,
    optionPrice?.arrive.cave,
    optionPrice?.arrive.distance_logement,
    optionPrice?.arrive.etage,
    optionPrice?.arrive.monte_meuble,
    optionPrice?.assurance,
    optionPrice?.bigWeight,
    optionPrice?.carton,
    optionPrice?.cartonPredict,
    optionPrice?.deballage,
    optionPrice?.depart.cave,
    optionPrice?.depart.distance_logement,
    optionPrice?.depart.etage,
    optionPrice?.depart.monte_meuble,
    optionPrice?.emballage,
    optionPrice?.frais_modification,
    optionPrice?.km,
    optionPrice?.livraison,
    optionPrice?.majoration,
    optionPrice?.meuble,
    optionPrice?.montage_demontage,
    report,
    setData,
    setDifference,
  ]);

  if (loading) {
    return <></>;
  }

  return (
    <div>
      {modifiedAssurance ? (
        <div className={styles.line}>
          <Select
            data={assurances}
            value={assurance}
            onChange={(e) => setAssurance(parseInt(e))}
            placeHolder={"Assurance"}
            className={styles.mySelect}
          />

          <div className={styles.inlineButton}>
            <button
              className="btnModify"
              onClick={() => setModifiedAssurance(false)}
            >
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleModifiedAssurance}
            />
          </div>
        </div>
      ) : (
        <div className={styles.line}>
          <p>
            <span className="weigthBoldmodif">Assurance :</span>{" "}
            <span className="valuemodif">
              {" "}
              {assurance === 1 ? "Premium 🎉" : "Contractuelle"}
            </span>
          </p>
          <button
            className="btnModify"
            onClick={() => setModifiedAssurance(true)}
          >
            🪛
          </button>
        </div>
      )}
      {modifiedReport ? (
        <div className={styles.line}>
          <Select
            data={trueOrFlase}
            value={report}
            onChange={(e) => setReport(parseInt(e))}
            placeHolder={"Report"}
            className={styles.mySelect}
          />
          <div className={styles.inlineButton}>
            <button
              className="btnModify"
              onClick={() => setModifiedReport(false)}
            >
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleModifiedReport}
            />
          </div>
        </div>
      ) : (
        <div className={styles.line}>
          <p>
            <span className="weigthBoldmodif">Report :</span>{" "}
            <span className="valuemodif"> {report ? "Oui" : "Non"}</span>
          </p>
          <button className="btnModify" onClick={() => setModifiedReport(true)}>
            🪛
          </button>
        </div>
      )}
    </div>
  );
}
