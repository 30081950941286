import React, { useCallback } from "react";
import styles from "./Create.module.scss";
import { useState } from "react";
import Button from "../../../commons/Button";
import SwitchButton from "../../../commons/SwitchButton";
import { format } from "date-fns";
import { createBlog } from "../../../apiCalls/blog";
import RichText from "../../../components/WYSING";

export default function CreateArticle() {
  const [value, setValue] = useState({
    title: "",
    text: "",
    visible: false,
    date: format(new Date(), "dd/MM/yyyy"),
    image_principal: "",
    metaDescription: "",
    metaTitle: "",
  });

  const handleCatchWords = useCallback(
    (e) => {
      setValue({ ...value, text: e });
    },
    [value]
  );

  const handleSubmit = useCallback(async () => {
    await createBlog({
      title: value.title,
      text: value.text,
      slug: value.title.toLocaleLowerCase().replaceAll(" ", "-"),
      visible: value.visible,
      date: format(new Date(), "dd/MM/yyyy"),
      image_principal: value.image_principal,
      metaDescription: value.metaDescription,
      metaTitle: value.metaTitle,
    })
      .then((res) => window.location.reload())
      .catch((err) => console.log(err));
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.richTextEditor}>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Titre de l'article"
            value={value.title}
            onChange={(e) => setValue({ ...value, title: e.target.value })}
          />
        </div>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Meta Title"
            value={value.metaTitle}
            onChange={(e) => setValue({ ...value, metaTitle: e.target.value })}
          />
        </div>
        <div className={styles.titleContainer}>
          <textarea
            className={styles.titleArticle}
            type="text"
            placeholder="Meta Description"
            value={value.metaDescription}
            onChange={(e) =>
              setValue({ ...value, metaDescription: e.target.value })
            }
          />
        </div>
        <div className={styles.titleContainer}>
          <input
            className={styles.titleArticle}
            type="text"
            placeholder="Adresse url image principale"
            value={value.image_principal}
            onChange={(e) =>
              setValue({ ...value, image_principal: e.target.value })
            }
          />
        </div>
        <RichText onChange={handleCatchWords} />
      </div>
      <div className={styles.switchContainer}>
        <p className={styles.label}>Visible : </p>
        <SwitchButton
          data={value.visible}
          onChange={(e) => setValue({ ...value, visible: e })}
        />
      </div>
      <Button
        text={<span style={{ fontSize: "2rem", padding: "0.3rem" }}>💾</span>}
        className={styles.validateBtn}
        type="primary"
        onClick={handleSubmit}
      />
    </div>
  );
}
