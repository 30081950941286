import classNames from "classnames";
import React from "react";
import styles from "./Input.module.scss";
export default function Input({
  type,
  placeholder,
  className,
  onChange,
  value,
  min,
  max,
  disabled = false,
}) {
  return (
    <input
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      className={classNames(styles.container, { [className]: className })}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
    />
  );
}
