export const cartons = [
  {
    id: 1,
    nom: "Carton standard",
    visuel: "carton-standard",
    prix: 1.54,
    dimension: "Dimensions : 55 x 35 x 30 cm",
    description: "Charge max : 20 kg",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((55 / 100) * 35) / 100) * 30) / 100,
  },
  {
    id: 2,
    nom: "Carton livre",
    visuel: "carton-livre",
    prix: 1.1,
    dimension: "Dimensions : 35 x 27,5 x 30 cm",
    description: "Charge max : 20 kg",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((35 / 100) * 27.5) / 100) * 30) / 100,
  },
  {
    id: 3,
    nom: "Carton 24 verres",
    visuel: "carton-24-verres",
    prix: 5.2,
    dimension: "Dimensions : 35 x 27,5 x 30 cm",
    description: "Structure renforcée et croisillon inclus",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((35 / 100) * 27.5) / 100) * 30) / 100,
  },
  {
    id: 4,
    nom: "Carton 10 assiettes",
    visuel: "carton-10-assiettes",
    prix: 5.2,
    dimension: "Dimensions : 35 x 27,5 x 30 cm",
    description: "Structure renforcée et croisillon inclus",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((35 / 100) * 27.5) / 100) * 30) / 100,
  },
  {
    id: 5,
    nom: "Carton 12 bouteilles",
    visuel: "carton-12-bouteilles",
    prix: 3.9,
    dimension: "Dimensions : 34 x 26 x 33 cm",
    description: "Croisillon inclus",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((34 / 100) * 26) / 100) * 33) / 100,
  },
  {
    id: 6,
    nom: "Carton penderie",
    visuel: "carton-penderie",
    prix: 12.99,
    dimension: "Dimensions : 50 x 50 x 130 cm",
    description: "Structure renforcée et tringle incluse",
    provenance: "🇫🇷 100 % recyclable",
    volume: ((((50 / 100) * 50) / 100) * 130) / 100,
  },
];
