import { format } from "date-fns";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
  addMessagePresta,
  updatePrestaInfo,
  updatePrestaMiseADispo,
} from "../../../apiCalls/prestataires";
import Button from "../../../commons/Button";
import Input from "../../../commons/Input";
import styles from "../DescriptionPresta.module.scss";
export default function Info({ data, handleRemovePresta, user }) {
  const [type, setType] = useState();
  const [message, setMessage] = useState("");
  const [modify, setModify] = useState(false);
  const [modifyMiseADispo, setModifyMiseADispo] = useState();
  const [presta, setPresta] = useState({
    id: data.id,
    nom_dirigeant: data.nom_dirigeant,
    telephone: data.telephone,
    email: data.email,
    email_connexion: data.email_connexion,
    ville: data.ville,
    statut: data.statut,
    siret: data.siret,
    region: data.region,
  });
  const [miseADisposition, setMiseAdispo] = useState({
    id: data.id,
    vehicule: data.vehicule,
    monte_meuble: data.monte_meuble,
    objet_lourd: data.objet_lourd,
    effectif: data.effectif,
  });
  const handleSubmitNote = useCallback(async () => {
    const newMesage = [
      {
        titre: "titre",
        message: message,
        date: format(new Date(), "dd/MM/yyyy"),
        auteur: user,
      },
    ];
    const newData = data?.note?.map((el) => ({
      titre: el.titre,
      message: el.message,
      date: el.date,
      auteur: el.auteur,
    }));
    if (data?.note === null) {
      await addMessagePresta({ id: parseInt(data?.id), note: newMesage })
        .then(() => {
          setType("");
          window.location.reload();
        })
        .catch((err) => console.log(err));
      return;
    }
    const dataSend = newData.concat(newMesage);
    await addMessagePresta({ id: parseInt(data?.id), note: dataSend })
      .then(() => {
        setType("");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }, [data?.id, data?.note, message, user]);

  const handleModify = useCallback(() => {
    setModify(!modify);
  }, [modify]);
  const handleModifyMiseAdispo = useCallback(() => {
    setModifyMiseADispo(!modifyMiseADispo);
  }, [modifyMiseADispo]);

  const handleSubmitFirstForm = useCallback(async () => {
    await updatePrestaInfo(presta)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  }, [presta]);

  const handleSubmitMiseAdispo = useCallback(async () => {
    await updatePrestaMiseADispo(miseADisposition)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  }, [miseADisposition]);

  return (
    <>
      <div className={styles.informationGeneral}>
        <h3>Informations Générales</h3>
        <div className={styles.containerModifButton}>
          {modify ? (
            <div className={styles.buttonModifycontainer}>
              <Button
                className={styles.buttonModifycontainer2}
                type="secondary"
                text={"Annuler"}
                size="small"
                onClick={() => setModify(!modify)}
              />
              <Button
                className={styles.buttonModifycontainer1}
                type="primary"
                text={"💾"}
                size="small"
                onClick={handleSubmitFirstForm}
              />
            </div>
          ) : (
            <Button
            className={styles.buttonModifycontainer1}
              text={<span style={{ fontSize: "2rem", padding: "0.3rem" }}>⚙️</span>}
              size={"small"}
              type="modify"
              onClick={handleModify}
            />
          )}
        </div>
        <div className={styles.containerLine}>
          <div className={styles.containerColumn}>
            {modify ? (
              <Input
                placeholder="Nom du dirigeant *"
                value={presta?.nom_dirigeant}
                onChange={(e) =>
                  setPresta({ ...presta, nom_dirigeant: e.target.value })
                }
              />
            ) : (
              <p>
                <span className="orangeText weigth">Contact :</span>{" "}
                {data?.nom_dirigeant}
              </p>
            )}
            {modify ? (
              <Input
                placeholder="Telephone *"
                value={presta?.telephone}
                onChange={(e) =>
                  setPresta({ ...presta, telephone: e.target.value })
                }
              />
            ) : (
              <p>
                <span className="orangeText weigth">Téléphone :</span>{" "}
                {data?.telephone}
              </p>
            )}
            {modify ? (
              <Input
                placeholder="Email *"
                value={presta?.email}
                onChange={(e) =>
                  setPresta({ ...presta, email: e.target.value })
                }
              />
            ) : (
              <p>
                <span className="orangeText weigth">Email contact :</span>{" "}
                {data?.email}
              </p>
            )}
            {modify ? (
              <Input
                placeholder="Email de connexion *"
                value={presta?.email_connexion}
                onChange={(e) =>
                  setPresta({ ...presta, email_connexion: e.target.value })
                }
              />
            ) : (
              <p>
                <span className="orangeText weigth">Email connexion : </span>
                {data?.email_connexion}
              </p>
            )}
          </div>
          <div className={styles.containerColumn}>
            {modify ? (
              <>
                <Input
                  placeholder="Forme juridique *"
                  onChange={(e) =>
                    setPresta({ ...presta, statut: e.target.value })
                  }
                  value={presta?.statut}
                />
                <Input
                  placeholder="Siret *"
                  onChange={(e) =>
                    setPresta({ ...presta, siret: e.target.value })
                  }
                  value={presta?.siret}
                />
                <Input
                  onChange={(e) =>
                    setPresta({ ...presta, ville: e.target.value })
                  }
                  value={presta?.ville}
                />
                <Input
                  placeholder="Région *"
                  onChange={(e) =>
                    setPresta({ ...presta, region: e.target.value })
                  }
                  value={presta?.region}
                />
              </>
            ) : (
              <>
                <p>
                  <span className="orangeText weigth">Statut : </span>{" "}
                  {data?.statut}
                </p>
                <p>
                  <span className="orangeText weigth">Siret : </span>{" "}
                  {data?.siret}
                </p>
                <p>
                  <span className="orangeText weigth">Ville : </span>{" "}
                  {data?.ville}
                </p>
                <p>
                  <span className="orangeText weigth">Région :</span>{" "}
                  {data?.region}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.miseADisposition}>
        <h3>Mise à disposition</h3>
        <div className={styles.containerModifButton}>
          {modifyMiseADispo ? (
            <div className={styles.buttonModifycontainer}>
              <Button
                className={styles.buttonModifycontainer2}
                type="secondary"
                text={"Annuler"}
                size="small"
                onClick={() => setModifyMiseADispo(!modifyMiseADispo)}
              />
              <Button
                className={styles.buttonModifycontainer1}
                type="primary"
                text={"💾"}
                size="small"
                onClick={handleSubmitMiseAdispo}
              />
            </div>
          ) : (
            <Button
              className={styles.buttonModifycontainer1}
              text={<span style={{ fontSize: "2rem", padding: "0.3rem" }}>⚙️</span>}
              type="modify"
              onClick={handleModifyMiseAdispo}
            />
          )}
        </div>
        <div className={styles.containerColumn}>
          {modifyMiseADispo ? (
            <>
              <Input
                value={miseADisposition?.vehicule}
                placeholder="Vehicules *"
                onChange={(e) =>
                  setMiseAdispo({
                    ...miseADisposition,
                    vehicule: e.target.value,
                  })
                }
              />
              <Input
                placeholder="Monte-meubles *"
                value={miseADisposition?.monte_meuble}
                onChange={(e) =>
                  setMiseAdispo({
                    ...miseADisposition,
                    monte_meuble: e.target.value,
                  })
                }
              />
              <Input
                value={miseADisposition?.objet_lourd}
                placeholder="Objets lourds *"
                onChange={(e) =>
                  setMiseAdispo({
                    ...miseADisposition,
                    objet_lourd: e.target.value,
                  })
                }
              />
              <Input
                value={miseADisposition?.effectif}
                placeholder="Effectifs *"
                onChange={(e) =>
                  setMiseAdispo({
                    ...miseADisposition,
                    effectif: e.target.value,
                  })
                }
              />
            </>
          ) : (
            <>
              <p>
                <span className="orangeText weigth">Véhicules :</span>{" "}
                {data?.vehicule}
              </p>
              <p>
                <span className="orangeText weigth">Monte-meubles :</span>{" "}
                {data?.monte_meuble}
              </p>
              <p>
                <span className="orangeText weigth">Objets Lourd :</span>{" "}
                {data?.objet_lourd}
              </p>
              <p>
                <span className="orangeText weigth">Effectifs : </span>{" "}
                {data?.effectif}
              </p>
            </>
          )}
        </div>
      </div>
      <div className={styles.noteContainer}>
        <h3>Notes</h3>
        {data?.note?.length === 0 ||
          (data?.note === null && <p className={styles.noNote}>Aucune Note</p>)}
        {data?.note?.map((el, i) => (
          <div className={styles.noteLine} key={i}>
            <p>
              <span className="orangeText weigth">Notes : </span>
              {el.message}
            </p>
            <p>
              <span className="orangeText weigth">Date :</span> {el.date}
            </p>
            {/* <p>
    <span className="orangeText weigth">Auteur :</span> {el.auteur}
  </p> */}
          </div>
        ))}
        {type === "NOTE" && (
          <div className={styles.addNoteContainer}>
            <h3>Écrire une note pour {data?.raison_social}</h3>
            <textarea
              placeholder="Ajouter un message"
              className="textArea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className={styles.buttonNoteCOntainer}>
              <Button
                className={styles.buttonModifycontainer3}
                type="primary"
                text={"💾"}
                size="small"
                onClick={handleSubmitNote}
              />
            </div>
          </div>
        )}
        <div>
          {type === "NOTE" ? (
            <Button
              className={styles.buttonModifycontainer2}
              text={"Annuler"}
              onClick={() => setType("")}
              type="secondary"
              size={"small"}
            />
          ) : (
            <Button
              text={"➕ Ajouter une note"}
              onClick={() => setType("NOTE")}
              type="primary"
              size={"small"}
            />
          )}
        </div>
      </div>
      <div className={styles.deleteContainer}>
        <Button
          text={"✖️ Supprimer le prestataire"}
          onClick={handleRemovePresta}
          type="primary"
          size={"small"}
        />
      </div>
    </>
  );
}
