import React, { useEffect } from "react";
import styles from "./Modal.module.scss";
import classNames from "classnames";
export default function Modal({
  children,
  handleClose,
  noCLose = false,
  freeHeigth = false,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [freeHeigth]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.autoHeight]: freeHeigth,
      })}
    >
      <div className={styles.childrenContainer}>
        {!noCLose && (
          <button className={styles.closeBtn} onClick={handleClose}>
            X
          </button>
        )}
        {children}
      </div>
    </div>
  );
}
