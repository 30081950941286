import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getDevisPdfById = async (devisNumber) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetDevisPdfById($devisNumber: String) {
        getDevisPdfById(devis_number: $devisNumber) {
          creation_date
          devis_number
          id
          key
          link
          user_id
        }
      }
    `,
    variables: {
      devisNumber: devisNumber,
    },
  });
  return { data, errors };
};
