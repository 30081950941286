import React, { useCallback, useContext } from "react";
import { modifiedContext } from "../../../store/devisContext";
import GeneralLine from "./generalLine";

export default function InfoGeneral({ data }) {
  const countext = useContext(modifiedContext);
  const handleUpdateName = useCallback(
    (e) => {
      countext.setData((data) => ({
        ...data,
        nom: e,
      }));
    },
    [countext]
  );
  const handleUpdateTel = useCallback(
    (e) => {
      countext.setData((data) => ({
        ...data,
        telephone: e,
      }));
    },
    [countext]
  );

  return (
    <div>
      <GeneralLine
        handleUpdate={handleUpdateName}
        label={<span style={{ color: "black" }}>Nom</span>}
        value={data?.nom}
        type="textInput"
      />
      <GeneralLine
        handleUpdate={handleUpdateTel}
        label={<span style={{ color: "black" }}>Téléphone</span>}
        value={data?.telephone}
      />
    </div>
  );
}
