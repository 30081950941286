import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getTotalPresta = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllPrestataireInformation {
        getAllPrestataireInformation {
          id
        }
      }
    `,
  });
  return { data, errors };
};

export const getAllPrestataire = async () => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetAllPrestataireInformation {
        getAllPrestataireInformation {
          id
          raison_social
          rue
          code
          ville
          telephone
          email
          siret
          nom_dirigeant
          password
          verified
          first_connexion
          region
          statut
          vehicule
          objet_lourd
          monte_meuble
          effectif
          email_connexion
          see_chantier
          note {
            titre
            message
            date
            auteur
          }
          ca_total
        }
      }
    `,
  });
  return { data, errors };
};

export const addMessagePresta = async ({ id, note }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation AddNotePrestataire(
        $addNotePrestataireId: ID
        $note: [NotePrestataireTypeInput]
      ) {
        addNotePrestataire(id: $addNotePrestataireId, note: $note) {
          successful
          message
        }
      }
    `,
    variables: {
      addNotePrestataireId: id,
      note: note,
    },
  });
  return { data, errors };
};

export const updatePrestaInfo = async ({
  id,
  nom_dirigeant,
  email,
  email_connexion,
  telephone,
  ville,
  statut,
  siret,
  region,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdatePrestataireInfo(
        $updatePrestataireInfoId: ID
        $nomDirigeant: String
        $email: String
        $telephone: String
        $emailConnexion: String
        $ville: String
        $statut: String
        $siret: String
        $region: String
      ) {
        updatePrestataireInfo(
          id: $updatePrestataireInfoId
          nom_dirigeant: $nomDirigeant
          email: $email
          telephone: $telephone
          email_connexion: $emailConnexion
          ville: $ville
          statut: $statut
          siret: $siret
          region: $region
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      updatePrestataireInfoId: id,
      nomDirigeant: nom_dirigeant,
      email: email,
      telephone: telephone,
      emailConnexion: email_connexion,
      ville: ville,
      statut: statut,
      siret: siret,
      region: region,
    },
  });
  return { data, errors };
};

export const updatePrestaMiseADispo = async ({
  id,
  vehicule,
  monte_meuble,
  objet_lourd,
  effectif,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdatePrestataireMiseAdispo(
        $vehicule: String
        $updatePrestataireMiseAdispoId: ID
        $monteMeuble: String
        $objetLourd: String
        $effectif: String
      ) {
        updatePrestataireMiseAdispo(
          vehicule: $vehicule
          id: $updatePrestataireMiseAdispoId
          monte_meuble: $monteMeuble
          objet_lourd: $objetLourd
          effectif: $effectif
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      vehicule: vehicule,
      updatePrestataireMiseAdispoId: id,
      monteMeuble: monte_meuble,
      objetLourd: objet_lourd,
      effectif: effectif,
    },
  });
  return { data, errors };
};

export const deletePrestataire = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeletePrestataire($deletePrestataireId: ID) {
        deletePrestataire(id: $deletePrestataireId) {
          id
        }
      }
    `,
    variables: {
      deletePrestataireId: id,
    },
  });
  return { data, errors };
};

export const CreatePrestataire = async ({
  effectif,
  monte_meuble,
  objet_lourd,
  vehicule,
  statut,
  region,
  password,
  nom_dirigeant,
  siret,
  email,
  telephone,
  ville,
  raison_social,
  email_connexion,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DeletePrestataire(
        $raisonSocial: String
        $ville: String
        $telephone: String
        $email: String
        $siret: String
        $nomDirigeant: String
        $password: String
        $region: String
        $statut: String
        $objetLourd: String
        $monteMeuble: String
        $effectif: String
        $emailConnexion: String
        $vehicule: String
      ) {
        createNewPrestataire(
          raison_social: $raisonSocial
          ville: $ville
          telephone: $telephone
          email: $email
          siret: $siret
          nom_dirigeant: $nomDirigeant
          password: $password
          region: $region
          statut: $statut
          objet_lourd: $objetLourd
          monte_meuble: $monteMeuble
          effectif: $effectif
          email_connexion: $emailConnexion
          vehicule: $vehicule
        ) {
          raison_social
        }
      }
    `,
    variables: {
      raisonSocial: raison_social,
      ville: ville,
      telephone: telephone,
      email: email,
      siret: siret,
      nomDirigeant: nom_dirigeant,
      password: password,
      region: region,
      statut: statut,
      objetLourd: objet_lourd,
      monteMeuble: monte_meuble,
      effectif: effectif,
      emailConnexion: email_connexion,
      vehicule: vehicule,
    },
  });
  return { data, errors };
};

export const getDevisByPrestaireId = async ({ prestataire_id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetCommandeByPrestataireIdAdmin($prestataireId: Int) {
        getCommandeByPrestataireIdAdmin(prestataire_id: $prestataireId) {
          id
          unique_id
          client_id
          transaction_stripe
          prestataire_id
          listing {
            id
            nom
            piece
            sous_categorie
            hauteur
            largeur
            profondeur
            poids
            prix_m3
            supplemet
            visuel
            demontable
            from_user
            selectPiece
            prix_montage
            visible
          }
          emb_deb_data {
            maxCarton
            cartonPredict
            prixDeballage
            prixEmballage
            prixCarton
            totalCarton
            volume
            livraison
            cartonSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
            accessoireSelect {
              quantity
              object {
                id
                nom
                visuel
                prix
                dimension
                description
                provenance
                volume
              }
            }
          }
          status
          is_payed
          date_commande
          date_demenagement
          depart {
            rue
            code
            ville
          }
          arrive {
            rue
            code
            ville
          }
          option_prix {
            frais_modification
            meuble
            montage_demontage
            emballage
            deballage
            assurance
            report
            km
            majoration
            depart {
              monte_meuble
              cave
              etage
              distance_logement
            }
            arrive {
              monte_meuble
              cave
              etage
              distance_logement
            }
          }
          total_km
          surface
          demontage
          emballage
          deballage
          assurance
          report
          step
          flexible {
            fin
            debut
          }
          price
          type
          surfaceFinal
          etages
          ascenseur
          cave
          monte_meuble
          commentaires
          volume
          distance_rue
          cartons
          date_modifier
          client {
            id
            nom
            email
            telephone
            devis_id
            facture_id
            transaction_id
            type_of_payment
            step
            first_connexion
            status
            contrat_id
            surface
            date_creation
            formulaire_id {
              devis_id
              date
              status
            }
          }
        }
      }
    `,
    variables: {
      prestataireId: prestataire_id,
    },
  });
  return { data, errors };
};

export const disableChantierSelectPresta = async ({
  id,
  seeChantier,
  caTotal,
}) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation DisableChantierSelectPresta(
        $disableChantierSelectPrestaId: ID
        $caTotal: Float
        $seeChantier: [String]
      ) {
        disableChantierSelectPresta(
          id: $disableChantierSelectPrestaId
          ca_total: $caTotal
          see_chantier: $seeChantier
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      disableChantierSelectPrestaId: id,
      caTotal: caTotal,
      seeChantier: seeChantier,
    },
  });
  return { data, errors };
};
export const removeDevisPrestataire = async ({ id }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateStatusCommande(
        $updateStatusCommandeId: ID
        $prestataireId: Int
      ) {
        updateStatusCommande(
          id: $updateStatusCommandeId
          prestataire_id: $prestataireId
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      updateStatusCommandeId: id,
      prestataireId: null,
    },
  });
  return { data, errors };
};
export const getPrestaireById = async ({ prestataire_id }) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetPrestataireById($getPrestataireByIdId: ID) {
        getPrestataireById(id: $getPrestataireByIdId) {
          nom_dirigeant
          raison_social
          email
          email_connexion
        }
      }
    `,
    variables: {
      getPrestataireByIdId: prestataire_id,
    },
  });
  return { data, errors };
};
