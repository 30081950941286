import titan from "../../AppoloClient/apollo-client";
import { gql } from "@apollo/client";

export const getFerie = async () => {
  const { data } = await titan.query({
    query: gql`
      query GetFerie {
        getFerie {
          id
          slug
          title
          day
          month
        }
      }
    `,
  });
  return { data };
};

export const createFerie = async ({ title, day, month }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateFerie(
        $title: String
        $day: String
        $month: String
        $slug: String
      ) {
        createFerie(title: $title, day: $day, month: $month, slug: $slug) {
          title
          day
          month
        }
      }
    `,
    variables: {
      title: title,
      day: day,
      month: month,
      slug: title.toLowerCase().replaceAll(" ", "-"),
    },
  });
  return { data, errors };
};

export const updateFerie = async ({ id, title, day, month }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation UpdateFerie(
        $updateFerieId: ID
        $day: String
        $title: String
        $month: String
        $slug: String
      ) {
        updateFerie(
          id: $updateFerieId
          day: $day
          title: $title
          month: $month
          slug: $slug
        ) {
          successful
          message
        }
      }
    `,
    variables: {
      updateFerieId: id,
      title: title,
      day: day,
      month: month,
      slug: title.toLowerCase().replaceAll(" ", "-"),
    },
  });
  return { data, errors };
};
