import React, { useState } from "react";
import { useCallback } from "react";
import styles from "./SearchBar.module.scss";
export default function SearchBar({
  onChange,
  value,
  placeholder = "🔍 Rechercher ...",
}) {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(
    (e) => {
      setLocalValue(e.target.value);

      onChange(e.target.value.toLowerCase());
    },
    [onChange]
  );

  return (
    <div className={styles.container}>
      <input
        type="text"
        onChange={handleChange}
        placeholder={placeholder}
        value={localValue}
        className={styles.searchBar}
      />
    </div>
  );
}
