import axios from "axios";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import styles from "./RecapPaiement.module.scss";
import { getFactureId } from "../../apiCalls/facture";
export default function RecapPaiement({ handleReturn, data }) {
  const [stripeInfo, setStripeInfo] = useState();
  const [factures, setFactures] = useState([]);
  const catchDataPaiement = useCallback(async () => {
    await axios
      .get(
        `https://www.dev-server.titan-demenagement.com/api/paiement/transaction?transaction=${data.transaction_stripe}`
      )
      .then((res) => setStripeInfo(res.data))
      .catch((err) => console.log(err));
  }, [data.transaction_stripe]);

  const catchtFacture = useCallback(async () => {
    await getFactureId(data.unique_id).then((res) =>
      setFactures(res.data.getFactureId)
    );
  }, [data.unique_id]);

  useEffect(() => {
    catchtFacture();
    if (data?.transaction_stripe) {
      catchDataPaiement();
    }
  }, [catchDataPaiement, catchtFacture, data?.transaction_stripe]);

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date +
      " " +
      month +
      " " +
      year +
      " " +
      " à " +
      hour +
      " h " +
      min +
      " min " +
      sec +
      " sec";
    return time;
  }

  if (stripeInfo === undefined) {
    return (
      <div className={styles.container}>
        <p className="weigth orangeText">Aucun paiement</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerButton}></div>
      <div className={styles.containerLine}>
        <p>
          <span className="weigth orangeText">Date de la transaction</span> :{" "}
          {timeConverter(stripeInfo?.created)}
        </p>
        <p>
          <span className="weigth orangeText">Numéro de transaction </span> :{" "}
          {data.transaction_stripe}
        </p>
        <p>
          <span className="weigth orangeText">Moyen de paiement</span> :{" "}
          {stripeInfo?.payment_method_types[0] === "card"
            ? "Carte Bancaire"
            : stripeInfo?.payment_method_types[0]}
        </p>
        <p>
          <span className="weigth orangeText">Montant</span> :{" "}
          {stripeInfo?.amount / 100} €
        </p>
        <p>
          <span className="weigth orangeText">Statut du paiement</span> :{" "}
          {stripeInfo?.status === "succeeded" ? "Succès" : "Non Abouti"}
        </p>
        {factures.map((el) => (
          <a
            className={styles.btnDonwload}
            target="_blanck"
            href={`https://facture-titan.s3.eu-west-3.amazonaws.com/facture-${el.file}`}
          >
            {el.file[0] === "r" && el.file[1] === "m"
              ? "Facture Révisée"
              : "Facture"}
          </a>
        ))}
        {/* <a
          className={styles.btnDonwload}
          target="_blanck"
          href={`https://facture-titan.s3.eu-west-3.amazonaws.com/facture-${data.unique_id}.pdf`}
        >
          Facture
        </a> */}
      </div>
    </div>
  );
}
