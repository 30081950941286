export const calculEtage = (e, price, bigWeight) => {
  let prix = price;
  if (bigWeight !== 0 || bigWeight === true) {
    prix = price * 2;
  }
  return e * prix;
};

export const weightPriceEtage = (e, price, bigWeight) => {
  if (bigWeight !== 0 || bigWeight === true) {
    return e > 0 ? calculEtage(parseInt(e), price, bigWeight) : 0;
  }
  return e > 2 ? calculEtage(parseInt(e - 2), price, bigWeight) : 0;
};

export const differenceOriginNewPriceEtage = (
  e,
  price,
  bigWeight,
  majoration,
  originPrice,
  originEtage
) => {
  if (originEtage === parseInt(e)) {
    return weightPriceEtage(parseInt(e), price, bigWeight) * majoration;
  }
  let differenceBetweenFloor = parseInt(e) - originEtage;
  if (originEtage < parseInt(e)) {
    differenceBetweenFloor = parseInt(e) - originEtage;
    return price * differenceBetweenFloor * majoration + originPrice;
  }
  if (originEtage > parseInt(e)) {
    differenceBetweenFloor = -differenceBetweenFloor;
    return weightPriceEtage(parseInt(e), price, bigWeight) * majoration;
  }
  return 0;
};
