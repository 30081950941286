const trueOrFalse = ["Non", "Oui"];

export const trueOrFlase = [
  {
    value: 0,
    label: "Non",
  },
  {
    value: 1,
    label: "Oui",
  },
];
export default trueOrFalse;
