import React, { useCallback, useContext, useState } from "react";
import Button from "../../../../commons/Button";
import Select from "../../../../commons/Select";
import { modifiedContext } from "../../../../store/devisContext";
import styles from "../InformationLogement.module.scss";
import {
  differenceOriginNewPriceEtage,
  weightPriceEtage,
} from "../../../../utils/Etages";
const trueOrFlase = [
  {
    value: 0,
    label: "Non",
  },
  {
    value: 1,
    label: "Oui",
  },
];
const distances = [
  {
    label: "0 à 30 m",
    value: 0,
  },
  {
    label: "30 à 50 m",
    value: 1,
  },
  {
    label: "50 à 100 m",
    value: 2,
  },
];
const typeOfLeave = [
  {
    label: "Maison",
    value: 0,
  },
  {
    label: "Appartement",
    value: 1,
  },
  {
    label: "Garde Meuble",
    value: 2,
  },
];

const totalEtage = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
];

export default function Form({ data, i, prices }) {
  const [modifiedDepart, setModifiedDepart] = useState(false);
  const countext = useContext(modifiedContext);
  const { setDifference, activeMajoration, orignalDevis, step } =
    useContext(modifiedContext);

  const [valueDepart, setValueDepart] = useState({
    type: typeOfLeave[data?.type?.[i]]?.value,
    etages: data?.etages?.[i],
    distances: distances[data?.distance_rue?.[i]]?.value,
    ascenseur: data?.ascenseur?.[i] ? 1 : 0,
    cave: data?.cave?.[i] ? 1 : 0,
    monte_meuble: data?.monte_meuble?.[i],
  });

  const calculPriceDistance = useCallback(
    (e) => {
      if (countext?.data?.option_prix?.[0]?.bigWeight !== 0) {
        return e === 0 ? 0 : e === 1 ? 150 : e === 2 ? 300 : 0;
      } else {
        return e === 0
          ? 0
          : e === 1
          ? prices[3].prix * 1
          : e === 2
          ? prices[3].prix * 2
          : 0;
      }
    },
    [countext?.data?.option_prix, prices]
  );

  const handleSave = useCallback(() => {
    // ADRESSE DE DEPART
    if (i === 0) {
      const myOptionPrice = {
        majoration: countext?.data?.option_prix?.[0]?.majoration,
        meuble: countext?.data?.option_prix?.[0]?.meuble,
        montage_demontage: countext?.data?.option_prix?.[0]?.montage_demontage,
        emballage: countext?.data?.option_prix?.[0]?.emballage,
        deballage: countext?.data?.option_prix?.[0]?.deballage,
        assurance: countext?.data?.option_prix?.[0]?.assurance,
        carton: countext?.data?.option_prix?.[0]?.carton,
        livraison: countext?.data?.option_prix?.[0]?.livraison,
        report: countext?.data?.option_prix?.[0]?.report,
        bigWeight: countext?.data?.option_prix?.[0]?.bigWeight,
        cartonPredict: countext?.data?.option_prix?.[0]?.cartonPredict,
        km: countext?.data?.option_prix?.[0]?.km,
        frais_dossier: countext?.data?.option_prix?.[0]?.frais_dossier,
        frais_modification:
          countext?.data?.option_prix?.[0]?.frais_modification,
        depart: {
          monte_meuble:
            parseInt(valueDepart.monte_meuble) === 1 ? prices[0].prix : 0,
          cave: valueDepart.cave === 1 ? prices[1].prix : 0,
          etage: weightPriceEtage(
            valueDepart.etages,
            prices[2].prix,
            countext?.data?.option_prix?.[0]?.bigWeight
          ),
          distance_logement: calculPriceDistance(valueDepart.distances),
        },
        arrive: {
          monte_meuble: countext?.data?.option_prix?.[0]?.arrive.monte_meuble,
          cave: countext?.data?.option_prix?.[0]?.arrive.cave,
          etage: countext?.data?.option_prix?.[0]?.arrive.etage,
          distance_logement:
            countext?.data?.option_prix?.[0]?.arrive.distance_logement,
        },
      };
      countext.setData((data) => ({
        ...data,
        option_prix: [myOptionPrice],
        etages: [valueDepart.etages, data.etages[1]],
        monte_meuble: [
          valueDepart.monte_meuble.toString(),
          data.monte_meuble[1],
        ],
        distance_rue: [valueDepart.distances, data.distance_rue[1]],
        cave: [valueDepart.cave === 1 ? true : false, data.cave[1]],
        ascenseur: [
          valueDepart.ascenseur === 1 ? true : false,
          data.ascenseur[1],
        ],
        type: [valueDepart.type, data.type[1]],
      }));
      setModifiedDepart(false);
      return;
    }
    const myOptionPrice = {
      majoration: countext?.data?.option_prix?.[0]?.majoration,
      meuble: countext?.data?.option_prix?.[0]?.meuble,
      montage_demontage: countext?.data?.option_prix?.[0]?.montage_demontage,
      emballage: countext?.data?.option_prix?.[0]?.emballage,
      deballage: countext?.data?.option_prix?.[0]?.deballage,
      assurance: countext?.data?.option_prix?.[0]?.assurance,
      carton: countext?.data?.option_prix?.[0]?.carton,
      livraison: countext?.data?.option_prix?.[0]?.livraison,
      report: countext?.data?.option_prix?.[0]?.report,
      bigWeight: countext?.data?.option_prix?.[0]?.bigWeight,
      cartonPredict: countext?.data?.option_prix?.[0]?.cartonPredict,
      km: countext?.data?.option_prix?.[0]?.km,
      frais_dossier: countext?.data?.option_prix?.[0]?.frais_dossier,
      frais_modification: countext?.data?.option_prix?.[0]?.frais_modification,
      depart: {
        monte_meuble: countext?.data?.option_prix?.[0]?.depart.monte_meuble,
        cave: countext?.data?.option_prix?.[0]?.depart.cave,
        etage: countext?.data?.option_prix?.[0]?.depart.etage,
        distance_logement:
          countext?.data?.option_prix?.[0]?.depart.distance_logement,
      },
      arrive: {
        monte_meuble:
          parseInt(valueDepart.monte_meuble) === 1 ? prices[0].prix : 0,
        cave: valueDepart.cave === 1 ? prices[1].prix : 0,
        etage: weightPriceEtage(
          valueDepart.etages,
          prices[2].prix,
          countext?.data?.option_prix?.[0]?.bigWeight
        ),
        distance_logement: calculPriceDistance(valueDepart.distances),
      },
    };
    countext.setData((data) => ({
      ...data,
      option_prix: [myOptionPrice],
      etages: [data.etages[0], valueDepart.etages],
      monte_meuble: [data.monte_meuble[0], valueDepart.monte_meuble.toString()],
      distance_rue: [data.distance_rue[0], valueDepart.distances],
      cave: [data.cave[0], valueDepart.cave === 1 ? true : false],
      ascenseur: [
        data.ascenseur[0],
        valueDepart.ascenseur === 1 ? true : false,
      ],
      type: [data.type[0], valueDepart.type],
    }));
    setModifiedDepart(false);
  }, [
    calculPriceDistance,
    countext,
    i,
    prices,
    valueDepart.ascenseur,
    valueDepart.cave,
    valueDepart.distances,
    valueDepart.etages,
    valueDepart.monte_meuble,
    valueDepart.type,
  ]);

  return (
    <div className={styles.left}>
      <div className={styles.titleContainer}>
        <p>Adresse {i === 0 ? "de départ" : "d'arrivé"} </p>
        {modifiedDepart ? (
          <div className={styles.inlineButton}>
            <button
              className="btnModify"
              onClick={() => setModifiedDepart(false)}
            >
              X
            </button>
            <Button
              className={styles.buttondisquette}
              type="primary"
              size={"small"}
              text={"💾"}
              onClick={handleSave}
            />
          </div>
        ) : (
          <button className="btnModify" onClick={() => setModifiedDepart(true)}>
            🪛
          </button>
        )}
      </div>
      {modifiedDepart ? (
        <>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>
              Type de bien :{" "}
            </p>
            <Select
              data={typeOfLeave}
              value={valueDepart?.type}
              onChange={(e) =>
                setValueDepart({ ...valueDepart, type: parseInt(e) })
              }
            />
          </div>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>Étage :</p>
            <Select
              data={totalEtage}
              value={valueDepart?.etages}
              onChange={(e) => {
                if (step === 3 && parseInt(e) < orignalDevis?.etages[i]) {
                  alert("Retourner à l'etape 1 pour ce changement");
                  return;
                }
                if (i === 0) {
                  setDifference((difference) => ({
                    ...difference,
                    etage_depart: {
                      modified: true,
                      value: parseInt(e),
                      price: differenceOriginNewPriceEtage(
                        parseInt(e),
                        prices[2].prix,
                        countext?.data?.option_prix?.[0]?.bigWeight,
                        activeMajoration,
                        orignalDevis?.option_prix?.[0]?.depart?.etage,
                        orignalDevis?.etages[0]
                      ),
                    },
                  }));
                } else {
                  setDifference((difference) => ({
                    ...difference,
                    etage_arrive: {
                      modified: true,
                      value: parseInt(e),
                      price: differenceOriginNewPriceEtage(
                        parseInt(e),
                        prices[2].prix,
                        countext?.data?.option_prix?.[0]?.bigWeight,
                        activeMajoration,
                        orignalDevis?.option_prix?.[0]?.arrive?.etage,
                        orignalDevis?.etages[1]
                      ),
                    },
                  }));
                }
                setValueDepart({ ...valueDepart, etages: parseInt(e) });
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>
              Distance Logement rue :
            </p>
            <Select
              data={distances}
              value={valueDepart?.distances}
              onChange={(e) => {
                if (i === 0) {
                  setDifference((difference) => ({
                    ...difference,
                    distance_logement_depart: {
                      modified: true,
                      value: parseInt(e),
                      price:
                        calculPriceDistance(parseInt(e)) * activeMajoration,
                    },
                  }));
                } else {
                  setDifference((difference) => ({
                    ...difference,
                    distance_logement_arrive: {
                      modified: true,
                      value: parseInt(e),
                      price:
                        calculPriceDistance(parseInt(e)) * activeMajoration,
                    },
                  }));
                }
                setValueDepart({
                  ...valueDepart,
                  distances: parseInt(e),
                });
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>Ascenseur :</p>
            <Select
              data={trueOrFlase}
              value={valueDepart?.ascenseur ? 1 : 0}
              onChange={(e) => {
                setValueDepart({ ...valueDepart, ascenseur: parseInt(e) });
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>Cave :</p>
            <Select
              data={trueOrFlase}
              value={valueDepart?.cave ? 1 : 0}
              onChange={(e) => {
                const res = parseInt(e);
                if (i === 0) {
                  setDifference((difference) => ({
                    ...difference,
                    cave_depart: {
                      modified: true,
                      value: res,
                      price: res === 0 ? 0 : prices[1].prix * activeMajoration,
                    },
                  }));
                } else {
                  setDifference((difference) => ({
                    ...difference,
                    cave_arrive: {
                      modified: true,
                      value: res,
                      price: res === 0 ? 0 : prices[1].prix * activeMajoration,
                    },
                  }));
                }
                setValueDepart({ ...valueDepart, cave: res });
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <p style={{ color: "black", fontWeight: "bold" }}>
              Monte-meubles :
            </p>
            <Select
              data={trueOrFlase}
              value={parseInt(valueDepart?.monte_meuble)}
              onChange={(e) => {
                const res = parseInt(e);
                if (i === 0) {
                  setDifference((difference) => ({
                    ...difference,
                    monte_meuble_depart: {
                      modified: true,
                      value: res,
                      price: res === 0 ? 0 : prices[0].prix * activeMajoration,
                    },
                  }));
                } else {
                  setDifference((difference) => ({
                    ...difference,
                    monte_meuble_arrive: {
                      modified: true,
                      value: res,
                      price: res === 0 ? 0 : prices[0].prix * activeMajoration,
                    },
                  }));
                }
                setValueDepart({ ...valueDepart, monte_meuble: parseInt(e) });
              }}
            />
          </div>
          <div className={styles.formGroupcommentaire}>
            <p style={{ color: "black", fontWeight: "bold" }}>Commentaire :</p>
            <br />
            <p className={styles.commentaire}>DD</p>
          </div>
        </>
      ) : (
        <>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Type de bien :</span>{" "}
              <span className="valuemodif">
                {typeOfLeave[data?.type?.[i]]?.label}
              </span>
            </p>
          </div>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Étage :</span>{" "}
              <span className="valuemodif">
                {data?.step > 7 && data?.etages?.[i] === 0
                  ? "Rdc"
                  : data?.etages?.[i]}
              </span>
            </p>
          </div>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Distance Logement rue :</span>{" "}
              <span className="valuemodif">
                {" "}
                {distances[data?.distance_rue?.[i]]?.label}
              </span>
            </p>
          </div>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Ascenseur :</span>{" "}
              <span className="valuemodif">
                {data?.step >= 7 ? (data?.ascenseur?.[i] ? "Oui" : "Non") : ""}
              </span>
            </p>
          </div>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Cave :</span>{" "}
              <span className="valuemodif">
                {data?.step >= 7 ? (data?.cave?.[i] ? "Oui" : "Non") : ""}
              </span>
            </p>
          </div>
          <div className={styles.formGroup}>
            <p>
              <span style={{ color: "black" }}>Monte-meubles :</span>{" "}
              <span className="valuemodif">
                {data?.step >= 7
                  ? parseInt(data?.monte_meuble?.[i]) === 1
                    ? "Oui"
                    : "Non"
                  : ""}
              </span>
            </p>
          </div>
        </>
      )}
    </div>
  );
}
