import React, { useCallback, useState } from "react";
import Button from "../../../commons/Button";
import { totalQuantity } from "../../../utils/duplicate";
import styles from "./Listing.module.scss";

import LineArticle from "./lineArticle";
import AddMeuble from "./AddMeuble";
import { useContext } from "react";
import { modifiedContext } from "../../../store/devisContext";
import { printUniqueObjectPerPiecelisting } from "../../../utils/unique";
import calculVolumeUserSelection from "../../../utils/VolumeCalcul";
import { priceM3 } from "../../../utils/price";

const typePieces = [
  {
    label: "Salon / Salle à manger",
    value: 1,
  },
  {
    label: "Cuisine",
    value: 2,
  },
  {
    label: "Chambre",
    value: 3,
  },
  {
    label: "Salle de bain",
    value: 4,
  },
  {
    label: "Garage et Jardin",
    value: 5,
  },
  {
    label: "Divers",
    value: 6,
  },
];

export default function Listing({ data, dataDemontage, allMeuble, allPrice }) {
  const [addMeuble, setAddMeuble] = useState(false);
  const [meubleToAdd, setMeubleToAdd] = useState([]);
  const countext = useContext(modifiedContext);
  const { activeMajoration } = useContext(modifiedContext);

  const fixQuantity = useCallback((id, element) => {
    return totalQuantity(id, element) ?? 0;
  }, []);

  const printOccurency = useCallback(
    (id) => {
      return data.reduce((count, objet) => {
        if (objet.id === id) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    [data]
  );

  const handleAddMeuble = useCallback(
    (data) => {
      let oldValue = [...meubleToAdd];
      oldValue.push(data);
      setMeubleToAdd(oldValue);
    },
    [meubleToAdd]
  );

  const handleRemove = useCallback(
    (data) => {
      let oldValue = [...meubleToAdd];
      const index = meubleToAdd.indexOf(data);
      oldValue.splice(index, 1);
      setMeubleToAdd(oldValue);
    },
    [meubleToAdd]
  );

  const handleSaveMyAdd = useCallback(() => {
    let ob = [...data];
    let saveOb = [...countext?.difference?.meuble?.add];
    const contat = ob.concat(meubleToAdd);
    let memoryModification = meubleToAdd.map((el) => ({
      nom: el.nom,
      price:
        el.poids === "MORE80"
          ? parseFloat(
              ((200 + priceM3(el, el.prix_m3)) * activeMajoration).toFixed(2)
            )
          : parseFloat((priceM3(el, el.prix_m3) * activeMajoration).toFixed(2)),
    }));

    const fusionOBSave = saveOb.concat(memoryModification);

    countext.setDifference((difference) => ({
      ...difference,
      meuble: {
        add: fusionOBSave,
        remove: difference?.meuble?.remove,
      },
    }));
    countext.setData((data) => ({
      ...data,
      listing: contat,
    }));
    countext.setData((data) => ({
      ...data,
      volume: calculVolumeUserSelection(contat),
    }));

    setMeubleToAdd([]);
    setAddMeuble(false);
  }, [activeMajoration, countext, data, meubleToAdd]);

  const demontable = useCallback(
    (id) => {
      const test = dataDemontage?.filter((e) => e === id);

      return test.length;
    },
    [dataDemontage]
  );

  const printSelector = useCallback(
    (piece) => {
      if (data === undefined) {
        return;
      }
      const objectPiece = data?.filter((el) => el.selectPiece === piece);
      const filterDuplicate = printUniqueObjectPerPiecelisting(objectPiece);
      return filterDuplicate?.map((element, i) => (
        <LineArticle
          pricing={allPrice}
          el={element}
          globalQuantity={printOccurency(element?.id)}
          key={`${element?.id}-${i}`}
          nom={element?.nom}
          demontages={dataDemontage?.includes(parseInt(element.id))}
          quantity={fixQuantity(element?.id, objectPiece)}
          selectQUantity={demontable(parseInt(element?.id))}
          weightPrice={
            allPrice?.filter((el) => el.label === "objet_lourd")?.[0]?.prix
          }
        />
      ));
    },
    [allPrice, data, dataDemontage, demontable, fixQuantity, printOccurency]
  );

  return (
    <div>
      <div className={styles.header}>
        {!addMeuble && (
          <Button
            text={"➕ Ajouter"}
            type="primary"
            size={"small"}
            onClick={() => setAddMeuble(!addMeuble)}
          />
        )}
        {addMeuble && (
          <div className={styles.inlineButton}>
            <button className="btnModify" onClick={() => setAddMeuble(false)}>
              X
            </button>

            <Button
              className={styles.buttondisquette}
              text={"💾"}
              type="primary"
              size={"small"}
              onClick={handleSaveMyAdd}
            />
          </div>
        )}
      </div>
      {!addMeuble &&
        typePieces.map((el, i) => (
          <div key={el.label} className={styles.mainPiece}>
            <div className={styles.headerPiece}>
              <p>{el.label}</p>
            </div>
            {printSelector(el.value)}
          </div>
        ))}
      {addMeuble && (
        <AddMeuble
          weightPrice={
            allPrice?.filter((el) => el.label === "objet_lourd")?.[0]?.prix
          }
          allMeuble={allMeuble}
          handleAddMeuble={handleAddMeuble}
          handleRemove={handleRemove}
          pricing={allPrice}
        />
      )}
    </div>
  );
}
