import { format } from "date-fns";
import React, { useState } from "react";
import { useCallback } from "react";
import { addMessageClient } from "../../apiCalls/clients";
import Button from "../../commons/Button";
import { useGlobalContext } from "../../store/globalContext";
import styles from "./Note.module.scss";
export default function Note({ data, clientId, reload }) {
  const { userInformation } = useGlobalContext();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");

  const handleSubmit = useCallback(() => {
    setAlert("");
    if (message !== "") {
      const newMesage = [
        {
          titre: "titre",
          message: message,
          date: format(new Date(), "dd/MM/yyyy"),
          auteur: userInformation.utilisateur,
        },
      ];
      if (data !== null) {
        const newData = data.map((el) => ({
          titre: el.titre,
          message: el.message,
          date: el.date,
          auteur: el.auteur,
        }));
        const dataSend = newData.concat(newMesage);
        addMessageClient({ id: parseInt(clientId), message: dataSend })
          .then(() => {
            setAlert("Commentaire Ajouter");
            setMessage("");
            window.location.reload();
          })
          .catch((err) => console.log(err));
        return;
      }
      const dataSend = newMesage;
      addMessageClient({ id: parseInt(clientId), message: dataSend })
        .then(() => {
          setAlert("Commentaire Ajouter");
          setMessage("");
          window.location.reload();
        })
        .catch((err) => console.log(err));
      return;
    }
    setAlert("Vous n'avez pas ajouté de message !");
  }, [clientId, data, message, userInformation.utilisateur]);

  return (
    <div className={styles.container}>
      <h2>Notes</h2>
      {data?.length > 0 ? (
        <div className={styles.oldMessage}>
          {data?.map((el, i) => (
            <div className={styles.lineMessage} key={i}>
              <div className={styles.dateContainer}>
                <span className={styles.dateInfo}>{el.date} :</span>
              </div>
              <div className={styles.messageContainer}>
                <p>{el.message}</p>
              </div>
              <div className={styles.autheurContainer}>
                <p>{el.auteur}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.oldMessage}>
          <p>Aucun Message </p>
        </div>
      )}
      <div className={styles.writeMessage}>
        <textarea
          placeholder="Ajouter un message"
          className="textArea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className={styles.buttonContainer}>
        {alert && <p className={styles.error}>{alert}</p>}
        <Button
          text={" ➕ Ajouter"}
          onClick={handleSubmit}
          type="primary"
          size="small"
        />
      </div>
    </div>
  );
}
