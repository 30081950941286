import React, { useCallback, useState } from "react";
import styles from "./CustomDropDown.module.scss";
import { ClickAwayListener } from "@mui/material";
import classNames from "classnames";
export default function CustomDropDown({
  label,
  children,
  onClick,
  totalObject,
  withoutTotal = false,
}) {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClick();
  }, [onClick]);
  const handleManage = useCallback(() => {
    setOpen(!open);
    onClick();
  }, [onClick, open]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={styles.container}>
        <div
          className={`${styles.elementTop} ${open && styles.elementTopActive}`}
          onClick={handleManage}
        >
          {!withoutTotal && (
            <p className={styles.label}>
              {label} ({totalObject})
            </p>
          )}
          {withoutTotal && <p className={styles.label}>{label}</p>}
          {/* <DropDown className={`${styles.icon} ${open && styles.rotateIcon}`} /> */}
          <svg
            width="25"
            height="25"
            viewBox="0 0 183 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M166.64 0.238281L92.1599 76.3983L16.558 1.92128L0.87793 18.1593L92.7179 108.319L182.878 15.9213L166.64 0.238281Z"
              fill="#fe5900"
            />
          </svg>
        </div>
        {open && (
          <div
            className={classNames(styles.elementBottom, {
              [styles.noPadding]: withoutTotal,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
