import { gql } from "@apollo/client";
import titan from "../../AppoloClient/apollo-client";

export const getFactureId = async (key) => {
  const { data, errors } = await titan.query({
    query: gql`
      query GetFactureId($key: String) {
        getFactureId(key: $key) {
          id
          index
          key
          user_id
          file
        }
      }
    `,
    variables: {
      key: key,
    },
  });
  return { data, errors };
};

export const createFactureRm = async ({ id, file, key, index }) => {
  const { data, errors } = await titan.mutate({
    mutation: gql`
      mutation CreateFactureRm(
        $userId: String
        $key: String
        $file: String
        $index: Int
      ) {
        createFactureRm(
          user_id: $userId
          key: $key
          file: $file
          index: $index
        ) {
          message
          successful
        }
      }
    `,
    variables: {
      userId: id,
      key: key,
      file: file,
      index: index,
    },
  });
  return { data, errors };
};
