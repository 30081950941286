import Modal from "../../../../commons/Modal";
import React, { useCallback } from "react";
import { useState } from "react";
import Button from "../../../../commons/Button";
import SearchBar from "../../../../commons/SearchBar/SearchBar";
import styles from "./AddMeuble.module.scss";
import Select from "../../../../commons/Select";
import { useContext } from "react";
import { modifiedContext } from "../../../../store/devisContext";

import { poidsModelEnum } from "../../../../utils/ENUM/poids";
import { priceM3 } from "../../../../utils/price";
import Input from "../../../../commons/Input";
import { createObjectModif } from "../../../../apiCalls/objets";
import {
  differenceOriginNewPriceEtage,
  weightPriceEtage,
} from "../../../../utils/Etages";
import {
  calculPriceDistance,
  calculPriceDistanceDifference,
} from "../../../../utils/DistanceLogementRue";

const typePieces = [
  {
    label: "Salon / Salle à manger",
    value: 1,
  },
  {
    label: "Cuisine",
    value: 2,
  },
  {
    label: "Chambre",
    value: 3,
  },
  {
    label: "Salle de bain",
    value: 4,
  },
  {
    label: "Garage et Jardin",
    value: 5,
  },
  {
    label: "Divers",
    value: 6,
  },
];
const trueOrFlase = [
  {
    value: 0,
    label: "Non",
  },
  {
    value: 1,
    label: "Oui",
  },
];
export default function AddMeuble({
  allMeuble,
  handleAddMeuble,
  weightPrice,
  handleRemove,
  pricing,
}) {
  const [searchValue, setSearchValue] = useState();
  const countext = useContext(modifiedContext);
  const { activeMajoration, orignalDevis } = useContext(modifiedContext);
  const [modal, setModal] = useState(false);
  const [activeMeuble, setActiveMeuble] = useState();
  const [pieceMeuble, setPieceMeuble] = useState();
  const [demontable, setDemontable] = useState("0");
  const [finalData, setFinalData] = useState([]);
  const [openModalCreation, setOpenModalCreation] = useState(false);
  const [prixM3, setPrixM3] = useState();
  const handleChange = useCallback((e) => {
    setSearchValue(e);
  }, []);

  const hanldeAdd = useCallback((data) => {
    setActiveMeuble(data);
    setModal(true);
  }, []);

  const handleValidateMeuble = useCallback(
    (createData) => {
      const final = [...finalData];
      let element = {
        demontable: activeMeuble?.demontable,
        hauteur: activeMeuble?.hauteur,
        id: activeMeuble?.id,
        largeur: activeMeuble?.largeur,
        nom: activeMeuble?.nom,
        piece: activeMeuble?.piece,
        poids: activeMeuble?.poids,
        prix_m3: activeMeuble?.prix_m3,
        profondeur: activeMeuble?.profondeur,
        sous_categorie: activeMeuble?.sous_categorie,
        supplemet: activeMeuble?.supplemet,
        visuel: activeMeuble?.visuel,
        selectPiece: parseInt(pieceMeuble),
        check: demontable === 1 ? true : false,
        prix_montage: activeMeuble?.prix_montage,
        visible: true,
      };
      if (createData) {
        element = createData;
      }
      if (demontable === 1) {
        let oldDemontage = [...countext?.data?.demontage];
        oldDemontage.push(parseInt(element?.id));
        countext?.setData((data) => ({
          ...data,
          demontage: oldDemontage,
        }));
        countext?.setData((data) => ({
          ...data,
          option_prix: [
            {
              ...data.option_prix[0],
              montage_demontage:
                countext?.data?.option_prix[0]?.montage_demontage +
                element?.prix_montage,
            },
          ],
        }));
        let saveOb = [...countext?.difference?.meuble?.add];

        const memoryModification = {
          nom: element.nom,
          prix: element?.prix_montage * activeMajoration,
        };

        const fusionOBSave = saveOb.concat(memoryModification);
        countext.setDifference((difference) => ({
          ...difference,
          demontage_remontage: {
            add: fusionOBSave,
            remove: difference?.demontage_remontage?.remove,
          },
        }));
      }

      if (
        element.poids === poidsModelEnum.MORE80.value ||
        element.poids === poidsModelEnum.MORE80.enum
      ) {
        countext.setDifference((difference) => ({
          ...difference,
          etage_depart: {
            modified: true,
            value:
              countext?.difference?.etage_depart?.value ??
              countext?.data?.etages[0],
            price: differenceOriginNewPriceEtage(
              countext?.data?.etages[0],
              pricing?.[10].prix,
              true,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.depart?.etage,
              orignalDevis?.etages[0]
            ),
          },
          etage_arrive: {
            modified: true,
            value:
              countext?.difference?.etage_arrive?.value ??
              countext?.data?.etages[1],
            price: differenceOriginNewPriceEtage(
              countext?.data?.etages[1],
              pricing?.[10].prix,
              true,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.arrive?.etage,
              orignalDevis?.etages[1]
            ),
          },
          distance_logement_depart: {
            modified: true,
            value:
              countext?.difference?.distance_logement_depart?.value ??
              countext?.data?.distance_rue[0],
            price: calculPriceDistanceDifference(
              countext?.data?.distance_rue[0],
              true,
              pricing[11].prix,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.depart?.distance_logement,
              orignalDevis?.distance_rue[0]
            ),
          },
          distance_logement_arrive: {
            modified: true,
            value:
              countext?.difference?.distance_logement_arrive?.value ??
              countext?.data?.distance_rue[1],
            price: calculPriceDistanceDifference(
              countext?.data?.distance_rue[1],
              true,
              pricing[11].prix,
              activeMajoration,
              orignalDevis?.option_prix?.[0]?.arrive?.distance_logement,
              orignalDevis?.distance_rue[1]
            ),
          },
        }));
        countext?.setData((data) => ({
          ...data,
          option_prix: [
            {
              ...data.option_prix[0],
              meuble:
                countext?.data?.option_prix[0]?.meuble +
                priceM3(element, element?.prix_m3),
              bigWeight:
                countext?.data?.option_prix[0]?.bigWeight + weightPrice,
              depart: {
                ...data.option_prix[0].depart,
                etage: weightPriceEtage(
                  countext?.data?.etages[0],
                  pricing?.[10].prix,
                  true
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[0],
                  true,
                  pricing[11].prix
                ),
              },
              arrive: {
                ...data.option_prix[0].arrive,
                etage: weightPriceEtage(
                  countext?.data?.etages[1],
                  pricing?.[10].prix,
                  true
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[1],
                  true,
                  pricing[11].prix
                ),
              },
            },
          ],
        }));
      }

      final.push(element);
      setFinalData(final);
      handleAddMeuble(element);
      setModal(false);
    },
    [
      finalData,
      activeMeuble?.demontable,
      activeMeuble?.hauteur,
      activeMeuble?.id,
      activeMeuble?.largeur,
      activeMeuble?.nom,
      activeMeuble?.piece,
      activeMeuble?.poids,
      activeMeuble?.prix_m3,
      activeMeuble?.profondeur,
      activeMeuble?.sous_categorie,
      activeMeuble?.supplemet,
      activeMeuble?.visuel,
      activeMeuble?.prix_montage,
      pieceMeuble,
      demontable,
      countext,
      handleAddMeuble,
      activeMajoration,
      pricing,
      orignalDevis?.option_prix,
      orignalDevis?.etages,
      orignalDevis?.distance_rue,
      weightPrice,
    ]
  );
  const addNewObject = useCallback(
    async (data) => {
      let sendData = {
        nom: data.nom,
        piece: data?.piece,
        sousCategorie: data?.piece,
        hauteur: data.hauteur,
        largeur: data.largeur,
        profondeur: data.profondeur,
        prix_m3: data.prix_m3,
        visuel: data.visuel,
        supplemet: data.supplemet,
        demontable: data.demontable,
        poids: data.poids,
        visible: false,
        prix_montage: data.prix_montage,
      };
      await createObjectModif(sendData)
        .then((res) => {
          setDemontable("0");
          setPieceMeuble();
          setActiveMeuble();
          setOpenModalCreation(false);
          data.from_user = true;
          data.id = res.data.createObjectAdminModify.id;
          handleValidateMeuble(data);
        })
        .catch((err) => console.log(err));
    },
    [handleValidateMeuble]
  );

  const handleCreateNewObject = useCallback(() => {
    const model = {
      demontable: demontable === 1 ? true : false,
      hauteur: parseInt(activeMeuble?.hauteur),
      largeur: parseInt(activeMeuble?.largeur),
      nom: activeMeuble?.name,
      piece: parseInt(pieceMeuble),
      poids: activeMeuble?.objectLourd === "1" ? "MORE80" : "LESS80",
      prix_m3: 41,
      prix_montage: parseFloat(activeMeuble?.prix_montage),
      profondeur: parseInt(activeMeuble?.profondeur),
      selectPiece: parseInt(pieceMeuble),
      sous_categorie: null,
      supplemet: 0,
      visible: false,
      visuel: "ajout-meuble-picto",
    };
    addNewObject(model);
  }, [
    activeMeuble?.hauteur,
    activeMeuble?.largeur,
    activeMeuble?.name,
    activeMeuble?.objectLourd,
    activeMeuble?.prix_montage,
    activeMeuble?.profondeur,
    addNewObject,
    demontable,
    pieceMeuble,
  ]);

  const handleRemoveObject = useCallback(
    (index, element) => {
      let oldArray = [...finalData];
      oldArray.splice(index, 1);
      let oldDemontage = [...countext?.data?.demontage];

      if (oldDemontage.includes(parseInt(element.id))) {
        const index = oldDemontage.indexOf(parseInt(element.id));
        oldDemontage.splice(index, 1);

        countext?.setData((data) => ({
          ...data,
          demontage: oldDemontage,
        }));
        countext?.setData((data) => ({
          ...data,
          option_prix: [
            {
              ...data.option_prix[0],
              montage_demontage:
                data?.option_prix[0]?.montage_demontage - element?.prix_montage,
            },
          ],
        }));
      }
      if (
        element.poids === poidsModelEnum.MORE80.value ||
        element.poids === poidsModelEnum.MORE80.enum
      ) {
        countext?.setData((data) => ({
          ...data,
          option_prix: [
            {
              ...data.option_prix[0],
              bigWeight:
                countext?.data?.option_prix[0]?.bigWeight - weightPrice,
              depart: {
                ...data.option_prix[0].depart,
                etage: weightPriceEtage(
                  countext?.data?.etages[0],
                  pricing?.[10].prix,
                  false
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[0],
                  false,
                  pricing[11].prix
                ),
              },
              arrive: {
                ...data.option_prix[0].arrive,
                etage: weightPriceEtage(
                  countext?.data?.etages[1],
                  pricing?.[10].prix,
                  false
                ),
                distance_logement: calculPriceDistance(
                  countext?.data?.distance_rue[1],
                  false,
                  pricing[11].prix
                ),
              },
            },
          ],
        }));
      }
      countext?.setData((data) => ({
        ...data,
        option_prix: [
          {
            ...data.option_prix[0],
            meuble:
              countext?.data?.option_prix[0]?.meuble -
              priceM3(element, element?.prix_m3),
          },
        ],
      }));
      setFinalData(oldArray);
      handleRemove(element);
    },
    [countext, finalData, handleRemove, pricing, weightPrice]
  );

  return (
    <div>
      {finalData?.length > 0 && (
        <div className={styles.myAddContainer}>
          <div className={styles.myObjectAdd}>
            <p>Liste des nouveaux ajouts</p>
          </div>
          {finalData?.map((el, i) => (
            <div key={i} className={styles.lineObjet}>
              <div className={styles.nameModal}>{el?.nom}</div>
              <div className={styles.pieceModal}>
                <p>
                  {
                    typePieces.filter(
                      (piece) => piece.value === el?.selectPiece
                    )[0].label
                  }
                </p>
              </div>
              <div className={styles.buttonModal}>
                <Button
                  onClick={() => handleRemoveObject(i, el)}
                  text="Supprimer"
                  type={"primary"}
                  size={"small"}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {modal && (
        <Modal handleClose={() => setModal(false)}>
          <div className={styles.containerModal}>
            <div className={styles.nameModal}>
              <p>{activeMeuble?.nom}</p>
            </div>
            <div>
              <Select
                data={typePieces}
                placeHolder="Pièce"
                onChange={(e) => setPieceMeuble(e)}
              />
            </div>
            {activeMeuble?.demontable && (
              <div>
                <Select
                  data={trueOrFlase}
                  placeHolder="Démontable"
                  onChange={(e) => setDemontable(parseInt(e))}
                />
              </div>
            )}
            <div className={styles.buttonModal}>
              <Button
                onClick={() => handleValidateMeuble()}
                text="Valider"
                type={"primary"}
                size={"small"}
              />
            </div>
          </div>
        </Modal>
      )}
      {openModalCreation && (
        <Modal handleClose={() => setOpenModalCreation(false)}>
          <div className={styles.containerModal}>
            <div className={styles.nameModal}>
              <p>Création d'un meuble</p>
            </div>
            <div className={styles.dimensionObject}>
              <Input
                placeholder={"Nom :"}
                value={activeMeuble?.name}
                onChange={(e) =>
                  setActiveMeuble({ ...activeMeuble, name: e.target.value })
                }
              />
            </div>
            <div></div>
            <div className={styles.dimensionObject}>
              <Select
                data={typePieces}
                placeHolder="Pièce"
                onChange={(e) => setPieceMeuble(e)}
              />
              <Select
                data={trueOrFlase}
                placeHolder="Démontable"
                onChange={(e) => setDemontable(parseInt(e))}
              />
              <Select
                data={trueOrFlase}
                placeHolder="Objet Lourd"
                onChange={(e) =>
                  setActiveMeuble({
                    ...activeMeuble,
                    objectLourd: e,
                  })
                }
              />
            </div>

            <div className={styles.dimensionObject}>
              <Input
                placeholder={"Largeur :"}
                value={activeMeuble?.largeur}
                onChange={(e) =>
                  setActiveMeuble({ ...activeMeuble, largeur: e.target.value })
                }
              />
              <Input
                placeholder={"Hauteur :"}
                value={activeMeuble?.hauteur}
                onChange={(e) =>
                  setActiveMeuble({ ...activeMeuble, hauteur: e.target.value })
                }
              />
              <Input
                placeholder={"Profondeur :"}
                value={activeMeuble?.profondeur}
                onChange={(e) =>
                  setActiveMeuble({
                    ...activeMeuble,
                    profondeur: e.target.value,
                  })
                }
              />
            </div>

            <div className={styles.dimensionObject}>
              <Input
                placeholder={"Prix du montage :"}
                value={activeMeuble?.prix_montage}
                onChange={(e) =>
                  setActiveMeuble({
                    ...activeMeuble,
                    prix_montage: e.target.value,
                  })
                }
              />
              <Input
                placeholder={"Prix du m3 :"}
                value={prixM3}
                onChange={(e) => setPrixM3(e.target.value)}
              />
            </div>
            <div className={styles.buttonModal}>
              <Button
                onClick={handleCreateNewObject}
                text="Valider"
                type={"primary"}
                size={"small"}
              />
            </div>
          </div>
        </Modal>
      )}
      <div className={styles.searchBarContainer}>
        <SearchBar onChange={handleChange} value={searchValue} />
        <Button
          onClick={() => setOpenModalCreation(true)}
          text="Meuble sur mesure"
          type={"primary"}
          size={"small"}
        />
      </div>
      <div className={styles.containerAllMeuble}>
        {searchValue?.length > 1
          ? allMeuble.map(
              (el) =>
                el.nom.toLowerCase().includes(searchValue) && (
                  <div className={styles.lineObjet} key={el.id}>
                    <div>
                      <p>{el.nom}</p>
                    </div>
                    <div>
                      <button
                        className={styles.addList}
                        onClick={() => hanldeAdd(el)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )
            )
          : allMeuble.map((el) => (
              <div className={styles.lineObjet} key={el.id}>
                <div>
                  <p>{el.nom}</p>
                </div>
                <div>
                  <button
                    className={styles.addList}
                    onClick={() => hanldeAdd(el)}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
