import React from "react";
import styles from "./Loader.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
export default function Loader({ full }) {
  if (full) {
    return (
      <div className={styles.container}>
        <Box sx={{ display: "flex" }}>
          <CircularProgress
            size={200}
            sx={{
              color: "#fe5900",
            }}
            thickness={4}
          />
        </Box>
      </div>
    );
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        size={100}
        sx={{
          color: "#fe5900",
        }}
        thickness={4}
      />
    </Box>
  );
}
